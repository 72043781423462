@import 'variable.less';
.HeaderSticky {
  position: sticky;
  top: 0;
  z-index: 10001;
}
.headerTopMenu {
  background: #fff;
  padding: 0 15px;
  background-size: 35%;
  position: relative;
  // background-image: url("../assets/svg/Header/header-bg.svg");
  // padding-bottom: 10px;
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    // background-image: url("../assets/svg/Header/header-bg.svg");
    // background: linear-gradient(to bottom, #fff 60%, transparent);
    // background: linear-gradient(to bottom,#fff 50%,rgba(56,57,56 , 0.1), rgba(236, 209 ,128 , 0.54),rgba(56,57,56,0.27)) !important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @themeGrayColor;
  }
}
// .headerStrip{
//     width: 100%;
//     height: 10px;
//     background-image: url("../assets/svg/Dashboard/bg.svg");
// }
.headerWrapper {
  display: flex;
  padding: 6px 0;
  align-items: center;
  // position: relative;
  z-index: 1;
}
.headerLogo {
  text-align: left;
  a {
    width: auto;
    display: inline-block;
    font-size: 30px;
    color: #000;
    font-weight: 600;
    line-height: 39px;
  }
}
.headerSearchWrapper {
  width: 240px;
  margin-left: 15px;
  .headerSearch {
    width: 100%;
    position: relative;
    background-color: #f4f4f4;
    border-radius: 20px;
    padding: 9px 15px;
    height: 40px;
    input {
      width: 100%;
      background-color: transparent;
      border: none;
      padding-left: 18px;
    }
    .headerSearchIcon,
    .headerSearchIcon2 {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      margin: auto;
      img {
        width: 16px;
        height: 16px;
        display: block;
      }
    }
    .headerSearchIcon2 {
      left: auto;
      right: 10px;
      cursor: pointer;
    }
  }
}
.headerLeft {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 60px;
  width: calc(100% - 410px);

  .collectionMenu {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    li {
      position: relative;

      font-size: 14px;
      a {
        display: inline-block;
        padding: 10px 15px;
        font-weight: 600;
      }
      .collectionSub {
        margin: 0;
        padding: 0;
        list-style: none;
        position: absolute;
        width: max-content;
        top: 100%;
        left: 0;
        background: @whiteColor;
        box-shadow: 0 2px 5px @themeGrayColor;
        z-index: 1;
        border-radius: 5px;
        height: 0;
        overflow: hidden;
        transition: all 0.1s linear;
        li {
          display: block;
          a {
            padding: 0 15px 10px;
          }
          &:first-child {
            a {
              &:first-child {
                padding-top: 10px;
              }
            }
          }
        }
      }
      &:hover {
        .collectionSub {
          height: 103px;
          // overflow: visible;
        }
      }
    }
  }
  .searchBlock {
    width: 50%;
    min-width: 415px;
    padding-right: 8px;
    padding-left: 8px;
    max-width: 100%;
    border: 1px solid #a6a6a6;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    .search_container {
      width: 100%;
      margin-right: 2rem;
    }
  }
  .headShortcut {
    margin-right: 20px;
    margin-left: 15px;
    fieldset {
      min-width: auto;
      border: 1px solid @themeLightColor;
      padding: 0px 0 3px;
      legend {
        display: inline;
        width: auto;
        min-width: auto;
        padding: 0 5px;
        font-weight: 600;
        color: @themeColor;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 1.1;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      list-style: none;
      li {
        display: inline-block;
        a {
          padding: 0 10px;
          display: block;
        }
        img {
          width: 20px;
        }
      }
    }
  }
  .helpBlock {
    margin-left: 15px;
    margin-top: 5px;
    cursor: pointer;
    svg {
      width: 25px;
    }
  }
}
.ant-tooltip {
  z-index: 10002 !important;
}
.headerRightSide {
  // width: calc(100% - 340px);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.headerMenuOption {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 260px);
}
.headerIcon img.hoverIcon {
  // display: none;
  opacity: 0;
  visibility: hidden;
  width: 0;
  cursor: pointer;
}
.headerIcon img.defulticon {
  opacity: 1;
  visibility: visible;
  width: 16px;
  cursor: pointer;
  // display: block;
}
// .headerRightBlock.active{
//     .headerIcon{
//         img{
//             &.hoverIcon{
//                 display: block;
//             }
//             &.defulticon{
//                 display: none;
//             }
//         }
//     }
// }
.headerRightBlock {
  display: flex;
  align-items: center;
  &.active {
    .headerIcon {
      span,
      a {
        color: #fff;
      }
      .defulticon {
        opacity: 0;
        visibility: hidden;
        width: 0;
      }
      .hoverIcon {
        opacity: 1;
        visibility: visible;
        width: 16px;
      }
      .countBox {
        background: #fff;
        color: @themeColor;
      }
    }
  }
}
.headerRightOption {
  display: flex;
  margin-right: 0px;
  padding-right: 10px;
  border-right: 1px solid #f1f1f1;
  .headerIcon {
    margin-left: 10px;
    cursor: pointer;
    &:first-child {
      border-right: 1px solid #f1f1f1;
      padding-right: 10px;
    }
  }
}
.headerIcon {
  display: flex;
  align-items: center;
  z-index: 10002;
  position: relative;
}
.search_icon {
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: -1px;
  background-color: #434443;
  // background-color: #008cd2;
  padding: 0 10px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.headerIcon img {
  width: 16px;
  height: 16px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.headerIcon .headerUserName {
  margin-left: 10px;
  white-space: nowrap;
  text-transform: capitalize;
  &:hover {
    color: #000;
  }
}
.headerIcon span {
  font-size: 14px;
  color: #000;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  font-weight: 600;
}
.headerRightBlock {
  justify-content: flex-end;
}
.headerMeniInner > ul {
  display: flex;
  // position: relative;
  justify-content: center;
  margin: 0;
}
.headerMeniInner > ul li a.menuItem {
  text-transform: capitalize;
  font-size: 14px;
  display: block;
  color: #000;
  padding: 0 9px;
  position: relative;
}
.headerMeniInner > ul li.active-sidebar a.menuItem,
.headerMeniInner > ul li:hover a.menuItem {
  font-weight: 600;
}
.headerMeniInner > ul li a.menuItem:after {
  content: '';
  width: 50px;
  height: 2px;
  background-color: @themeColor;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -7px;
  margin: auto;
  border-radius: 10px 10px 0 0;
  z-index: 1;
  opacity: 0;
}
.headerMeniInner > ul li.active-sidebar a.menuItem:after,
.headerMeniInner > ul li:hover a.menuItem:after {
  opacity: 1;
}
.menuDropdown {
  box-shadow: 0px 9px 12px 0px rgba(0, 0, 0, 0.09);
  /* display: none; */
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  width: 100%;
  z-index: 1000;
  background: #fff;
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: 0.2s ease-in;
}

.headerMeniInner > ul li:hover .menuDropdown {
  visibility: visible;
  /* display: block; */
  opacity: 1;
  height: auto;
  // top: 50px;
}
.headerMeniInner ul#links > li {
  overflow: hidden;
  padding-top: 9px;
  padding-bottom: 9px;
}
.submenuWrapper {
  display: flex;
  max-width: 80%;
  margin: 0 auto;
  min-width: 1420px;
  padding: 0 10px;
}
.submenuItem {
  flex-basis: 100%;
  padding-right: 50px;
}
.subMenuImage {
  flex: 0 0 50%;
}
.submenuItem h3 {
  font-size: 16px;
  color: #000;
  line-height: 1.71;
  letter-spacing: normal;
  margin-bottom: 8px;
  font-weight: 600;
}
.submenuItem ul {
  padding: 0 10px 0 0;
  margin: 0;
}
.submenuItem ul li {
  list-style: none;
  margin: 8px 0;
}
.submenuItem ul li a {
  // display: inline-block;
  position: relative;
  font-size: 14px;
  color: #000;
  background: linear-gradient(0deg, @themeColor, @themeColor) 0 100% no-repeat;
  transition: background-size 0.5s;
  background-size: 0 1px;
  display: inline !important;
}
.submenuItem ul li a:hover {
  background-size: 100% 1px;
}
.submenuImageContent .sibmenuImg {
  height: 100px;
}
.submenuImageContent .sibmenuImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.submenuContent {
  margin-top: 15px;
}
.submenuContent .submenudropTitle {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}
.submenuContent .submenuLink {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
}
.submenuContent .submenuLink img {
  width: 12px;
  height: 12px;
  margin-left: 2px;
}
.HeaderRightSidebar {
  background-color: #fff;
  width: 430px;
  z-index: 10001;
  position: fixed;
  top: 80px;
  right: 0;
  transition: 0.4s ease;
  visibility: hidden;
  height: 0;
  &.active {
    transition-delay: 0.5s;
    visibility: visible;
    height: 100vh;
    bottom: 0;
  }
  .headerRightOverlay {
    content: '';
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .headerRightSidebarTop {
    // height: 50px;
    height: 80px;
    background: @themeSecondColor;
    top: -80px;
    position: absolute;
    width: 100%;
  }
}
.dropdownRightSide {
  overflow: auto;
  // height: calc(100% - 80px);
  height: calc(100% - 50px);
  background: #fff;
  position: relative;
}
.dropdownUserIcon {
  display: flex;
  align-items: center;
  padding: 15px;
  position: sticky;
  top: 0;
  background: #fff;
  .userProfileLogo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  .userProfileContent {
    width: calc(100% - 70px);
    h2 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 16px;
    }
    p {
      color: #000;
      font-size: 14px;
      margin-bottom: 0;
    }
    span {
      cursor: pointer;
    }
  }
}
.userDetailList {
  padding: 25px;
  ul {
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 30px;
      .userDetailListItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .detailItemName {
          font-size: 16px;
          color: #000;
          font-weight: 600;
        }
        img {
          width: 12px;
          height: 12px;
        }
      }
      .uerDetailBasic {
        display: flex;
        align-items: center;
      }
    }
  }
}
.userDetailBasicItem {
  width: auto;
  margin-right: 7px;
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
    &:first-child {
      font-size: 12px;
      text-transform: uppercase;
      color: #555;
      margin-right: 5px;
      font-weight: 500;
    }
    &:last-child {
      color: #000;
      font-weight: 600;
    }
  }
}
.notFoundDataSidebar {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  height: 100%;
  align-items: center;
  h2 {
    width: 100%;
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
  }
  p {
    font-size: 16px;
    margin: 0;
    color: #212121;
  }
}

.cartDropdownWrapper {
  padding: 15px;
  .cartDropdownWraperItem {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .cartDrodownImg {
      width: 120px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cartDropdownContent {
      // display: flex;
      width: calc(100% - 140px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column;
      .cartDrodownTop {
        width: 100%;
        h2 {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 5px;
          font-weight: 600;
        }
        .cartDiamondDetail {
          // display: flex;
          // flex-wrap: wrap;
          // align-items: center;
          .cartDropdownPrice {
            margin-right: 5px;
            display: flex;
            font-size: 12px;
            span {
              &:first-child {
                text-transform: uppercase;
                color: #555;
                margin-right: 5px;
                font-weight: 500;
                min-width: 50px;
              }
              &:last-child {
                color: #000;
                font-weight: 600;
              }
            }
          }
        }
      }
      .cartDropdownLink {
        flex-direction: column;
        display: flex;
        flex: 0 1 auto;
        a {
          color: #000;
          font-weight: 500;
        }
      }
    }
  }
}
.cartDropdownItem {
  height: calc(100vh - 195px);
  overflow: auto;
}
.cartDropdownSummary {
  .cartDropdownSummaryItem {
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    span {
      font-size: 14px;
      color: #000;
    }
  }
  .cartDropdoownButton {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    font-size: 15px;
    span {
      color: #fff;
    }
    button {
      color: #fff;
      background-color: transparent;
      border: none;
    }
  }
}

.notificationDropdown {
  padding: 15px;
  .notificationItem {
    margin-bottom: 20px;
    .notificationTitle {
      font-size: 16px;
      text-decoration: underline;
      color: #000;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .notificationInner {
      .notificationDropdownDetail {
        margin-bottom: 10px;
        border-bottom: 1px solid #ddd;
        // padding-bottom: 10px;
        padding-left: 3px;
        .norificationDropdownItem {
          background-color: #fff;
          padding-left: 0;
        }
        &:last-child {
          border-bottom: none;
        }
        h2 {
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 5px;
          font-weight: 400;
        }
        &.readMessage {
          background: @headerStripColor;
          .norificationDropdownItem {
            padding-left: 10px;
          }
          h2 {
            font-weight: 600;
          }
        }
        span {
          color: #000;
          text-transform: capitalize;
          font-size: 12px;
          font-weight: 500;
        }
        &.readMessage {
          h2 {
            //font-weight: 800;
            // text-decoration: line-through;
          }
        }
      }
    }
  }
}

.covidStrip {
  background: @themeSecondColor;
  padding: 5px 0;
  text-align: center;
  position: relative;
  span {
    color: #fff;
    &.closeImg {
      position: absolute;
      right: 10px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      background: #fafafa;
      border-radius: 100%;
      top: 0;
      bottom: 0;
      display: flex;
      margin: auto;
      cursor: pointer;
      img {
        width: 8px;
        height: 8px;
        margin: auto;
      }
    }
  }
}
.searchWrapper {
  .searchWrapperInner {
    position: fixed;
    width: 0;
    background: @headerStripColor;
    padding: 10px;
    top: 0;
    height: 50px;
    z-index: 100000;
    display: flex;
    flex-wrap: wrap;
    right: 0;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    .searchBox {
      width: 50%;
      margin: auto;
      input {
        width: 100%;
        background-color: transparent;
        border: none;
        margin-left: 0;
        padding-right: 0;
        color: #fff;
        &::placeholder {
          color: #fff;
        }
        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
      .ant-input-suffix {
        display: none;
      }
      .searchIcon {
        img {
          width: 16px;
          height: 16px;
        }
      }
      .searchIconClose {
        margin-left: 20px;
        cursor: pointer;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .searchDropdown {
      position: absolute;
      margin: auto;
      width: 50%;
      left: 0;
      right: 0;
      background: #f1f1f1;
      padding: 15px;
      border-radius: 0 0 10px 10px;
      top: 50px;
      box-shadow: -1px 12px 16px 0px rgba(0, 0, 0, 0.19);
      h2 {
        font-weight: 600;
        margin: 0 0 10px 0;
        font-size: 16px;
      }
      ul {
        padding: 0 15px;
        li {
          list-style: none;
          color: #555;
          line-height: 26px;
          font-size: 14px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
  }
  &:before {
    content: '';
    width: 100%;
    position: fixed;
    height: 100%;
    background: rgba(0, 0, 0, 0.58);
    left: 0;
    top: 0;
    display: none;
    z-index: 10003;
  }
  &.active {
    &:before {
      display: block;
    }
    .searchWrapperInner {
      width: 100%;
      opacity: 1;
      // left: 0;
      margin: auto;
      visibility: visible;
    }
  }
}
.headerTopMenu {
  &.active {
    .HeaderRightSidebar {
      top: 50px;
      // top: 80px;
    }
    .cartDropdownItem {
      height: calc(100vh - 225px);
    }
  }
}

@primary-color: #434443;