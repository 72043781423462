@import 'variable.less';

.compareMainPage {
  padding: 10px;
  .compareTable {
    overflow: auto;
    height: calc(100% - 43px); // 43 height of heading
    table {
      width: auto;
      position: relative;
      border-spacing: 0;
      tr {
        td {
          border: 1px solid #d9d9d9;
          padding: 10px;
          text-align: left;
          max-width: 180px !important;
          width: 200px;
          min-width: 200px !important;
          max-width: 200px !important;
          &:first-child {
            width: 150px;
            letter-spacing: 0.5px;
            position: sticky !important;
            left: -1px;
            background-color: @themeGrayColor;
            border: 1px solid #d9d9d9;
            color: #000;
            font-weight: 600;
            z-index: 1;
          }
          &.compareHead {
            position: relative;
            img {
              &.diamondCompareImg {
                width: 130px;
                height: 130px;
                object-fit: contain;
                margin: auto;
              }
            }
            .closeImage {
              position: absolute;
              right: 8px;
              top: 8px;
              width: 14px;
              height: 14px;
              img {
                width: 10px;
                height: 10px;
                display: block;
              }
            }
            .checkImageBox {
              position: absolute;
              left: 8px;
              top: 8px;
              .ant-checkbox-wrapper,
              .ant-checkbox {
                display: block;
              }
            }
          }
          &.compareImage {
            text-align: center;
          }
        }
        &:first-child {
          td {
            height: 154px;
            min-height: 154px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.compareBottomIcon {
  display: flex;
  margin-top: 10px;
  align-items: center;
  border: 1px solid #ddd;
  padding: 5px 10px;
  justify-content: space-between;
  img {
    width: 16px;
    height: 16px;
  }
  .Text {
    line-height: 24px;
    color: #000;
    font-weight: 600;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .compareMainPage {
    .compareTable {
      height: calc(100vh - 161px);
      table {
        tr {
          td {
            &:first-child {
              min-width: 120px !important;
              max-width: 120px !important;
              width: 120px;
            }
            width: 150px;
            min-width: 150px !important;
            max-width: 150px !important;
            &.compareHead {
              img {
                &.diamondCompareImg {
                  width: 100px;
                  height: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #434443;