@import '../styles/variable.less';

.text-left {
  text-align: left !important;
}
.loginMainWrapper {
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;
  // position: fixed;
  &.registerLogWrapper {
    padding-right: 0;
    .LoginRightBlock {
      margin: 0 auto;
      width: 100%;
      // &:before {
      //   content: '';
      //   background-image: url(/static/media/login-bg.8ef19122.jpg);
      //   background-position: right;
      //   background-repeat: no-repeat;
      //   background-size: cover;
      //   width: 50%;
      //   height: 100%;
      //   position: absolute;
      //   left: auto;
      //   right: -70px;
      //   top: 0;
      //   bottom: 0;
      //   border-radius: 0 0 0 60%;
      // }
    }
  }
  .loginLeftWrapper {
    display: grid;
    height: 100%;
  }
  .loginLeftBlock {
    width: 70%;
    height: 100%;
    position: relative;
    .main-desktop-img-cover {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    // padding: 30px;
    .loginLeftBlockInner {
      height: 100vh;
      display: flex;
      align-items: center;
    }
    video {
      width: 100%;
      height: 100%;
      // max-height: calc(100vh - 60px);
      position: sticky;
      // top: 30px;
      // position: absolute;
      /* z-index: 1; */
      object-fit: cover;
      // border-radius: 10px;
    }
    .login_desc {
      width: 60%;
      margin: auto;
      img {
        width: 300px;
      }
      .post_banner {
        img {
          width: 100%;
          max-width: 100%;
        }
      }
      .main-title {
        font-size: 32px;
        line-height: 44px;
        font-weight: 300;
        margin-bottom: 20px;
        margin-top: 30px;
        text-transform: capitalize;
      }
      ul {
        list-style: none;
        li {
          font-weight: 300;
          position: relative;
          font-size: 16px;
          color: #555;
          margin-bottom: 10px;
          display: flex;
          align-items: flex-start;
          img {
            width: 20px;
            height: 20px;
            margin-bottom: 0;
            margin-right: 10px;
            margin-top: 3px;
          }
        }
      }
      .desc {
        font-size: 14px;
        line-height: 26px;
        color: #000;
        opacity: 0.7;
      }
    }
    .loginLeftContent {
      position: relative;
      max-width: 70%;
      margin: auto;
      h2 {
        font-size: 50px;
        color: #fff;
        line-height: 70px;
        font-weight: 600;
      }
      button {
        padding: 15px 25px;
        font-size: 16px;
      }
    }
    .loginBasicList {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        li {
          list-style: none;
          text-align: center;
          a {
            color: #fff;
            margin: 0 10px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .LoginRightBlock {
    // border-radius: 0 0 0 60%;
    width: 30%;
    // display: flex;
    background-color: #fff;
    // align-items: center;
    justify-content: center;
    position: relative;
    // height: 100vh;
    // overflow-y: auto;
    height: calc(100vh - 0px);
    // height: calc(100vh - 15px);
    overflow-y: auto;
    // align-items: center;
    display: flex;
    // padding-top: 115px;
    &.guestlogin-center {
      align-items: center;
    }
    &.align-middle {
      align-items: center;
      display: flex;
    }
    // &::before {
    //   content: '';
    //   background-image: url("../assets/img/login-bg.jpg");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    //   border-radius: 0 0 0 60%;
    // }
    .loginDetail {
      // padding: 30px;
      padding: 0px 44px;
      // padding: 0px 15px;
      // width: 75%;
      background: #fff;
      // box-shadow: 0px 20px 80px rgba(0, 0, 0, 0.08);
      align-items: center;
      position: relative;
      border-radius: 5px;
      width: 100%;
      // height: 100%;
      // .qr-code {
      //   text-align: center;
      //   border-top: 1px solid @tableBackGroundColor;
      //   padding-top: 10px;
      //   p {
      //     color: @themeDarkBoreder;
      //     font-weight: 600;
      //     margin-bottom: 10px !important;
      //     line-height: 18px;
      //     span {
      //       color: @themeColor;
      //       display: block;
      //     }
      //   }
      //   img {
      //     max-width: 100px;
      //   }
      // }
      &.registerForm {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        // margin-left: -100%;
        // @media screen and (max-width: 1366px) {
        //   width: 1000px;
        // }
        .registerMainScroll {
          // margin: 30px 0;
          // .registerMainBlock{
          //   max-height: calc(100vh - 300px);
          //   overflow-y: auto;
          // }
        }
        .registrationSelect {
          flex-wrap: wrap;
          .registrationSelectItem {
            border: 1px solid #e1e1e1;
            background-color: #fafafa;
            padding: 8px 12px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin-right: 12px;
            margin-bottom: 5px;
            min-width: 160px;
            cursor: pointer;
            .registrationSelectImg {
              width: 40px;
              height: 40px;
              border: 1px solid #ccc;
              display: flex;
              margin-right: 10px;
              border-radius: 5px;
              background: #fff;
              img {
                margin: auto;
                width: 25px;
                height: 25px;
              }
            }
            span {
              font-weight: 600;
            }
            &.active {
              background-color: @themeLightColor;
              border: 1px solid @themeColor;
              .registrationSelectImg {
                border: 1px solid @themeColor;
              }
              span {
                color: #000;
              }
            }
          }
        }
        .loginHead {
          width: 256px;
          margin: auto;
        }
        .registerMainBlock {
          form {
            width: 100%;
            .registerFiledBlock {
              display: flex;
              margin-bottom: 30px;
              flex-wrap: wrap;
              &.terms-con {
                justify-content: space-between;
              }
              .profileUploadBlock {
                width: 49%;
              }
              .from-group {
                width: calc(33.33% - 20px);
                // width: calc(50% - 20px);
                margin-right: 20px;
                &.width-66 {
                  width: calc(66.67% - 20px);
                  .profileUploadBlock {
                    width: 100%;
                  }
                }
                &.singleBlock {
                  width: 100%;
                  margin-bottom: 15px;
                }
                .ant-input {
                  height: auto;
                }
              }
            }
          }
        }
      }
      .loginHead {
        margin-bottom: 30px;
        text-align: center;

        h3 {
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          margin-bottom: 0px;
          // margin-bottom: 15px;
        }
        p {
          font-size: 14px;
          color: #555;
        }
      }
      .loginBox {
        .from-group {
          margin-bottom: 25px;
        }
      }
      .loginLinkBlock {
        margin: 15px 0 50px 0;
      }
      .registerLogLink {
        text-align: center;
        margin-top: 20px;
        color: #000;
        a {
          color: #000;
          font-weight: 600;
          margin-left: 5px;
          text-decoration: underline;
          &:hover {
            color: @themeColor;
          }
        }
      }
      .rememberCheckBox {
        display: flex;
        align-items: center;
        .rememberLabel {
          margin-left: 5px;
          color: #000;
          font-size: 13px;
          white-space: nowrap;
        }
      }
      .check_nowrap {
        .flex-wrap {
          flex-wrap: nowrap;
        }
      }
    }
  }
  .loginButton {
    display: flex;
    justify-content: space-between;
    .commonButton,
    .commonOutline {
      white-space: nowrap;
      padding: 12px 12px;
      width: 49%;
      &.pad-lr-30 {
        white-space: nowrap;
        padding: 12px 12px;
      }
    }
  }
}

.registerTitie {
  font-size: 18px;
  font-weight: 500;
}
ul.tabList {
  margin: 0;
  padding: 0;
  // position: absolute;
  li {
    list-style: none;
    a {
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      display: flex;
      color: #000;
      cursor: pointer;
      span {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        border: 1px solid #000;
        margin-right: 8px;
        display: block;
        text-align: center;
      }
      &.is-active {
        text-decoration: underline;
        font-weight: 600;
        span {
          background-color: #000;
          color: #fff;
          border: 1px solid #000;
          font-weight: normal;
        }
      }
    }
  }
}
p {
  color: #555;
  line-height: 24px;
  margin-bottom: 0 !important;
}
.registerLogWrapper {
  .login_desc {
    display: none;
  }
}

.f-width {
  .intl-tel-input {
    width: 100%;
  }
}
.countryCodeDropdown {
  .intl-tel-input {
    input {
      &:focus {
        border-bottom: 1px solid #434443;
        // border-bottom: 1px solid #3a8ed2;
      }
    }
  }
}
.ant-select-selection--multiple {
  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }
}
.d-none {
  display: none;
}
.mobileCodeBlock {
  position: relative;
  .countryCode {
    position: absolute;
    top: 8px;
    left: 38px;
    z-index: 1;
  }
  .intl-tel-input.allow-dropdown .selected-flag {
    width: 84px;
    justify-content: flex-start;
  }
  input {
    padding-left: 91px !important;
  }
}
@media (max-width: 1399px) {
  width: 80%;
}
@media (min-width: 1000px) and (max-width: 1270px) {
  .LoginRightBlock {
    &.hv-auto-set {
      // padding-top: 115px;
      // margin: 10px 0px;
    }
  }
}
@media (min-width: 768px) {
  // .loginHead {
  //   .loginLogo {
  //     display: none;
  //   }
  // }
}
@media screen and (max-width: 767px) {
  .loginMainWrapper {
    .brandLogoContain {
      display: none !important;
    }
    .loginButton {
      // justify-content: center;
      position: fixed;
      width: 100%;
      left: 0;
      justify-content: center;
      padding: 10px;
      background: #fff;
      bottom: 0;
      box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.09);
      &.justify-center {
        justify-content: center;
      }
    }
    .LoginRightBlock {
      max-width: 100% !important;
      width: 100%;
      .loginDetail {
        .loginLinkBlock {
          margin: 15px 0 0 0;
        }
        .loginBox {
          padding: 0 15px;
        }
        .signup-form {
          padding: 0 15px;
        }
        &.registerForm {
          // width: 100%;
          .accountType {
            padding: 0;
            margin-bottom: 20px;
            .registrationSelect {
              display: -webkit-box;
              overflow: auto;
              flex-wrap: nowrap;
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
          // .registerMainScroll{
          //   .registerMainBlock{
          //     max-height: 100% !important;
          //     overflow: visible;
          //   }
          // }
          .registerMainBlock {
            form {
              padding: 0;
              .registerFiledBlock {
                .from-group {
                  width: 100%;
                  margin: 0 0 10px 0;
                  &.width-66 {
                    width: 100%;
                    .profileUploadBlock {
                      width: 100%;
                    }
                  }
                }

                .profileUploadBlock {
                  width: 100% !important;
                }
              }
              .ant-checkbox-wrapper {
                display: flex;
                .ant-checkbox {
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .loginMainWrapper {
    flex-wrap: wrap;
    background-color: #fff;
    .LoginRightBlock {
      width: 100%;
      // max-width: 465px;
      background-color: #fff;
      margin: auto;
      padding-bottom: 60px;
    }
    &.registerLogWrapper {
      .LoginRightBlock {
        max-width: 100%;
      }
    }
    &.registerLogWrapper {
      padding: 15px;
      .registerMainScroll {
        height: auto !important;
      }
    }
    .loginLeftBlock {
      display: none;
    }
    .LoginRightBlock {
      width: 100%;
      .loginDetail {
        width: 100%;
        padding: 0;
        &.registerForm {
          max-width: 800px;
          margin-left: 0;
          padding: 20px 0;
        }
        .loginHead {
          text-align: center;
          width: 100% !important;
          padding: 0 !important;
          margin-bottom: 30px;
          .loginLogo {
            img {
              height: auto;
              width: 256px;
            }
          }
          p {
            max-width: 100%;
          }
        }
        .loginBox {
          .commonOutline {
            white-space: nowrap;
            padding: 5px 10px;
            width: 49%;
            &.pad-lr-30 {
              white-space: nowrap;
              padding: 5px 10px;
            }
          }
          .loginLinkBlock {
            margin: 15px 0 40px 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1299px) {
  .loginMainWrapper {
    .loginLeftBlock {
      .loginBasicList {
        ul {
          li {
            a {
              font-size: 12px;
              display: inline-block;
              text-align: center;
            }
          }
        }
      }
      .loginLeftContent {
        h2 {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }
    .LoginRightBlock {
      .loginDetail {
        // .loginHead {
        //   .loginLogo {
        //     img {
        //       width: 150px;
        //     }
        //   }
        // }
        .loginHead {
          margin-bottom: 20px;
          text-align: center;
          h3 {
            font-size: 24px;
          }
          .loginLogo {
            img {
              width: 150px;
            }
          }
        }
        .ant-form {
          .inputBlock {
            margin-bottom: 10px;
            label {
              font-size: 14px !important;
            }
            .inputWrapper {
              input {
                padding: 10px 0px;
                font-size: 12px;
              }
            }
          }
          .check_nowrap {
            label {
              font-size: 12px;
            }
          }
          .mt-30 {
            margin-top: 15px !important;
            .commonButton {
              padding: 5px 5px;
              font-size: 12px;
            }
          }
        }

        .qr-code {
          img {
            max-width: 80px;
          }
        }
      }
    }
    // .LoginRightBlock {
    //   .loginDetail{
    //     &.registerForm{
    //       width: 1200px;
    //       margin-left: -100%;
    //       .registerMainScroll {
    //         .registerMainBlock{
    //           max-height: 100% !important;
    //           overflow: visible;
    //           form{
    //             .registerFiledBlock{
    //               .from-group {
    //                 width: calc(33.33% - 20px);
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
@media screen and (max-width: 1199px) {
  .loginMainWrapper {
    .loginLeftBlock {
      .loginLeftBlockInner {
        background-position: center top 30%;
      }
      .loginLeftContent {
        h2 {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
    .LoginRightBlock {
      .loginDetail {
        // width: 50%;
        // width: 80%;

        .loginHead {
          margin-bottom: 25px;
          h3 {
            font-size: 22px;
          }
          .loginLogo {
            img {
              width: 160px;
            }
          }
        }
        .ant-form {
          .inputBlock {
            margin-bottom: 10px;
            label {
              font-size: 12px !important;
            }
            .inputWrapper {
              input {
                padding: 5px 0px;
                font-size: 10px;
              }
            }
          }
          .check_nowrap {
            label {
              font-size: 12px;
            }
          }
          .mt-30 {
            margin-top: 15px !important;
            .commonButton {
              padding: 5px 5px;
              font-size: 12px;
            }
          }
        }

        .loginBox {
          .from-group {
            margin-bottom: 15px;
            .inputBlock {
              .inputWrapper {
                input {
                  font-size: 12px !important;
                  padding: 5px 0;
                }
              }
            }
          }
          .loginLinkBlock {
            margin: 15px 0 20px 0;
          }
          .loginButton {
            .commonButton {
              padding: 5px 10px;
              font-size: 12px;
            }
            .commonOutline {
              white-space: nowrap;
              padding: 5px 10px;
              width: 49%;
              font-size: 12px;
              &.pad-lr-30 {
                white-space: nowrap;
                padding: 5px 10px;
              }
            }
          }
        }
        .registerLogLink {
          margin-top: 5px;
          font-family: 12px;
        }
        &.registerForm {
          .registrationSelect {
            .registrationSelectItem {
              margin-right: 5px;
              min-width: auto;
            }
          }
        }
      }
    }
    // .loginButton {
    //   .commonButton,
    //   .commonOutline {
    //     padding: 10px 15px;
    //   }
    // }
  }
}

@media screen and (min-width: 992px) and (max-width: 1599px) {
  .loginMainWrapper {
    .loginButton {
      .commonButton {
        &.resetPass {
          width: max-content;
        }
      }
    }
  }
}

@primary-color: #434443;