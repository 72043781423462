@import '../../styles/variable.less';
.qrCodeOption {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 18px;
  width: 90px;
  padding: 12px 0 10px 0;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.19);
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  img,
  canvas {
    width: 50px;
    height: 50px;
    // margin: auto auto 30px auto;
    margin: auto;
    object-fit: contain;
  }
  span {
    color: #000;
    text-transform: uppercase;
    font-size: 11px;
    text-align: center;
    display: block;
    margin-top: 5px;
    width: 100%;
    line-height: 16px;
    font-weight: 600;
    // position: absolute;
    // bottom: 5px;
  }
}
.dnaContainerBox {
  // padding: 43px 15px 0 15px;
  padding: 10px 15px 10px 15px;
  // padding: 55px 15px 10px 15px;
  width: 100%;
  // height: 100vh;
  background: #f1f1fb;
}
.dnaWrapper {
  width: 100%;
  // padding: 10px 0px;
}
.dnaWrapperBox {
  &.ivc_block {
    width: 40%;
    background: transparent;
  }
  &.stone_detail_cont {
    width: calc(~'60% - 30px');
    // height: calc(100vh - 45px);
    margin-left: 30px;
    position: relative;
    z-index: 1;
    background-color: @whiteColor;
    border-radius: 10px;
    overflow: hidden;
    // box-shadow: 0 0 5px rgb(0 0 0);
    .dnaImageTop {
      span {
        font-family: Gilroy;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 0.3px;
        margin-bottom: 0;
        font-weight: 500;
      }
      .stone-detail {
        width: 80% !important;
      }
      a {
        width: 10% !important;
        text-decoration: underline !important;
        span {
          color: blue !important;
          width: 10% !important;
          font-size: 14px !important;
        }
      }
    }
  }

  &.threeBlock1 {
    width: 41.66%;
  }
  &.threeBlock2 {
    width: 16.66%;
  }
  .dnaImageBlock {
    position: sticky;
    position: -webkit-sticky;
    top: 50px;
    z-index: 1;
  }
}
.dnaImageSwipe {
  .slick-track {
    margin: auto;
  }
  .sliderMainImage {
    // height: calc(100% - 65px);
    display: flex;
    align-items: center;
    padding: 10px 10px 0;
  }
  .imageSliderFullBlock {
    text-align: center;
    width: 100%;
    // height: 540px;
    // display: inline-block !important;

    img {
      width: 100%;
      height: 100%;
      margin: auto;
      object-fit: contain;
      border: 0px solid #fff;
      // border: 1px solid #fff;
      padding-bottom: 10px;
    }

    .magnifier {
      width: 100%;
      margin: auto;
      img {
        width: 400px;
      }
    }
    &.width-100 {
      iframe {
        width: 100%;
        height: 650px;
      }
    }
  }
  .video-set {
    &.imageSliderFullBlock {
      text-align: center;
      width: 100%;
      height: 540px;
      // display: inline-block !important;
      iframe,
      img {
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit: contain;
        border: 0px solid #fff;
        // border: 1px solid #fff;
        padding-bottom: 10px;
      }
      iframe {
        // min-height: 420px;
      }
      .magnifier {
        width: 100%;
        margin: auto;
        img {
          width: 400px;
        }
      }
      &.width-100 {
        iframe {
          width: 100%;
          height: 650px;
        }
      }
    }
  }
  .certificateImage {
    &.imageSliderSmallBox {
      position: relative;
      // height: 60px;
      height: 650px;
      cursor: pointer;
      display: flex;
      border: 1px solid #ddd;
      border-radius: 5px;
      img {
        width: 60% !important;
        height: 650px !important;
        margin: auto !important;
      }
      iframe {
        width: 100%;
        height: 650px;
        margin: auto;
        // object-fit: cover;
        border: none;
        &.certificateImage {
          height: 650px;
        }
      }
    }
  }
  .imageSliderSmallBlock {
    padding-right: 5px;
    img,
    iframe {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .imageVideoIcon {
    position: absolute;
    bottom: 3px;
    right: 3px;
    text-align: right;
    background: #000000;
    border-radius: 3px;
    padding: 6px;
    line-height: 1;
    font-size: 12px;
    color: #ffffff;
    display: flex;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .imageVideoTitle {
    font-size: 13px;
    display: block;
    margin-top: 5px;
    color: #454545;
    white-space: pre-wrap;
    text-align: center;
  }
  .sliderSmallImage {
    padding: 10px 24px;
    .slick-slide {
      // width: 90px !important;
      height: auto;
      &.slick-current {
        .imageSliderSmallBlock {
          .imageSliderSmallBox {
            border: 2px solid @themeColor;
            padding: 2px;
          }
        }
      }
    }
  }
}
.threeBlock1 {
  .sliderSmallImage {
    .slick-slide {
      &.slick-cloned {
        display: none;
      }
    }
  }
}
.dnaLeftBox {
  // border-bottom: 1px solid #ddd;
  // overflow: hidden;
  // display: flex;
  // min-height: calc(100vh - 128px);
  .dnaLeftBoxImage {
    // border: 1px solid #ddd;
    border-radius: 10px;
    background: @whiteColor;
    // box-shadow: 0 0 5px rgb(0 0 0);
  }
}
.dnaLeftBoxImage {
  width: 100%;
  // float: left;
  // padding-bottom: 20px;
  // .dnaImageSwipe {
  .imageSliderFullBlock {
    // height: 583px !important;
    // height: 62vh !important;
    // height: 600px !important;
    // height: 467px !important;
    //width: 540px !important;
    margin: auto;
    &.multiimg {
      height: 267px !important;
    }
    // height: auto;
    .magnifier {
      // height: 100% !important;
    }
    img {
      width: 100% !important;
      height: 100%;
    }
  }

  .video-set {
    &.imageSliderFullBlock {
      height: 583px !important;
      // height: 62vh !important;
      // height: 600px !important;
      // height: 467px !important;
      //width: 540px !important;
      margin: auto;
      &.multiimg {
        height: 267px !important;
      }
      // height: auto;
      .magnifier {
        // height: 100% !important;
      }
      img {
        width: 100% !important;
        height: 100%;
      }
    }
  }
  // }
}
.dnaImageTop {
  padding: 9px 11px;
  // padding: 11px;
  font-size: 18px;
  align-items: center;
  justify-content: space-between;
  // background:@headerStripColor;
  // background-color: @themeColor;
  // margin-bottom: 10px;
  // background-color: #f5f5f5;
  // border-bottom: 1px solid #eaeaea;
  // border-radius: 10px 10px 0 0;
  // margin: 0 0 10px 0;
  span {
    display: block;
    font-weight: 600;
    color: #000;
    text-align: left;
  }
  &.mainBox {
    background: @themeColor;
    // margin: 0 0 10px 0;
    border: none;
    position: sticky;
    // position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    font-size: 16px;
    height: 42px;
    span {
      color: #fff;
      text-align: center;
    }
    .unique_logo {
      position: absolute;
      left: 10px;
      top: 5px;
      img {
        width: 40px;
        height: 30px;
      }
    }
  }
}
.dnaLeftRightDetil {
  width: 100%;
  // margin-bottom: 20px;
  // float: left;
  position: relative;
  height: calc(100vh - 120px);
  overflow: auto;
  // border-top: 1px solid #ddd;
  .dnaDetailBlock {
    // background: #fafafa;
    width: 100%;
    // border-right: none;
    padding: 10px;
    align-items: flex-start;
    // margin-bottom: 10px;
    border-radius: 10px;
    // border: 1px solid #ddd;
    .mainHeadingTitle {
      // background: #fff;
      width: 100%;
      // margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 16px;
      padding: 0px 0px;
      display: flex;
      align-items: center;
      // border-bottom: 1px solid #eaeaea;
      // border-radius: 5px;
    }
    .mobile-w-33 {
      flex-wrap: wrap;
      width: 100%;
    }
  }
}
.dnaDetailBlock {
  padding: 10px;
  .detail {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 5px;
    // justify-content: space-between;
    border: 1px solid #ccc;
    width: 25%;
    span {
      color: #000;
      display: block;
      padding: 3px 5px;
      &:first-child {
        width: 100px;
        font-size: 11px;
        letter-spacing: 0.5px;
        font-weight: 600;
        background: @themeGrayColor;
      }
      &:last-child {
        // font-size: 13px;
        font-size: 11px;
        color: #000;
        font-weight: 500;
        word-break: break-word;
        // width: calc(100% - 100px);
      }
    }
    // &.width-100 {
    //   span {
    //     width: auto;
    //   }
    // }
    // &:nth-child(even):after {
    //   content: '';
    //   position: absolute;
    //   width: 1px;
    //   bottom: 0;
    //   top: 0;
    //   margin: auto;
    //   right: 5px;
    //   height: 115%;
    //   background-color: #ececec;
    // }
    // &:last-child::after {
    //   content: none;
    // }
  }
  &.three-col {
    .detail {
      width: 33.33%;
    }
  }
  &.full-width {
    .detail {
      width: 100%;
    }
  }
  &.firstDetail {
    .detail:nth-last-child(2),
    .detail:last-child {
      width: 100%;
      span {
        &:first-child {
          width: 140px;
        }
        &:last-child {
          width: calc(100% - 145px);
        }
      }
    }
  }
}
.dnaJourenySticky {
  position: sticky;
  background-color: #fafafa;
  top: 0;
  padding: 10px;
  // border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
}
.diamondJourneyBlock {
  display: flex;
  justify-content: space-between;
  align-items: normal;
  flex-wrap: wrap;
  padding: 10px;
  // max-height: calc(100vh - 56px);
  // overflow: auto;
  .diamondJourneyItem {
    margin-bottom: 20px;
    width: calc(50% - 10px);
    margin: 5px;
    height: auto;
    text-align: center;
    border: 1px solid #f2f2f2;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    .diamondJourneyTitle {
      margin-bottom: 10px;
      span {
        background-color: #fafafa;
        color: #000;
        font-weight: 600;
        font-size: 16px;
      }
    }
    .diamondJourneyImage {
      margin: auto;
      width: 100%;
      height: 200px;
      border-radius: 10px;
      .magnifier {
        height: 100% !important;
      }
      img,
      iframe {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        border: none;
        object-fit: cover;
      }
    }
  }
}
.dnaDetailShareOption {
  display: flex;
  position: absolute;
  right: 5px;
  top: 4px;
}
.dnaShareOption {
  width: auto;
  height: 35px;
  background-color: @themeColor;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  margin: 0 2px;
  img {
    margin: auto;
    width: 16px;
    height: 16px;
  }
  span {
    margin-left: 10px;
    // color:@themeColor !important;
  }
}
.dnaWrapperBox .slick-prev,
.dnaWrapperBox .slick-next {
  width: 34px;
  height: 34px;
  border: 1px solid #ddd;
  border-radius: 100%;
  left: 15px;
  cursor: pointer;
  z-index: 1;
  background-color: #fff !important;
}
.dnaWrapperBox .slick-next {
  left: auto;
  right: 15px;
}
.dnaWrapperBox .sliderSmallImage .slick-prev,
.dnaWrapperBox .sliderSmallImage .slick-next {
  top: 50px;
}
.dnaWrapperBox .slick-prev::before,
.dnaWrapperBox .slick-next::before {
  content: '';
  background-image: url('../../assets/svg/DNA/left-arrow.svg');
  width: 14px;
  height: 14px;
  background-size: 100%;
  opacity: 1;
  display: block;
  margin: auto;
}
.dnaWrapperBox .slick-next::before {
  transform: rotate(180deg);
}
.dnaWrapperBox .slick-prev:hover {
  background-color: #fff;
}
.similarStoneScroll {
  height: 670px;
  overflow: auto;
}
.similarStoneBlock {
  .similarStoneBlockItem {
    width: 100%;
    padding: 10px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    span {
      display: block;
      text-align: center;
      margin-top: 10px;
      color: #000;
    }
  }
}
.no-msg {
  width: 100%;
  font-size: 18px;
  color: #000;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.dnaImageSwipe .imageSliderFullBlock.no-msg {
  display: flex !important;
}
.downlaod_data {
  border-top: 1px solid #ddd;
  padding: 15px;
  text-align: center;
  p {
    font-weight: 600;
    font-size: 18px;
    color: #000;
    margin-bottom: 15px !important;
  }
  .dwn_liks {
    display: flex;
    justify-content: center;
    a {
      margin: 0 5px;
      img {
        width: 30px;
      }
    }
  }
  &.two_part {
    display: flex;
    justify-content: space-between;
    .stone_part {
      width: 50%;
      &:last-child {
        border-left: 1px solid #ddd;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  // .dnaImageTop{
  //   padding: 20px;
  // }
  .dnaDetailBlock {
    .detail {
      width: 33.33%;
    }
  }
}
@media screen and (max-width: 1366px) {
  .dnaLeftBoxImage {
    .imageSliderFullBlock {
      // height: 55vh;
      // height: 308.56px !important;
      position: relative;
    }
    .video-set {
      &.imageSliderFullBlock {
        height: 55vh;
        // height: 308.56px !important;
        position: relative;
      }
    }
  }
  .dnaLeftBoxImage {
    .video-set {
      &.imageSliderFullBlock {
        img {
          height: 54vh !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  // .diamondJourneyBlock{
  //     .diamondJourneyItem{
  //         width:100%;
  //         .diamondJourneyImage{
  //             img{
  //                 width: auto;
  //             }
  //         }
  //     }
  // }
  // .dnaImageSwipe{
  //     .imageSliderFullBlock{
  //         img,iframe{
  //             width: 420px;
  //             height: 420px;
  //         }
  //         &.width-100{
  //             iframe{
  //                 height: 450px !important;
  //             }
  //         }
  //     }
  // }
  .dnaContainerBox {
    padding: 10px 15px 0 15px;
    // padding: 60px 15px 0 15px;
    height: auto !important;
    .dnaWrapper {
      margin-bottom: 15px;
      .dnaWrapperBox {
        &.threeBlock1 {
          width: 40%;
        }
        &.threeBlock2 {
          width: 20%;
        }
      }
      .dnaImageSwipe {
        .video-set {
          &.imageSliderFullBlock {
            height: 420px;
            .magnifier {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .similarStoneScroll {
    height: 540px;
  }
  .dnaWrapper {
    flex-wrap: wrap;
  }
  .dnaWrapperBox {
    &.ivc_block {
      width: 100%;
      margin-bottom: 20px;
    }
    &.stone_detail_cont {
      width: 100%;
      margin-left: 0;
    }
  }
  // .dnaImageTop{
  //   padding: 20px;
  // }
  .dnaDetailBlock {
    .detail {
      width: 33.33%;
    }
  }
}
@media screen and (min-width: 990px) and (max-width: 1025px) {
  .dnaLeftBoxImage {
    .imageSliderFullBlock {
      // height: 680px;
      position: relative;
    }
    .video-set {
      &.imageSliderFullBlock {
        height: 680px;
        position: relative;
      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 825px) {
  .dnaLeftBoxImage {
    .imageSliderFullBlock {
      // height: 530px;
      position: relative;
    }
    .video-set {
      &.imageSliderFullBlock {
        height: 530px;
        position: relative;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .dnaContainerBox {
    padding: 10px 15px 0 15px;
    height: auto !important;
    .dnaWrapper {
      margin-bottom: 15px;
      .dnaWrapperBox {
        &.threeBlock1 {
          width: 40%;
        }
        &.threeBlock2 {
          width: 20%;
        }
      }
      .dnaImageSwipe {
        .imageSliderFullBlock {
          // height: 555px;
          // height: 420px;
          .magnifier {
            img {
              width: 100%;
            }
          }
        }
        .video-set {
          &.imageSliderFullBlock {
            height: 555px;
            // height: 420px;
            .magnifier {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .similarStoneScroll {
    height: 540px;
  }
  .dnaWrapper {
    flex-wrap: wrap;
  }
  .dnaWrapperBox {
    &.ivc_block {
      width: 100%;
      margin-bottom: 20px;
    }
    &.stone_detail_cont {
      width: 100%;
      margin-left: 0;
    }
  }
  // .dnaImageTop{
  //   padding: 20px;
  // }
  .dnaDetailBlock {
    .detail {
      width: 33.33%;
    }
  }
}
@media screen and (max-width: 767px) {
  .dnaLeftBoxImage {
    .imageSliderFullBlock {
      // height: 40vh !important;
      // height: 308.56px !important;
      position: relative;
    }
    .video-set {
      &.imageSliderFullBlock {
        height: 40vh !important;
        // height: 308.56px !important;
        position: relative;
      }
    }
  }
}
@media (max-width: 767px) {
  .dnaContainerBox {
    padding: 0px 0px 0 0px;
    height: auto !important;
    .dnaWrapper {
      flex-wrap: wrap;
      background-color: #fff;
      .dnaWrapperBox {
        width: 100%;
        // margin: 10px 0;
        &.ivc_block {
          position: relative;
          // top: 93px;
          // top: 86px;
          .dnaImageSwipe {
            .dnaLeftBox {
              .dnaLeftBoxImage {
                .sliderSmallImage {
                }
                .sliderMainImage {
                }
              }
            }
          }
        }

        &.stone_detail_cont {
          position: relative;
          // position: relative;
          // margin: 10px;
          // margin: 30px 10px 10px;

          // top: 97px;
          // top: 86px;
          .dnaLeftRightDetil {
            // height: calc(100% - 0px);
            // height: calc(100% - 66px);
          }
        }
      }
      .dnaImageSwipe {
        .imageSliderFullBlock {
          .magnifier {
            img {
              width: 100%;
            }
          }
        }
        .sliderTwoImageBlock {
          .imageSliderFullBlock {
            height: auto;
          }
          .video-set {
            &.imageSliderFullBlock {
              height: auto;
            }
          }
        }
      }
    }
    .similarStoneScroll {
      height: 470px;
      .no-msg {
        min-height: 470px;
      }
    }
    .dnaWrapperBox {
      margin: 0;
    }
    .dnaLeftRightDetil {
      .dnaDetailBlock {
        width: 100%;
        // margin-bottom: 15px;
        // border-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
        .mainHeadingTitle {
          background: #434443;
          // background: #3a8ed2;
          color: #fff;
          padding: 5px 10px;
          text-align: center;
          margin-bottom: 0;
          justify-content: center;
        }
      }
    }
    .dnaLeftBoxImage {
      .imageSliderFullBlock {
        img {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
    .diamondJourneyBlock {
      flex-wrap: wrap;
      .diamondJourneyItem {
        width: 100%;
      }
    }
    .dnaJourenySticky {
      iframe {
        height: 400px;
      }
    }
  }
  .dnaImageTop {
    &.mainBox {
      text-align: center;
    }
    .dnaDetailShareOption {
      position: relative;
      margin: auto;
      right: 0;
      left: 0;
      width: max-content;
    }
  }
  .qrCodeOption {
    display: none;
  }
  .dnaImageTop.mainBox {
    padding: 5px;
    .unique_logo {
      position: relative;
      left: auto;
      top: auto;
      margin-bottom: 5px;
    }
  }
  .dnaImageTop {
    .dnaDetailShareOption {
      .dnaShareOption {
        height: 25px;
      }
    }
  }
  .dnaLeftBoxImage {
    .imageSliderFullBlock {
      width: 100% !important;
      // height: auto !important;
    }
    .video-set {
      &.imageSliderFullBlock {
        width: 100% !important;
        // height: auto !important;
      }
    }
  }
  .dnaImageSwipe {
    .imageSliderFullBlock {
      iframe {
        // min-height: 271px;
      }
    }
    .video-set {
      &.imageSliderFullBlock {
        iframe {
          // min-height: 271px;
        }
      }
    }
  }
  .dnaDetailBlock {
    .detail {
      width: 33.33%;
      margin-bottom: -1px;
      // width: 100%;

      span {
        &:first-child {
          width: 100%;
        }
      }
    }
    &.full-width {
      .detail {
        width: 50%;
      }
    }
    &.three-col {
      .detail {
        width: 33.33%;
        // width: 100%;
      }
    }
  }
}

// @media screen and (min-width: 428px) and (max-width: 429px) {
//   .dnaImageSwipe .video-set.imageSliderFullBlock iframe,
//   .dnaImageSwipe .video-set.imageSliderFullBlock img {
//     margin-top: -29px;
//   }
// }

@primary-color: #434443;