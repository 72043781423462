@import 'variable.less';

.homeContainer {
  max-width: 1440px;
  margin: auto;
}
.topBannerBlock {
  position: relative;
  .topbannerImage {
    height: 50vh;
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .topBannerContent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 250px;
    width: 256px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-width: 1440px;
    margin: auto;
    right: 0;
    padding: 0 45px;
    h2 {
      width: 100%;
      font-size: 30px;
      line-height: 41px;
      font-weight: 600;
    }
    p {
      color: #000;
      font-size: 14px;
    }
    a {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px !important;
      text-decoration: underline;
    }
  }
}

// .dashboardBannerBox {
//   .deal_block_wrap {
//     .deal_block_container {
//       border-radius: 10px;
//       width: 100%;
//       // width: calc(~'40% - 10px');
//       // display: flex;
//       // flex-wrap: wrap;
//       // justify-content: space-between;
//       margin: 10px auto;
//       background-color: #e9e9e9;
//       // background-color: rgba(65, 65, 67, 0.02);
//       border: 1px solid rgba(65, 65, 67, 0.09);
//       border-radius: 10px;
//       padding: 10px;
//       height: 473px;
//       .slider_block {
//         background: #f2f2f2;
//         // background: #fff;
//         border-radius: 10px;
//         padding: 10px 0px;
//         height: 452px;
//         // width: calc(~'50% - 5px');
//         // margin-bottom: 10px;
//         .title_block {
//           display: flex;
//           align-items: center;
//           justify-content: space-between;
//           margin-bottom: 3px;
//           padding: 0px 15px 5px;
//           border-bottom: 2px solid rgb(224, 224, 224);
//           h3 {
//             text-transform: uppercase;
//             font-weight: 600;
//             font-size: 15px;
//             margin-bottom: 0px;
//           }
//           a {
//             font-weight: 500;
//             font-size: 14px;
//             text-transform: uppercase;
//             text-decoration: underline;
//           }
//         }
//         .slick-next {
//           right: 10px;
//         }
//         .slick-prev {
//           left: 10px;
//         }
//         .slick-prev,
//         .slick-next {
//           background: #fff;
//           padding: 0 5px;
//           width: 30px;
//           height: 30px;
//           z-index: 1;
//           top: 35%;

//           box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
//           &:before {
//             font-size: 13px;
//             color: #000;
//           }
//         }
//         .daimondGridViewItem {
//           width: 100%;
//           // border: 1px solid #e2e2e2;
//           // display: flex !important;
//           // justify-content: space-between;
//           // align-items: center;
//           padding: 0 20px;
//           margin-top: 10px;
//           .DiamondGridViewImg {
//             // width: 50%;
//             // height: 250px;
//             // background: #fafafa;
//             // position: relative;

//             // position: relative;
//             // cursor: pointer;
//             // border-radius: 10px;
//             // padding-top: 10px;
//             width: 100%;
//             height: 250px;
//             background: #fafafa;
//             border-radius: 10px 10px 0 0;
//             position: relative;
//             display: -webkit-flex;
//             display: flex;
//             img {
//               // width: 100%;
//               // height: 100%;
//               // border-radius: 10px;
//               // height: auto;
//               // height: 520px;
//               // object-fit: cover;
//               // border-radius: 10px;

//               width: 100%;
//               height: 100%;
//               object-fit: contain;
//               border-radius: 10px 10px 0 0;
//               margin: auto;
//               background-color: #f2f2f2;
//             }
//           }
//           .gridViewContent {
//             display: flex;
//             flex-wrap: wrap;
//             // width: 50%;
//             padding: 10px 0px 10px 0px;
//             .gridLeftContent {
//               width: 100%;
//               text-align: left;
//               .st_id {
//                 justify-content: space-between;
.st-two {
  display: inline-flex;
}

.st-ml-5 {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 500;
}
//               }
//               h2 {
//                 font-size: 16px;
//                 font-weight: 800;
//                 margin-bottom: 5px;
//                 line-height: 20px;
//               }
//               a {
//                 margin-bottom: 5px;
//                 color: #000;
//                 display: block;
//                 font-weight: 500;
//                 text-decoration: underline;
//                 &.labName {
//                   text-decoration: none;
//                 }
//               }
//               p {
//                 margin: 0;
//                 font-size: 14px;
//                 color: #353535;
//               }
//               // }
//               // .gridRightContent {
//               // width: 40%;
//               // text-align: right;
//               span {
//                 display: block;
//                 color: #000;
//                 font-weight: 500;
//                 font-size: 16px;
//                 &.st_amt {
//                   font-weight: 700;
//                   margin-top: 10px;
//                 }
//               }
//               .statusShow {
//                 display: -webkit-flex;
//                 display: flex;
//                 -webkit-align-items: center;
//                 align-items: center;
//                 -webkit-justify-content: flex-end;
//                 justify-content: flex-end;
//                 font-size: 14px;
//                 margin-top: 5px;
//                 .statusIcon {
//                   margin-left: 5px;
//                   width: 8px;
//                   height: 8px;
//                   border-radius: 100%;
//                   display: block;
//                   background: #6bc950;
//                   &.red {
//                     background-color: #db1c1c;
//                   }
//                 }
//               }
//             }
//           }
//         }
//         // &.dashboard_right{
//         //   margin-top: 0;
//         //   height: auto;
//         //   position: relative;
//         //   top: 0;
//         //   .diamond_data{
//         //     padding: 0;
//         //     margin-bottom: 0;
//         //     .diamond_block_wrap{
//         //       height: calc(~'100vh - 333px');
//         //     }
//         //   }
//         // }
//       }
//       .bannerImg {
//         width: 60%;
//         padding-left: 0px;
//         // width: calc(~'60% - 10px');
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         .banner_container {
//           // margin-bottom: 10px;
//           width: 100%;
//           display: block;
//           &:first-child {
//             margin-bottom: 10px;
//           }
//         }
//         img {
//           max-width: 100%;
//           border-radius: 10px;
//           width: 100%;
//         }
//       }
//     }
//   }
// }

.quickMenuBox {
  background: linear-gradient(180deg, #fffdfa 0%, rgba(255, 249, 244, 0.08) 100%);
  margin-bottom: 50px;
  // background-image: url("../assets/img/dashboard/quickmenu1.png");
  background-repeat: no-repeat;
  position: relative;
  background-position: bottom left -249px;
  overflow: hidden;
  .quickMenuLogo {
    background-image: url('../assets/img/logo.png');
    position: absolute;
    bottom: 0;
  }
  &:before {
    content: '';
    width: 100%;
    height: 400px;
    background-image: url('../assets/svg/Dashboard/bg.svg');
    position: absolute;
    left: 0;
    background-size: contain;
    background-repeat-y: no-repeat;
    opacity: 0.1;
    // transform: rotate(90deg);
    bottom: 0;
  }
  // &:after{
  //     content: "";
  //     width: 400px;
  //     height: 400px;
  //     // background-color: rgba(255, 255, 255, 0.7);
  //     // background:linear-gradient(180deg, #FFFDFA 0%, rgba(255 ,249 ,244 , 0.08) 100%);
  //     background-image: url("../assets/img/dashboard/quickmenu1.png");
  //     position: absolute;
  //     top: -231px;
  //     right: -194px;
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //     opacity: 0.2;
  //     transform: rotate(90deg);
  // }
}
.quickMenuBlock {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;
  // align-items: center;
  .quickMenuItem {
    width: calc(12.5% - 10px);
    margin: 5px 5px 20px 5px;
    text-align: center;
    // border: 1px solid #ceab54;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    cursor: pointer;
    // background: rgba(206,171,84,10%);
    &.blackQuickMenu {
      width: 0;
      padding: 0;
    }
    img {
      width: 45px;
      height: 45px;
      // margin-bottom: 5px;
    }
    .count {
      font-size: 24px;
      font-weight: 600;
      margin: 10px auto;
      width: 80px;
      color: #000;
      height: 80px;
      // border: 1px solid #ceab54;
      // background: rgba(206 ,171 ,84 , 0.18);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // background-image: url("../assets/svg/Dashboard/bg.svg");
      // background-position: center;
      // background-size: 300%;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
      background-color: #fff;
      position: relative;
      padding: 3px;
      &:after {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        content: '';
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        // top: -4px;
        // left: -4px;
        padding: 0;
        z-index: 1;
        // border: 3px dashed transparent;
        background: transparent;
      }
      span {
        background: #fff;
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        z-index: 2;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .quickMenuTitle {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      width: 100%;
      margin-bottom: 4px;
    }
    a {
      color: #000;
      font-weight: 600;
      text-decoration: underline;
      width: 100%;
      &:hover {
        color: @themeColor;
      }
    }
    &:hover {
      .count {
        -webkit-transition: box-shadow 0.2s;
        -moz-transition: box-shadow 0.2s;
        transition: box-shadow 0.2s;
        &:after {
          -webkit-animation: spinAround 9s linear infinite;
          -moz-animation: spinAround 9s linear infinite;
          animation: spinAround 9s linear infinite;
          background: @headerStripColor;
          // border: 3px dashed @themeColor;
        }
      }
    }
  }
}
@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.homeBanner1 {
  position: relative;
  margin-bottom: 50px;
  .homeBanner1Img {
    width: 100%;
    .homeBannerImageBlock {
      height: 488px;
    }
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.homeBanner1Conetnt {
  position: absolute;
  bottom: 15px;
  width: 450px;
  margin: auto;
  padding: 15px 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 10px;
  }
  a {
    font-size: 15px;
    text-decoration: underline;
    color: #000;
    font-weight: 500;
    &:hover {
      color: @themeColor;
    }
  }
  p {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px !important;
  }
}
.homeTwoBanner {
  display: flex;
  margin-bottom: 50px;
  justify-content: space-between;
  .hometwoBannerItem {
    width: calc(50% - 20px);
    margin: 10px 0;
    height: 666px;
    position: relative;
    // border-radius:10px;
    &.leftBlock {
      background: #f2ebe5;
    }
    .homeTwoBannerImage {
      width: 100%;
      height: 666px;
      img,
      video {
        // border-radius: 0 0 10px 10px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .homeTwoBannerContent {
      padding: 65px 15px 15px 15px;
      bottom: auto;
      text-align: center;
      // position: relative;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      width: 100%;
    }
    &.rightBlock {
      .homeTwoBannerContent {
        padding: 15px 15px 65px 15px;
        width: 100%;
        // background: linear-gradient(90deg, #020202, #121212, #000);
        top: auto;
        bottom: 0;
        p,
        h2,
        a {
          color: #fff;
        }
        a {
          &:hover {
            color: @themeColor;
          }
        }
      }
    }
  }
}

.footerStickyIcon {
  position: sticky;
  z-index: 1;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
  padding: 8px 15px;
  .footerStickyLabel {
    font-size: 16px;
    font-weight: 600;
    margin-right: 15px;
    color: #000;
  }
}

.footerDropdownBlock {
  position: absolute;
  width: 50%;
  // height: 0;
  background-color: #fff;
  right: 0;
  box-shadow: -5px -5px 16px 0px rgba(0, 0, 0, 0.09);
  border-radius: 10px 0 0 0;
  bottom: 53px;
  opacity: 0;
  transform: translateY(48px);
  transition: translateY, all 0.3s ease-in-out;
  padding: 15px;
  visibility: hidden;
  overflow: hidden;
  &.active {
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transform: translateY(0);
  }
}
.sacedSearchBox {
  height: 345px;
  overflow: auto;
  .savedSearchItem {
    background-color: #f5f6fa;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .savedSearchHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      border-bottom: 1px solid #ddddea;
      padding-bottom: 5px;
    }
    .bottomSection {
      margin-top: 5px;
      border-top: 1px solid #ddddea;
      padding-top: 5px;
    }
    .searchTime {
      color: #000;
      font-size: 13px;
      span {
        // margin: 0 2px;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
    .searchTitle {
      font-weight: 500;
      font-size: 14px;
      color: #000;
    }
    .savedSearchDetail {
      display: flex;
      flex-wrap: wrap;
      .detail {
        margin-right: 10px;
        display: flex;
        flex-wrap: wrap;

        align-items: center;
        span {
          &:first-child {
            text-transform: uppercase;
            margin-right: 5px;
            font-size: 12px;
            color: #000;
          }
          &:last-child {
            color: #000;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
.newsBlockWrapper {
  &.sacedSearchBox {
    .savedSearchHead {
      display: block;
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
.newsBlockImg {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 100%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
}

.recentActivityBlock {
  .savedSearchHead {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }
}
.leftRightBlock {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  background: #f6f6f6;
  .leftBlock {
    left: 0;
    top: 0;
    bottom: 0;
    right: auto;
    margin: auto;
    height: 120px;
  }
}
.clauFilter {
  .from-group {
    width: calc(25% - 10px);
    margin: 0 5px;
  }
  .calcRapPrice {
    .ant-input-number {
      width: 100%;
      border: none;
      border-bottom: 1px solid #e4e4e4;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.lightBg {
  padding: 0 0 50px 0;
  display: flex;
  align-items: center;
  // background: linear-gradient(to bottom, #f8f8f8,#fff,#fff, #fff);
  .homeBanner1Conetnt {
    position: relative;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    padding: 65px 40px;
    text-align: left;
    width: 50%;
    margin-right: 0;
  }
  .homeBanner1Img {
    text-align: center;
    width: 50%;
    padding-right: 25px;
    .homeBannerImageBlock {
      height: 400px;
    }
    img,
    video {
      width: 100%;
      margin: auto;
    }
  }
}
.clauBox {
  display: flex;
  // background-color: @themeLightColor;
  padding: 15px;
  border-radius: 20px;
  margin-top: 20px;
  .calcuListBox {
    align-items: center;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 14px;
      &:first-child {
        margin-right: 10px;
        color: #000;
        min-width: 50px;
      }
      &:last-child {
        color: #000;
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
  label {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
    color: #000;
  }
  .calcuLeftBox {
    width: 50%;
    background-color: @themeLightColor;
    padding: 15px;
    border-radius: 10px;
    margin-right: 10px;
    &.calcuRightBlock {
      background: #c09e7c;
      padding: 15px;
      border-radius: 10px;
      label {
        color: #fff;
      }
      span {
        &:first-child {
          color: #fff;
        }
        &:last-child {
          color: #ffffff;
        }
      }
    }
  }
}
.footerSection {
  background-image: url('../assets/svg/Dashboard/bg.svg');
  // background-repeat: no-repeat;
  position: relative;
  background-position: center;
  padding: 15px 0;
  /* border-top: 2px solid rgb(192 158 124 / 18%); */
  background-color: rgba(56, 56, 56, 0.62);
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(56, 56, 56, 0.62);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .homeContainer {
    position: relative;
    z-index: 1;
    // padding-top: 50px;
  }
}
.footerWrapper {
  // padding-bottom: 50px;
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
  ul {
    padding: 0;
    margin: 0;
    width: auto;
    display: flex;
    li {
      list-style: none;
      line-height: 30px;
      color: #fff;
      font-weight: 600;
      a {
        text-transform: uppercase;
        color: #fff;
        font-size: 14px;
        letter-spacing: 0.5px;
        margin: 0 10px;
        font-weight: 500;
        &:hover {
          color: @themeColor;
        }
      }
    }
  }
}
.tracklistButton {
  .commonButton {
    padding: 2px 5px;
  }
}
.dashboardWrapper {
  .brandLogoContain {
    max-width: 1440px;
    padding: 0 15px;
    margin: auto auto 50px auto;
  }
}
.center-banner {
  width: 83% !important;
  top: auto;
  .ant-modal-body {
    // height: calc(100vh - 100px);
    img {
      max-width: 100%;
    }
  }
  .ant-modal-close-x {
    border-radius: 50px;
    background-color: #e8e8e894;
    width: 27px !important;
    height: 29px !important;
    line-height: 32px !important;
    font-size: 14px !important;
  }
}

.dashboardWrapper {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;

  .dashbaord_left {
    width: 70%;
    .dashbaord_banner {
      width: 100%;
      border-radius: 10px;
      border: 1px solid #ddd;
      overflow: hidden;
      margin-top: 15px;
      &.top_banner {
        display: flex;
        img {
          max-height: 200px;
          object-fit: cover;
        }
      }
      img {
        max-width: 100%;
        width: 100%;
      }
      video {
        width: 100%;
      }
    }
    .dashbaord_bottom_img {
      height: calc(100vh - 315px);
      img {
        height: 100%;
        // object-fit: cover;
      }
    }
    @media (min-width: 1400px) {
      .dashbaord_bottom_img {
        height: calc(100vh - 325px);
      }
    }
    @media (min-width: 1600px) {
      .dashbaord_bottom_img {
        height: calc(100vh - 380px);
      }
    }
    @media (min-width: 1920px) {
      .dashbaord_bottom_img {
        height: calc(100vh - 400px);
      }
    }

    .my_count {
      width: 100%;
      margin-top: 15px;
      border-radius: 10px;
      // padding:0px 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .search-count-box {
        background-color: #bee1e65b !important;
        // background-color: #9d9e9e38  !important;
      }
      .couple-block {
        background-color: #fec5bb63 !important;
      }
      .enquiry-box {
        background-color: #4d194d1f !important;
      }
      .quote-box {
        background-color: #ffbe6926 !important;
      }
      .watchlist-box {
        background-color: #00356615 !important;
      }
      .cart-box {
        background-color: #ffcbf25d !important;
      }
      .upcoming-box {
        background-color: #a0c4ff3b !important;
      }
      .special-stone-box {
        background-color: #beb2ff41 !important;
      }
      .bid-count-box {
        background-color: #eb90b630 !important;
      }
      .special-count-box {
        background-color: #8ccfe938 !important;
      }
      .upcoming-count-box {
        background-color: #7af15326 !important;
      }
      .appointment-box {
        background-color: #7af15326 !important;
      }
      .bid-count-box {
        background-color: #f2826633 !important;
      }
      .bid-cart-box {
        background-color: #480ca817 !important;
      }
      .bid-box {
        background-color: #05f2d223 !important;
      }
      .count_block {
        background: #fff !important;
        border-radius: 10px;
        width: calc(25% - 10px);
        margin: 0 5px 10px;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        // border: 1px solid rgba(0, 141, 210,0.2);
        // box-shadow: 0 0 10px rgba(0, 141, 210,0.2);
        .icon {
          width: 40px;
          margin-right: 15px;
        }
        .count_text {
          width: calc(~'100% - 55px');
          display: inline !important;
        }
        h3,
        h1 {
          margin: 0;
        }
        h3 {
          font-weight: 500;
          font-size: 15px;
          color: #4c4d4f;
          text-transform: capitalize;
        }
        h1 {
          font-weight: 600;
          font-size: 27px;
          line-height: 1;
        }
      }
    }
    .collection_wrap {
      display: flex;
      align-items: center;
      &.single-banner {
        .collection_banner {
          width: 100%;
        }
      }
      &.two-banner {
        .collection_banner {
          width: calc(~'50% - 10px');
        }
      }
      &.three-banner {
        .collection_banner {
          width: calc(~'33.33% - 10px');
        }
      }
      .collection_banner {
        margin: 5px;
        img {
          max-width: 100%;
          border-radius: 10px;
        }
      }
    }
    .new-slier-deshbord {
      .deal_block_wrap {
        // display: flex;
        // flex-wrap: wrap;
        // align-items: flex-start;
        // padding: 0 5px;
        // margin-top: 15px;
        // margin-bottom: 15px;
        // justify-content: space-between;
        // border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
        width: 100%;
        .title_block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 3px;
          // background: @whiteColor;
          padding: 0px 15px 5px;
          border-bottom: 2px solid #e0e0e0;
          h3 {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 15px;
            margin-bottom: 0;
            color: @themeColor;
          }
          a {
            font-weight: 500;
            text-decoration: underline;
            font-size: 14px;
            text-transform: uppercase;
          }
        }

        .deal_block_container {
          border-radius: 10px;
          width: 100%;
          // width: calc(~'40% - 10px');
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-between;

          &.das-w-100 {
            width: 100%;
            .slick-slider {
              padding: 0px 40px;
            }
            .slider_block {
              // .slider-height {
              //   height: 380px;
              // }
              .sider-part {
                // display: flex;
                // justify-content: center;
                .sider-part-3 {
                  display: flex !important;
                  justify-content: center;
                  margin: 0px 10px;
                  height: 200px;
                  align-items: center;
                  object-fit: cover;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }
          }
          .slider_block {
            background: @whiteColor;
            border-radius: 10px;
            padding: 10px 0px;
            // width: calc(~'50% - 5px');
            margin-bottom: 26px;

            .slick-next {
              right: 10px;
            }
            .slick-prev {
              left: 10px;
            }
            .slick-prev,
            .slick-next {
              background: @whiteColor;
              padding: 0 5px;
              width: 30px;
              height: 30px;
              z-index: 1;
              top: 35%;

              box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
              &:before {
                font-size: 13px;
                color: #000;
              }
            }
            .daimondGridViewItem {
              width: 100%;
              // border: 1px solid #e2e2e2;
              // display: flex !important;
              // justify-content: space-between;
              // align-items: center;
              padding: 0 20px;
              margin-top: 10px;
              .DiamondGridViewImg {
                // width: 50%;
                // height: 250px;
                // background: #fafafa;
                // position: relative;

                // position: relative;
                // cursor: pointer;
                // border-radius: 10px;
                // padding-top: 10px;
                width: 100%;
                height: 250px;
                // background: #fafafa;
                background: #f2f2f2;
                border-radius: 10px 10px 0 0;
                position: relative;
                display: -webkit-flex;
                display: flex;
                img {
                  // width: 100%;
                  // height: 100%;
                  // border-radius: 10px;
                  // height: auto;
                  // height: 520px;
                  // object-fit: cover;
                  // border-radius: 10px;

                  width: 70%;
                  height: 100%;
                  object-fit: contain;
                  border-radius: 10px 10px 0 0;
                  margin: auto;
                  background-color: #f2f2f2;
                }
              }
            }
          }
          // &.dashboard_right{
          //   margin-top: 0;
          //   height: auto;
          //   position: relative;
          //   top: 0;
          //   .diamond_data{
          //     padding: 0;
          //     margin-bottom: 0;
          //     .diamond_block_wrap{
          //       height: calc(~'100vh - 333px');
          //     }
          //   }
          // }
        }
        .bannerImg {
          width: 100%;
          // width: 60%;
          padding-left: 0px;
          // width: calc(~'60% - 10px');
          display: flex;
          flex-wrap: wrap;
          // flex-direction: column;
          justify-content: space-between;
          .banner_container {
            // margin-bottom: 10px;
            width: 100%;
            // width: 49%;
            height: 49%;
            // width: 100%;
            display: block;
            &:first-child {
              // margin-bottom: 10px;
            }
          }
          img {
            max-width: 100%;
            border-radius: 10px;
            // width: 96%;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .deal_block_wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 0 5px;
      margin-top: 15px;
      margin-bottom: 15px;
      justify-content: space-between;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .title_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3px;
        // background: @whiteColor;
        padding: 0px 15px 5px;
        border-bottom: 2px solid #e0e0e0;
        h3 {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 15px;
          margin-bottom: 0;
          color: @themeColor;
        }
        a {
          font-weight: 500;
          text-decoration: underline;
          font-size: 14px;
          text-transform: uppercase;
        }
      }

      .deal_block_container {
        width: 100%;
        margin: 10px auto;
        background-color: #e9e9e9;
        border: 1px solid rgba(65, 65, 67, 0.09);
        border-radius: 10px;
        padding: 10px;
        height: 473px;

        // border-radius: 10px;
        // width: calc(~'40% - 10px');
        // width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;

        // &.das-w-100 {
        //   width: 100%;
        //   .slick-slider {
        //     padding: 0px 40px;
        //   }
        //   .slider_block {
        //     // .slider-height {
        //     //   height: 380px;
        //     // }
        //     .sider-part {
        //       // display: flex;
        //       // justify-content: center;
        //       .sider-part-3 {
        //         display: flex !important;
        //         justify-content: center;
        //         margin: 0px 10px;
        //         height: 200px;
        //         align-items: center;
        //         object-fit: cover;

        //         img {
        //           width: 100%;
        //           height: 100%;
        //           object-fit: cover;
        //         }
        //       }
        //     }
        //   }
        // }
        .slider_block {
          background: #f2f2f2;
          border-radius: 10px;
          padding: 10px 0px;
          height: 452px;

          // background: @whiteColor;
          // border-radius: 10px;
          // padding: 10px 0px;
          // margin-bottom: 26px;
          // width: calc(~'50% - 5px');

          .slick-next {
            right: 10px;
          }
          .slick-prev {
            left: 10px;
          }
          .slick-prev,
          .slick-next {
            background: @whiteColor;
            padding: 0 5px;
            width: 30px;
            height: 30px;
            z-index: 1;
            top: 35%;

            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            &:before {
              font-size: 13px;
              color: #000;
            }
          }
          .daimondGridViewItem {
            width: 100%;
            // border: 1px solid #e2e2e2;
            // display: flex !important;
            // justify-content: space-between;
            // align-items: center;
            padding: 0 20px;
            margin-top: 10px;
            .DiamondGridViewImg {
              // width: 50%;
              // height: 250px;
              background: #fafafa;
              position: relative;
              .noimagefound {
                width: 66px !important;
                height: 66px !important;
                border-radius: 0 !important;
              }
              img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
              }
            }
            .gridViewContent {
              display: flex;
              flex-wrap: wrap;
              // width: 50%;
              padding: 10px 0px 10px 0px;
              .gridLeftContent {
                width: 100%;
                text-align: left;
                .st_id {
                  justify-content: space-between;
                }
                h2 {
                  font-size: 16px;
                  font-weight: 800;
                  margin-bottom: 5px;
                  line-height: 20px;
                }
                a {
                  margin-bottom: 5px;
                  color: #000;
                  display: block;
                  font-weight: 500;
                  text-decoration: underline;
                  &.labName {
                    text-decoration: none;
                  }
                }
                p {
                  margin: 0;
                  font-size: 14px;
                  color: #353535;
                }
                // }
                // .gridRightContent {
                // width: 40%;
                // text-align: right;
                span {
                  display: block;
                  color: #000;
                  font-weight: 500;
                  font-size: 16px;
                  &.st_amt {
                    font-weight: 700;
                    margin-top: 10px;
                  }
                }
                .statusShow {
                  display: -webkit-flex;
                  display: flex;
                  -webkit-align-items: center;
                  align-items: center;
                  -webkit-justify-content: flex-end;
                  justify-content: flex-end;
                  font-size: 14px;
                  margin-top: 5px;
                  .statusIcon {
                    margin-left: 5px;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    display: block;
                    background: #6bc950;
                    &.red {
                      background-color: #db1c1c;
                    }
                  }
                }
              }
            }
          }
        }
        // &.dashboard_right{
        //   margin-top: 0;
        //   height: auto;
        //   position: relative;
        //   top: 0;
        //   .diamond_data{
        //     padding: 0;
        //     margin-bottom: 0;
        //     .diamond_block_wrap{
        //       height: calc(~'100vh - 333px');
        //     }
        //   }
        // }
      }
      .btn_slider {
        height: 100% !important;
        .slider_block {
          &.bottom_one {
            height: 100%;
            padding-bottom: 0px;
          }
        }
      }

      .slider_block {
        &.bottom_one {
          position: relative;
          .collection_banner {
            position: relative;
            cursor: pointer;
            border-radius: 10px;
            img {
              width: 100%;
              height: 300px;
              object-fit: fill;
              border-radius: 10px;
            }
          }
        }
      }
      .bannerImg {
        width: 100%;
        // width: 60%;
        padding-left: 0px;
        // width: calc(~'60% - 10px');
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;
        justify-content: space-between;
        .banner_container {
          // margin-bottom: 10px;
          width: 100%;
          // width: 49%;
          height: 49%;
          // width: 100%;
          display: block;
          &:first-child {
            // margin-bottom: 10px;
          }
        }
        img {
          max-width: 100%;
          border-radius: 10px;
          // width: 96%;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .dashboard_right {
    width: 30%;
    padding-left: 10px;
    margin-top: 15px;
    height: calc(100vh - 72px);
    // height: calc(100vh - 92px);
    position: sticky;
    top: 73px;
    .search_tabs {
      background: @whiteColor;
      .react-tabs__tab-list {
        border-bottom: 4px solid #e0e0e0;
        margin-bottom: 0;
        .react-tabs__tab {
          font-size: 15px;
          font-weight: 500;
          text-transform: capitalize;
          width: 50%;
          border-right: 2px solid #e0e0e0 !important;
          text-align: center;
          &:first-child {
            border-top-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 10px;
            border-right: 0 !important;
          }
        }
        .react-tabs__tab--selected {
          border: 0;
          border-bottom: 2px solid @themeSecondColor;
          border-radius: 0;
          font-size: 15px;
          font-weight: 600;
          color: @themeDarkBoreder;
        }
        .react-tabs__tab:focus {
          box-shadow: none;
          border: 0;
        }
        .react-tabs__tab:focus:after {
          background: transparent;
        }
      }
    }
    .shortcuts_block {
      margin-bottom: 20px;

      ul {
        margin: 0px 0 5px;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;
        li {
          width: calc(~'20% - 10px');
          text-align: center;
          background: @whiteColor;
          padding: 10px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 35px;
          }
        }
      }
    }
    .diamond_data {
      border-radius: 10px;
      // padding: 10px;
      margin-bottom: 6px;
      .title {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 15px;
      }
      .title_block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 10px;
        padding: 5px 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: 2px solid #e0e0e0;
        background: #fff;
        .title {
          margin-bottom: 0;
          color: @themeDarkBoreder;
        }
        .contact_links {
          display: flex;
          align-items: center;
          a {
            margin: 0 5px;
            img {
              width: 18px;
            }
          }
        }
      }
      .diamond_block_wrap {
        height: calc(100vh - 585px);
        min-height: 110px;
        overflow: hidden;
        overflow-y: auto;
        padding: 10px;
        width: calc(~'100% - 0px');
        // width: calc(~'100% - 5px');
        // display: flex;
        // align-items: flex-start;
        // justify-content: center;
        // flex-wrap: wrap;
        .no_data {
          font-size: 16px;
          font-weight: 600;
          color: @themeDarkBoreder;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .dashbaord_right_continer {
          display: flex !important;
          justify-content: center !important;
          a {
            text-decoration: underline;
          }
        }
      }
      .diamond_block {
        border-radius: 10px;
        margin-bottom: 10px;
        background: #f9f9f9;
        padding: 5px;
        cursor: pointer;
        width: 100%;
        .diamond_block_data {
          display: flex;

          flex-wrap: wrap;
          p {
            margin: 0 10px 0 0;
            text-transform: uppercase;
            font-size: 12px;

            span {
              font-weight: 600;
            }
          }
        }
        .d_time {
          font-size: 12px;
          color: #4c4d4f;
          border-top: 1px solid #ddd;
          &:first-child {
            border-top: 0;
          }
        }
        &.sales_person_details {
          padding: 0px;
          background: transparent;
          border-radius: 0;
          .sales_content {
            margin-bottom: 3px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: #fff;
            padding: 15px;
            .contact_links {
              display: flex;
              align-items: center;
              img {
                width: 25px;
              }
            }

            .cnt_no {
              width: 50%;
              margin-left: 10px;
              &::before {
                content: '|';
              }
              img {
                margin-left: 10px;
              }
            }
            span {
              color: #000;
            }
            img {
              margin-right: 10px;
            }
            a {
              // width: 30px;
              // height: 30px;
              // border-radius: 30px;
              padding: 5px;
              // border : 1px solid #999;
              // margin-top: 20px;
              // margin-right: 10px;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              // svg{
              //     margin: 0;
              //     width: 18px;
              //     fill:#999;
              // }
              // &:hover{
              //     background:#e4e4fb;
              //     border-color: #282870;
              //     svg{
              //         fill:#282870;
              //     }
              // }
            }
          }
          .ph_mail {
            display: flex;
            align-items: center;
            a {
              width: 50%;
              text-align: center;
              padding: 5px;
              background: @whiteColor;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              &.ph {
                background: @themeColor;
                color: @whiteColor;
              }
            }
          }
        }
      }
    }
  }
  .bg_light {
    background: #9d9e9e38;
  }
}
.bg_grey_new {
  background: #e0e0e0;
}
@media screen and (max-width: 1250px) {
  .dashboardWrapper {
    .dashbaord_left {
      .deal_block_wrap {
        .slider_block {
          &.bottom_one {
            .collection_banner {
              img {
                height: 250px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .dashboardWrapper {
    flex-wrap: wrap;
    .dashbaord_left,
    .dashboard_right {
      width: 100%;
    }
    .dashbaord_left {
      .my_count {
        overflow-x: auto;
        flex-wrap: nowrap;
        .count_block {
          width: calc(100% - 10px);
          min-width: 180px;
        }
      }
      .dashbaord_bottom_img {
        height: auto !important;
      }
      .deal_block_wrap {
        .slider_block {
          &.bottom_one {
            .collection_banner {
              img {
                height: 250px;
              }
            }
          }
        }
      }
    }
    .dashboard_right {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;

      .diamond_data {
        width: calc(50% - 10px);
        margin-right: 10px;
        &:last-child {
          width: 100%;
        }
        .diamond_block_wrap {
          max-height: 240px !important;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .topBannerBlock {
    .topbannerImage {
      height: 30vh;
    }
  }
  .quickMenuBox {
    margin-bottom: 0;
    .homeContainer {
      .quickMenuBlock {
        .quickMenuItem {
          .count {
            width: 60px;
            height: 60px;
            font-size: 18px;
          }
          .quickMenuTitle {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
  .homeContainer {
    padding: 0 15px;
  }
  .homeBanner1 {
    margin-bottom: 30px;
    .homeBanner1Img {
      .homeBannerImageBlock {
        height: 288px;
      }
    }
  }
  .homeTwoBanner {
    margin-bottom: 30px;
    .hometwoBannerItem {
      height: 444px;
      margin: 0;
      .homeTwoBannerImage {
        height: 444px;
      }
      &.rightBlock,
      &.leftBlock {
        .homeTwoBannerContent {
          padding: 15px 15px 15px 15px;
        }
      }
    }
  }
  .dashboardWrapper {
    flex-wrap: wrap;
    .dashbaord_left,
    .dashboard_right {
      width: 100%;
    }
    .dashbaord_left {
      .my_count {
        overflow-x: auto;
        flex-wrap: nowrap;
        .count_block {
          width: calc(100% - 10px);
          min-width: 180px;
        }
      }
      .dashbaord_bottom_img {
        height: auto !important;
      }
    }
    .dashboard_right {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      .diamond_data {
        width: calc(50% - 10px);
        // margin-right: 10px;
        .diamond_block_wrap {
          // max-height: 240px !important;
        }
        &:last-child {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .quickMenuBox {
    margin-bottom: 0;
    background-position: bottom -160px left -249px;
    .quickMenuBlock {
      display: -webkit-box;
      overflow: auto;
      flex-wrap: nowrap;
      &::-webkit-scrollbar {
        display: none;
      }
      .quickMenuItem {
        width: calc(40% - 10px);
        margin: 5px 5px 5px 5px;
        &.blackQuickMenu {
          display: none;
        }
      }
    }
  }

  .dashboardWrapper {
    .dashbaord_left {
      .deal_block_wrap {
        .bannerImg {
        }
      }
    }
  }
  .homeBanner1 {
    display: block;
    margin-bottom: 0;
    &.lightBg {
      padding: 0 15px;
    }
    .homeBanner1Img {
      height: 288px;
      width: 100%;
      &.rightBlock {
        height: 417px;
        .homeBannerImageBlock {
          height: 417px;
        }
      }
      .homeBannerImageBlock {
        height: 288px;
      }
    }
    .homeBanner1Conetnt {
      width: 100%;
      padding: 35px 15px 15px 15px;
      &.leftBlock {
        bottom: auto;
      }
      h2 {
        font-size: 20px;
        margin-bottom: 5px;
      }
    }
  }
  .lightBg {
    margin-top: 15px;
    .homeBanner1Img {
      padding-right: 0;
      margin-bottom: 10px;
    }
    .homeBanner1Conetnt {
      top: 0;
    }
  }
  .homeTwoBanner {
    display: block;
    margin-bottom: 0;
    .homeTwoBannerImage {
      height: 423px !important;
    }
    .hometwoBannerItem {
      width: 100%;
      margin: 0;
      position: relative;
      height: 423px;
      .homeTwoBannerContent {
        width: 100%;
        padding: 35px 15px 15px 15px;
      }
      &.rightBlock {
        .homeTwoBannerContent {
          padding: 15px 15px 35px 15px;
        }
      }
    }
  }
  .footerStickyIcon {
    display: none;
  }
  .footerSection {
    padding: 5px 0;
    .footerWrapper {
      flex-wrap: wrap;
      ul {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
  .topBannerBlock {
    height: 250px !important;
    .topbannerImage {
      height: 250px;
    }
    .topBannerContent {
      width: 100%;
      min-width: min-content;
    }
  }
  .dashboardWrapper {
    flex-wrap: wrap;
    .dashbaord_left,
    .dashboard_right {
      width: 100%;
    }
    .dashbaord_left {
      .collection_wrap {
        // flex-wrap: wrap;
        overflow-x: auto;
        &.three-banner {
          .collection_banner {
            width: 100%;
            min-width: 300px;
            display: inline-block;
          }
        }
      }
      .my_count {
        overflow-x: auto;
        flex-wrap: nowrap;
        align-items: flex-start;
        .count_block {
          // width: calc(50% - 10px);
          min-width: max-content;
          flex-direction: column;
          text-align: center;
          // min-width: 200px;
          .icon {
            width: 30px;
            // margin-right: 10px;
            margin: auto;
          }
          .count_text {
            // width: calc(~'100% - 45px');
            width: 100% !important;
            display: inline-block !important;
          }
          h3 {
            font-size: 12px;
          }
          h1 {
            font-size: 20px;
            margin: 10px 0 5px;
          }
        }
      }
      .dashbaord_bottom_img {
        height: auto !important;
      }
      .deal_block_wrap {
        flex-wrap: wrap;
        .deal_block_container {
          width: 100%;
        }
        .bannerImg {
          width: 100%;
          margin-top: 15px;
          padding-left: 0;
        }
      }
    }
    .dashboard_right {
      padding-left: 0;
      padding-bottom: 10px;
      height: auto !important;
      .diamond_data {
        .diamond_block_wrap {
          max-height: 100%;
          height: calc(~'100vh - 400px') !important;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1440px) {
  .quickMenuBlock {
    width: 80%;
    margin: auto;
  }
}
@media screen and (max-height: 1080px) {
  .dashboardWrapper {
    .dashboard_right {
      .diamond_data {
        .diamond_block_wrap {
          height: calc(~'100vh - 695px');
        }
      }
    }
  }
}
@media screen and (max-height: 951px) {
  .dashboardWrapper {
    .dashboard_right {
      .diamond_data {
        .diamond_block_wrap {
          height: calc(~'100vh - 630px');
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboardWrapper {
    .dashboard_right {
      .diamond_data {
        .diamond_block_wrap {
          height: calc(1024px - 128px) !important;
        }
      }
    }
  }
}
@media screen and (max-height: 789px) {
  .dashboardWrapper {
    .dashboard_right {
      .diamond_data {
        .diamond_block_wrap {
          height: calc(~'100vh - 550px');
        }
      }
    }
  }
}
@media screen and (max-height: 610px) {
  .dashboardWrapper {
    .dashboard_right {
      .diamond_data {
        .diamond_block_wrap {
          height: calc(~'100vh - 460px');
        }
      }
    }
  }
}

@primary-color: #434443;