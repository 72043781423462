.Countdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .Box {
    margin: 0 5px 5px;
    border: 1px solid #434443;
    border-radius: 4px;
    background-color: #e4e4e4;
    padding: 2px 7px;

    .Number {
      color: #000000;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }

    .Label {
      text-transform: uppercase;
      color: #434443;
      font-size: 10px;
      font-weight: 500;
    }
  }

  &.EndingSoon {
    .Box {
      border-color: darken(#ff0000, 10%) !important;
      background-color: lighten(#ff0000, 40%) !important;

      .Number,
      .Label {
        color: darken(#ff0000, 5%) !important;
      }
    }
  }
}

@primary-color: #434443;