@import 'variable.less';

body {
  color: #262828;
}

.side-drawer ul li > div {
  width: 100%;
}

.card-table-header a:hover {
  opacity: 0.7;
}
table {
  thead {
    // display: flex;
    position: sticky;
    top: 0;
    z-index: 5;
  }
  td {
    div {
      overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
  th {
    &:hover {
      z-index: 3 !important;
    }
  }
  .tbody {
    &.ant-calendar-tbody {
      [title]:hover:after {
        content: none;
        background-color: rgba(102, 102, 104, 0.7803921568627451) !important;
        // background:black;
      }
    }
  }
}
table.sticky {
  overflow: scroll;
}
table.sticky .header,
table.sticky .footer {
  position: sticky;
  z-index: 1;
  // width: fit-content;
}
table.sticky .header {
  top: 0;
  // box-shadow: 0px 3px 3px #ccc;
}
table.sticky .footer {
  bottom: 0;
  box-shadow: 0px -3px 3px #ccc;
}
table.sticky .body {
  position: relative;
  z-index: 0;
}
table.sticky [data-sticky-td] {
  position: sticky;
  background-color: white;
  // width: fit-content !important;
  // left: unset !important;
}
table.sticky [data-sticky-last-left-td] {
  box-shadow: 2px 0px 3px #ccc;
}
table.sticky [data-sticky-first-right-td] {
  box-shadow: -2px 0px 3px #ccc;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    color: @themeColor;
  }
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #000;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@font-face {
  font-family: Gilroy;
  src: url('../assets/fonts/Gilroy-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('../assets/fonts/Gilroy-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('../assets/fonts/Gilroy-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('../assets/fonts/Gilroy-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('../assets/fonts/Gilroy-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('../assets/fonts/Gilroy-Bold.ttf');
  font-weight: 900;
  font-style: normal;
}

body,
html {
  cursor: default;
  width: 100%;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  font-weight: 400;
  color: #999;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.3px;
  font-family: Gilroy !important;
  overflow-x: hidden;
}

h1 {
  font-family: Gilroy;
  font-size: 46px;
  line-height: 55px;
  font-weight: 700;
}

h2 {
  font-family: Gilroy;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.3px;
  font-weight: 700;
}

h3 {
  font-family: Gilroy;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  font-weight: 700;
}

h4 {
  font-family: Gilroy;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
}

h5 {
  font-family: Gilroy;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  font-weight: 700;
}

h6 {
  font-family: Gilroy;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 700;
}

.sub-header {
  font-family: Gilroy;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.2px;
  font-weight: 600;
}

label {
  font-family: Gilroy;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.3px;
  font-weight: 600;
}

p {
  font-family: Gilroy;
  font-size: 14px;
  color: #999;
  line-height: 23px;
  letter-spacing: 0.3px;
  font-weight: 400;
}

small {
  font-family: Gilroy;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 400;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.background-white {
  background: #fff;
}

.width-full {
  width: 100% !important;
}
.width-50 {
  width: 50% !important;
}

.d-block {
  display: block;
  cursor: pointer;
}

.d-flex {
  display: flex !important;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-flow-wrap {
  flex-flow: row wrap;
}

.align-items-center {
  align-items: center;
}

.j-space-between {
  justify-content: space-between;
}
.j-content-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
  cursor: pointer;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.o-hidden {
  overflow: hidden;
}

.primary-text {
  color: @themeColor;
}
.text-style {
  font-size: 13px;
  white-space: nowrap;
}

.margin-auto {
  margin: auto;
}

.page-heading {
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.white-text {
  color: #fff;
}

.mt-30 {
  margin-top: 30px !important;
}

.no-padding {
  padding: 0 !important;
}

.h-full {
  height: 100%;
}

.mb-25 {
  margin-bottom: 25px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield !important;
}
input {
  &:focus {
    outline: none;
  }
}

.mb-15 {
  margin-bottom: 15px;
}
.mb-17 {
  margin-bottom: 17px;
}

a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

th,
td {
  text-align: left;
  padding: 8px 10px;
  color: #4c4d4f;
}

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: '←';
}

[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
  right: -25px;
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: '→';
}

[dir='rtl'] .slick-next:before {
  content: '←';
}

// .slick-slider {
//   margin-bottom: 30px;
// }
.slick-dots {
  position: absolute;
  bottom: -45px;
  display: block !important;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 6px;
  height: 6px;
  margin: auto;
  padding: 1px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: white;
  text-indent: -999999px;
  border-radius: 50%;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

ul.slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
th,
td {
  text-align: center;
  padding: 8px 10px;
  color: #4c4d4f;
}

.mr-10 {
  margin-right: 10px;
}

// loader----------

.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid @themeColor;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// loader end--------

.avtar-default {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  // background: rgba(153, 153, 153, 0.1);
}

.avtar {
  border-radius: 50%;
  overflow: hidden;
}

.avtar-xl {
  width: 160px;
  height: 160px;
}

.avtar-fs {
  width: 80px;
  height: 80px;
}

.avtar-sm {
  width: 42px;
  height: 42px;
}

.avtar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mb-30 {
  margin-bottom: 30px;
}
th,
td {
  text-align: left;
  padding: 8px 10px;
  color: #4c4d4f;
}

.mb-0 {
  margin-bottom: 0 !important;
}
// .ant-select {
//   border: 1px solid rgba(153, 153, 153, 0.5);
//   border-radius: 3px;
//   -webkit-transition: all 0.2s ease-in-out;
//   -o-transition: all 0.2s ease-in-out;
//   transition: all 0.2s ease-in-out;
// }

// .ant-select:hover,
// .ant-select:hover:focus {
//   outline: none;
//   border: 1px solid @themeColor;
//   box-shadow: 0 4px 5px rgba(40, 56, 149, 0.1);
// }
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: @themeLightColor;
}
.mt-5 {
  margin-top: 5px;
}
.pd0 {
  padding: 0px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.align-arrow {
  display: flex;
  justify-content: center;
}
.mb-90 {
  margin-bottom: 90px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.hidden {
  display: none;
}

.ant-modal-footer button {
  font-size: 13px;
  padding: 4px 22px !important;
}

.ant-calendar-selected-date .ant-calendar-date {
  color: #57968f !important;
  background: #bae7ff !important;
  border-color: #57968f !important;
}

.width-auto {
  width: auto;
}

.ant-radio-inner::after {
  background-color: @themeColor !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: @themeColor !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #000 !important;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(192, 158, 124, 0.08) !important;
  border-color: @themeColor !important;
}
.justify-center {
  justify-content: center;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.align-end {
  align-items: flex-end;
}

.width-49 {
  width: 49%;
}

.height-100 {
  height: 100%;
}

.no-data-found {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: grayscale(1);
}

.mr-20 {
  margin-right: 20px !important;
}

.width15 {
  width: 15% !important;
}

.width25 {
  width: 25% !important;
}

.width20 {
  width: 20% !important;
}

.justify-end-flex {
  justify-content: flex-end !important;
}

.width-auto {
  width: auto !important;
}

#loader-inner {
  height: 100%;
  display: flex;
}

#loader-inner .logo img {
  width: 150px;
}

#loader.APILoader {
  display: none;
}
#loader.APILoader.loading {
  display: block;
}
.plr-15 {
  padding: 0 15px;
}
// new css
.commonButton {
  background-color: @themeColor;
  margin: 3px;
  border: 1px solid @themeColor;
  padding: 8px 14px;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: @themeColor;
    background-color: #fff;
  }
  &:focus {
    outline: none;
  }
  &.smallButton {
    padding: 3px 8px;
    font-size: 13px;
  }
}
.commonOutline {
  background-color: #fff;
  margin: 3px;
  border: 1px solid @themeColor;
  padding: 6px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: @themeColor;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #fff;
    background-color: @themeColor;
    border: 1px solid @themeColor;
  }
  &:focus {
    outline: none;
  }
  &.smallButton {
    padding: 4px 10px;
  }
}

.p-0 {
  padding: 0 !important;
}
.justify-space-between {
  justify-content: space-between;
}
.countryCodeDropdown {
  width: 100%;
  .intl-tel-input {
    // .country-list{
    //   width: 100%;
    // }
    .selected-flag {
      &:focus {
        outline: none;
      }
    }
    input {
      border: none;
      border-bottom: 1px solid #e4e4e4;
      width: 100%;
      height: auto;
      padding: 7px 0;
    }
  }
}

.actionButton {
  display: flex;
  justify-content: center;
  .actionAction {
    width: 25px;
    height: 25px;
    margin: 0 2px;
    display: flex;
    border-radius: 100%;
    background-color: @themeLightColor;
    img {
      width: 14px;
      height: 14px;
      margin: auto;
    }
  }
}
.activeStatus span {
  padding: 5px 13px;
  background: #ecfdf3;
  border-radius: 8px;
  color: #429945;
  font-size: 12px;
  text-transform: uppercase;
}
.officeStatus.pending span {
  padding: 5px 13px;
  background: #e8e8e8;
  border-radius: 8px;
  color: #313131;
  font-size: 12px;
  text-transform: uppercase;
}
.officeStatus.approved span {
  padding: 5px 13px;
  background: #ecfdf3;
  border-radius: 8px;
  color: #429945;
  font-size: 12px;
  text-transform: uppercase;
}

.officeStatus.rejected span {
  padding: 5px 13px;
  background: #fdecf1;
  border-radius: 8px;
  color: #ff0000;
  font-size: 12px;
  text-transform: uppercase;
}

.activeStatus.red span {
  background: #fdecf1;
  color: #ff0000;
}
.stausButton {
  padding: 5px 13px;
  background: #ecfdf3;
  border-radius: 8px;
  color: #429945;
  font-size: 12px;
  text-transform: uppercase;
  &.active {
    color: #429945;
    background: #ecfdf3;
  }
  &.sold {
    background-color: #d8e9ff;
    color: #096bef;
  }
  &.pending {
    background-color: #ffe5fa;
    color: #ea81dd;
  }
  &.return {
    background-color: #feface;
    color: #d8c700;
  }
}
.commonLabel {
  color: #000;
  font-size: 14px;
  display: block;
  width: 100%;
  font-weight: 600;
}
.dotList {
  p {
    margin-bottom: 10px !important;
    position: relative;
    padding-left: 15px;
    &:after {
      content: '';
      width: 4px;
      height: 4px;
      left: 0;
      background-color: #555;
      top: 10px;
      position: absolute;
      border-radius: 100%;
    }
  }
}
.bottom-0 {
  bottom: 0 !important;
}

.mr-10 {
  margin-right: 10px;
}
.underline {
  text-decoration: underline;
}

// Reminder

.reminder-block {
  width: calc(50% - 20px);
  text-align: center;
  margin-bottom: 40px;
  background: #fafafa;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.reminder-block.active {
  border: 1px solid @themeColor;
  background-color: @themeLightColor;
}
.reminderImageBlock {
  margin-right: 10px;
}
.reminderDetail {
  text-align: left;
}

.reminder-block img {
  width: 30px;
  margin-bottom: 10px;
}

.reminder-datepicker span.modal-title {
  width: 100%;
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 600;
}

.reminder-datepicker {
  width: 100%;
}

.reminder-block h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.reminder-block span {
  color: #454545;
}

.reminder-wrapper {
  width: auto;
  display: flex;
  border-radius: 5px;
  min-width: 160px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .reminder-block {
    width: 100%;
  }
}
.position-relative {
  position: relative !important;
}
.p-lr-15 {
  padding: 0 15px;
}
i.ant-spin-dot-item {
  background-color: @themeColor !important;
}
.mt-50 {
  margin-top: 50px;
}
.justify-content-between {
  justify-content: space-between;
}
.mt-10 {
  margin-top: 10px;
}
.mr-40 {
  margin-right: 40px !important;
}
.ant-calendar-selected-date .ant-calendar-date {
  background: #fafafa !important;
  border: 1px solid #000 !important;
  color: #000 !important;
}
.deleteMessage {
  font-size: 16px;
}
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover {
  background-color: @themeColor;
}
.ant-calendar-range .ant-calendar-in-range-cell::before,
.ant-calendar-date:hover {
  background: @themeLightColor;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @themeColor;
  border-color: @themeColor;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: @themeColor;
}
.p-10 {
  padding: 10px;
}
.height-auto {
  height: auto !important;
}
a[disabled] {
  color: #fff;
  opacity: 0.9;
  cursor: not-allowed;
  pointer-events: none;
}
.themeColor {
  color: @themeColor;
  font-weight: 600;
}
@media (max-width: 767px) {
  .m-width-100 {
    width: 100% !important;
  }
}
.listTag {
  padding: 0px 10px 5px;
}
.listTag .listTagList {
  display: flex;
  align-items: center;
  margin: 2px;
}
.listTag .listTagList span.listLabel {
  color: #000;
  font-weight: bold;
  margin-right: 5px;
  display: block;
}
.pointer-events-none {
  pointer-events: none !important;
}
//  captcha start
.Captcha {
  box-sizing: border-box;
  // border: 1px solid brown;
  // padding: 5px 20px;
  // box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #fff;
  // border-radius: 324px;
}
.randomChars {
  padding: 5px;
}
.randomChars div {
  padding: 3px 5px;
  font-size: 18px;
  color: #223ccf;
  display: inline-block;
  transform: rotate(20deg);
  // No Select
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* rotate chars */
.randomChars div:nth-child(0) {
  transform: rotate(0deg);
}

.randomChars div:nth-child(1) {
  transform: rotate(15deg);
}

.randomChars div:nth-child(2) {
  transform: rotate(30deg);
}

.randomChars div:nth-child(3) {
  transform: rotate(45deg);
}

//  captcha end
.disabled {
  cursor: not-allowed !important;
}

//QR code
.qrCodeOption {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 18px;
  width: 90px;
  padding: 12px 0 10px 0;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.19);
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
  img,
  canvas {
    width: 50px;
    height: 50px;
    // margin: auto auto 30px auto;
    margin: auto;
    object-fit: contain;
  }
  span {
    color: #000;
    text-transform: uppercase;
    font-size: 11px;
    text-align: center;
    display: block;
    margin-top: 5px;
    width: 100%;
    line-height: 16px;
    font-weight: 600;
    // position: absolute;
    // bottom: 5px;
  }
}
.purple {
  color: #9c539a;
}
.visStones div {
  color: #9c539a;
}
@media (max-width: 767px) {
  .qrCodeOption {
    display: none;
  }
}

.columnSettingPage thead {
  display: contents;
  width: 100%;
}

.vstnpopup {
  color: #008cba;
}

.headerInfoIcon {
  width: 25px;
  height: 12px;
  cursor: pointer;
  margin-right: 0.25rem;

  width: auto;
  max-height: 100%;
}
.cookies-table {
  margin: 10px 0;
  td {
    border: 1px solid black;
  }
}

@primary-color: #434443;