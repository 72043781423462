.search-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  max-height: 12rem;
  min-height: 3rem;
  padding: 0.625rem 1rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

  .search-heading {
    &-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: space-around;
      justify-content: space-between;
      align-items: center;
    }

    max-width: 600px;
    margin: 0 1rem 0 0;
    font-size: 1.125rem;
    font-weight: 600;
  }

  &-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 560px);
  }

  &-select {
    width: calc(65% - 6px);
  }

  &-input {
    width: calc(35% - 6px);
  }
}

@media screen and (max-width: 1300px) {
  .search-header {
    .search-heading {
      &-wrapper {
        margin-bottom: 0.5rem;
      }
    }

    &-form {
      width: 100%;
    }

    &-select {
      width: calc(65% - 6px);
    }

    &-input {
      width: calc(35% - 6px);
    }
  }
}

@media screen and (max-width: 767px) {
  .search-header {
    .search-heading {
      margin-right: auto;
      &-wrapper {
        display: flex;
        width: 100%;
      }
    }

    &-form {
      width: 100%;
    }

    &-select {
      width: calc(65% - 6px);
    }

    &-input {
      width: calc(35% - 6px);
    }
  }
}

@media screen and (max-width: 535px) {
  .search-header {
    &-form,
    &-select,
    &-input,
    .search-heading,
    .search-heading-limit {
      min-width: 100%;
    }

    &-select {
      margin-bottom: 0.5rem;
    }
  }
}

@primary-color: #434443;