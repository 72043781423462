@import 'variable.less';

.diamondDetailPopup {
  z-index: 1000000;
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1 !important;
  }
  &.ant-drawer {
    .ant-drawer-content {
      overflow: visible;
    }
  }
  .ant-drawer-header-no-title {
    .ant-drawer-close {
      background: #fff;
      right: auto;
      left: -46px;
      border-radius: 20px;
      top: 8px;
      width: 40px;
      height: 40px;
      line-height: 42px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-drawer-content-wrapper {
    width: 84% !important;
    padding: 0;
    height: 100%;
    .ant-drawer-body {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
  .diamondDetailWrapper {
    .diamondDetailTopTab {
      position: absolute;
      left: 0;
      top: -45px;
      border-bottom: none;
      z-index: 10000;
    }
  }
  .diamondDetailLeft {
    width: 50%;
    position: relative;
    z-index: 10001;
    .diamondDetailInnerDetail {
      position: relative;
      top: 0;
      .tab-content {
        height: calc(100vh - 80px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  .diamondDetailRight {
    max-height: calc(100vh - 73px);
    overflow: auto;
    background-color: transparent;
  }

  .diamondDetailBottomBox {
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
    z-index: 1000;
    top: 0;
    background: #fff;
    width: 100%;
    .diamondDetailBottomAction {
      width: 100%;
    }
  }
}
.diamondDetailWrapper {
  width: 100%;
  min-height: 100vh;
  background: #f1f1fb;
  padding: 20px 20px 30px;
  .diamondDetailTopTab {
    display: flex;
    justify-content: center;
    list-style: none;
  }
  .diamondDetailInner {
    .diamondDetailMainTab {
      display: flex;
    }
    .basicDetailFull {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      margin-bottom: 0;
      li {
        color: #212431;
        font-size: 20px;
        font-weight: 600;
        list-style: none;
        margin: 0 5px;
      }
    }
  }
}
.diamondDetailLeft {
  width: 40%;
  position: relative;
  // z-index: 10001;
  // height: calc("100vh - 131px");
  // overflow: auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  .diamondDetailInnerDetail {
    // height: calc(100vh - 73px);
    // overflow-y: auto;
    // overflow-x: hidden;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;

    .customerMaintab li a {
      padding: 10px;
      color: #434443;
      &.active {
        border-bottom: 3px solid #434443;
        font-weight: 800;
      }
    }

    .tab-content,
    .tab-pane {
      height: 100%;
    }
    .diamondDetailImageBox {
      height: 100%;
      // padding: 20px 10px 0;
      text-align: center;
      &.htmlViewImage {
        // height: 600px;
        // height: 64vh;
        position: relative;
      }
      .magnifier {
        // width: 450px !important;
        // height: 450px !important;
        //margin: auto;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit: contain;
        border: none;
      }
      iframe {
        width: 100%;
        height: 100%;
        margin: auto;
        // object-fit: cover;
        border: none;
        &.certificateImage {
          height: auto;
        }
      }
      .imageBlock {
        max-width: 100%;
        height: 100% !important;
        img {
          width: 90%;
          height: 100%;
        }
      }
    }
    .smallSliderBlock {
      margin-top: 15px;
      padding: 0 55px;
      .slick-track {
        margin: auto;
      }
      .smallSliderImage {
        width: 60px !important;
        height: 60px;
        display: flex !important;
        margin: auto;
        align-items: center;
        justify-content: center;
        border: 1px solid #ddd;
        border-radius: 5px;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
        }
      }
      &.smallSliderBlockimage {
        .smallSliderImage {
          // width: 80px !important;
          // height: 80px;
          img {
            width: 35px;
            height: 35px;
          }
        }
      }
      .slick-track {
        display: flex;
        justify-content: center;
        .slick-slide {
          width: 80px !important;
          height: 80px;
          margin: 0 0;
          &.slick-current {
            .smallSliderImage {
              border: 1px solid #000;
            }
          }
        }
      }
      .slick-prev,
      .slick-next {
        width: 30px;
        height: 30px;
        background: rgba(0, 0, 0, 0.5);
        &:before {
          color: #fff;
        }
        &.slick-prev {
          left: 10px;
        }
        &.slick-next {
          right: 10px;
        }
      }
      .smallimgTitle {
        color: #454545;
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 5px;
        display: block;
        text-align: center;
      }
    }
  }
  .tab-pane {
    display: none;
    &.active {
      display: block;
    }
  }
}
.diamondDetailRight {
  width: calc(60% - 30px);
  margin-left: 30px;
  // max-height: calc(100vh - 73px);
  // overflow: auto;
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  overflow: hidden;
  .height-1vh {
    // height: calc(100vh - 10px);
  }
  .diamondDetailMore {
    padding: 0 10px 10px;
    .detailSmallTitle {
      font-size: 14px;
      color: #424242;
      margin-bottom: 0;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 5px;
      line-height: 20px;
    }
    .diamondDetailMoreHalf {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      ul {
        width: 25%;
        padding: 0;
        margin: 0;
        &.threeCol {
          width: 33.33%;
        }
        &.twoCol {
          width: 67%;
        }
        li {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          margin-bottom: 5px;
          justify-content: space-between;
          border: 1px solid #ccc;

          span {
            color: #000;
            font-size: 14px;
            font-weight: normal;
            padding: 3px 5px;
            &:first-child {
              width: 90px;
              // margin-right: 5px;
              font-size: 11px;
              letter-spacing: 0.5px;
              // text-transform: uppercase;
              font-weight: 600;
              background: @themeGrayColor;
            }
            &:last-child {
              font-size: 11px;
              color: #000;
              font-weight: 500;
              word-break: break-word;
              width: calc(100% - 90px);
            }
          }
        }
      }

      &.diamondFullBlock {
        ul {
          width: 100%;
          li {
            span {
              &:first-child {
                width: 190px;
              }
              &:last-child {
                width: calc(~'100% - 195px');
              }
              &.addNotesOption {
                display: flex;
                align-items: center;
                textarea {
                  width: calc(~'100% - 110px');
                  border: 1px solid #e2e2e2;
                  background-color: #fff;
                  height: 80px;
                  border-radius: 5px;
                  margin-right: 10px;
                  padding: 10px;
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.mobileSearchBottom {
  .diamondDetailBottomAction {
    justify-content: space-evenly !important;
  }
  .dia_opt {
    .diamondDetailActionItem {
      margin: -4px !important;
    }
  }
}
.detailHalfWhite {
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
}
.detailHalfGray {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f6f6f6;
}
.diamondDetailSummary {
  width: 100%;
  .botoomStickyBar {
    display: none !important;
  }
}
.diamondDetailBottomBox {
  position: sticky;
  top: 0;
  //padding: 10px;
  // background: #fafafa;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .diamondDetailBottomAction {
    width: auto;
    height: 53px;
    // border-bottom: 1px solid #e2e2e2;
    border-top: none !important;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    .inner-dna {
      &.w-20 {
        width: 20%;
        h2 {
          line-height: 25px;
        }
        span {
          color: #969696;
        }
      }
    }
    h2 {
      margin-bottom: 0;
      font-weight: 500;
    }
    .dia_opt {
      display: flex;
      align-items: flex-start;
      .diamond-detail-error {
        margin-top: 12px;
        font-size: 15px;
        color: red;
      }
    }
    .diamondDetailTab {
      border-bottom: none !important;
    }
    .diamondDetailActionItem {
      display: flex;
      align-items: center;
      margin: 5px;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid @themeColor;
      background-color: @themeColor;
      img {
        width: 20px;
        height: 20px;
        // margin-right: 5px;
      }
      span {
        color: #212431;
        font-weight: 600;
      }
    }
  }
}
.tooltipShow {
  z-index: 100001;
}
//   popup Detail
.diamondDetailRightPopup {
  padding: 15px;
}
.mobile-detail-contain {
  position: sticky;
  bottom: 0px;
  z-index: 999;
  width: 100%;
  background: #fff;
}
.diamondDetailPage {
  .sideBarPopupButton {
    position: fixed;
    bottom: 0;
    width: 50%;
    right: 0;
    left: auto;
    box-shadow: none;
    background-color: @whiteColor;
  }
}
@media screen and (min-width: 1301px) and (max-width: 1420px) {
  .diamondDetailBottomBox {
    .diamondDetailBottomAction {
      .dia_opt {
        display: flex;
        align-items: flex-start;
        .diamondDetailActionItem {
          padding: 7px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .diamondDetailRight {
    .diamondDetailMore {
      .diamondDetailMoreHalf {
        ul {
          width: 33.33%;
        }
      }
    }
  }
  .diamondDetailLeft {
    margin-bottom: 30px;
    .diamondDetailInnerDetail {
      height: 100% !important;
      .tab-content {
        height: 100% !important;
        // height: 500px !important;
      }
      .diamondDetailImageBox.htmlViewImage {
        // height: 400px;
      }
      // .diamondDetailImageBox {
      //   .magnifier{
      //     width: 100% !important;
      //     height: 300px !important;
      //   }
      // }
    }
  }
}
@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .diamondDetailBottomBox {
    .diamondDetailBottomAction {
      .dia_opt {
        .diamondDetailActionItem {
          padding: 5px;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
  .diamondDetailRight {
    .diamondDetailMore {
      .diamondDetailMoreHalf {
        ul {
          width: 33.33%;
        }
      }
    }
  }
  .diamondDetailLeft {
    margin-bottom: 30px;
    .diamondDetailInnerDetail {
      .tab-content {
        height: 500px !important;
      }
      .diamondDetailImageBox.htmlViewImage {
        // height: 400px;
      }
      .diamondDetailImageBox {
        .magnifier {
          width: 100% !important;
          // height: 300px !important;
        }
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .diamondDetailWrapper {
    .diamondDetailInner {
      .diamondDetailMainTab {
        flex-wrap: wrap;
        .diamondDetailLeft {
          width: 100%;
        }
        .diamondDetailRight {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
  .diamondDetailLeft {
    margin-bottom: 30px;
    .diamondDetailInnerDetail {
      .tab-content {
        height: 100% !important;
        // height: 500px !important;
      }
      .diamondDetailImageBox.htmlViewImage {
        // height: 700px;
        // height: 910px;
        // height: 500px;
      }
    }
  }

  .diamondDetailRight {
    .diamondDetailMore {
      .diamondDetailMoreHalf {
        ul {
          width: 33.33%;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .diamondDetailWrapper {
    .diamondDetailInner {
      .diamondDetailMainTab {
        flex-wrap: wrap;
        .diamondDetailLeft {
          width: 100%;
        }
        .diamondDetailRight {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
  .diamondDetailLeft {
    margin-bottom: 30px;
    .diamondDetailInnerDetail {
      .tab-content {
        &.hv-600px {
          height: 600px !important;
        }
      }
      .diamondDetailImageBox.htmlViewImage {
        height: 480px;
        // height: 565px;
      }
    }
  }
  .diamondDetailBottomBox {
    .diamondDetailBottomAction {
      .dia_opt {
        .diamondDetailActionItem {
          padding: 5px;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
  .diamondDetailRight {
    .diamondDetailMore {
      .diamondDetailMoreHalf {
        ul {
          width: 33.33%;
        }
      }
    }
  }
}
@media screen and (min-width: 500px) and (max-width: 540px) {
  .mobilediamond {
    height: 480px;
    .diamondDetailInnerDetail {
      height: 342px;
      // height: 426px;
    }
  }
  .diamondDetailLeft {
    .diamondDetailInnerDetail {
      .diamondDetailImageBox {
        &.htmlViewImage {
          // height: 366px;
        }
      }
    }
  }
}

.cert-image {
  height: 290px !important;
  // height: auto !important;
  img {
    object-fit: contain !important;
  }
  .magnifier-image {
    height: auto !important;
  }
}
.copy-icon {
  font-size: 16px;
  position: absolute;
  right: 0px;
  top: 12px;
  margin-right: -2px;
  color: #00000096 !important;
}

@primary-color: #434443;