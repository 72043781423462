.image-fluid {
  max-width: 100%;
  height: auto;
}

.image-view {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
  width: auto;
  background-color: rgba(65, 65, 65, 0.8);
  transition: transform 0.2s, opacity 0.2s;
  z-index: 9999;

  .pinch-zoom-container {
    display: flex;
    max-height: 100vh !important;
    max-width: 100vw !important;
    min-height: 100vh !important;
    min-width: 100vw !important;
    height: 100vh !important;
    width: 100vw !important;
    margin: auto;
  }

  &-visible {
    transform: scale(1);
    opacity: 1;
  }

  &-hidden {
    transform: scale(0);
    opacity: 0;
  }

  &-image {
    margin: auto;
    background-color: transparent;
  }

  &-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 3rem;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    z-index: 1;
    text-shadow: 0px 0px 3px #000000;
  }
}

@primary-color: #434443;