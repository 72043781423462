@import 'variable.less';

.searchResultListWrapper {
  // position: relative;
  // margin-top: 10px;
  &.profilePage {
    margin: 0;
  }
}
.searchResultListWrapper {
  &.offerTable {
    height: calc(100vh - 102px);
    position: relative;
  }
}
.userDetailEdit {
  margin-left: 5px;
  margin-right: 10px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
}
.filterMainDropdown {
  .ant-dropdown-menu {
    min-width: 320px;
    display: flex;
    flex-wrap: wrap;
    max-width: 320px;
    li {
      background-color: @tableBackGroundColor;
      padding: 2px 8px !important;
      border-radius: 20px;
      margin: 3px;
      display: flex;
      align-items: center;
      span {
        color: @themeColor;
        font-size: 12px;
      }
      .filterClose {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background-color: @themeColor;
        display: flex;
        margin-left: 5px;
        img {
          width: 6px;
          height: 6px;
          margin: auto;
          display: block;
        }
      }
    }
  }
}
.searchResultTable {
  //padding: 0 10px;
  height: calc(100vh - 254px);
  overflow: auto;
  margin: 0px 0px 0px 10px;
  // &.profilePageTable{
  //     height: calc(100vh - 384px);
  // }
  &.shareOptionScroll {
    height: calc(100vh - 324px);
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &.profilePagewithoutFilter {
    height: calc(100vh - 333px);
  }
  &.diamondListinSetColunm {
    padding-left: 30px;
    tr {
      td:first-child,
      th:first-child {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
      }
    }
  }
  table {
    width: 100%;
    position: relative;
    thead {
      tr {
        th {
          text-transform: capitalize;
          color: #000;
          font-size: 14px;
          font-weight: 600;
          padding: 8px 3px 5px 3px;
          text-align: center;
          position: sticky;
          top: 0;
          z-index: 100;
          cursor: pointer;
          // background: #f1f1f1;
          background: @themeGrayColor;
          vertical-align: top;
          .selectAmount {
            color: #000 !important;
            font-weight: bold;
            font-size: 10px;
            height: 12px;
            display: block;
          }
          &:first-child {
            position: sticky !important;
            left: 0px !important;
            z-index: 1000 !important;
            background-color: #f1f1f1 !important;
          }
          // &:nth-child(2){
          //   position: sticky;
          //   left: 47px;
          //   z-index: 1000;
          //   background-color: #f1f1f1;
          // }
          // &:nth-child(3){
          //   position: sticky;
          //   left: 110px;
          //   z-index: 1000;
          //   background-color: #f1f1f1;
          // }
        }
      }
    }
    tbody {
      .tableSortingTd {
        position: absolute;
        left: -20px;
        margin: 10px 0;
        cursor: move;
        img {
          width: 16px;
          height: 16px;
        }
      }
      tr {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;
        border: 1px solid #e2e2e2;
        position: relative;
        vertical-align: top;
        td {
          cursor: pointer;
          text-align: center;
          padding: 5px 7px;
          white-space: nowrap;
          border-right: 1px solid #f2f2f2;
          .editCell {
            input {
              width: 100%;
              padding: 1px 5px;
              border: 1px solid #e2e2e2;
              border-radius: 5px;
              font-size: 12px;
              // display: none;
            }
          }
          &:nth-child(1) {
            position: sticky;
            left: 0px;
            z-index: 1;
            //background-color: red;
            background-color: #fff;
          }
          // &:nth-child(2){
          //   position: sticky;
          //   left: 47px;
          //   z-index: 1;
          //   //background-color: red;
          //   background-color: #fff;
          // }
          // &:nth-child(3){
          //   position: sticky;
          //   left: 110px;
          //   z-index: 1;
          //   //background-color: red;
          //   background-color: #fff;
          // }
          .newdiamond {
            color: red;
            font-weight: 400;
            position: absolute;
            top: -6px;
            right: -1px;
            z-index: 5;
            font-size: 10px;
          }
        }
        // &.IGI {
        //   td {
        //     background: rgba(41, 41, 112, 0.2);;
        //     box-shadow: none;
        //   }
        // }
        // &.HRD {
        //   td {
        //     background: rgba(41, 41, 112, 0.28);
        //     box-shadow: none;
        //   }
        // }
        // &.FM {
        //   td {
        //     background: rgba(41, 41, 112, 0.16);
        //     box-shadow: none;
        //   }
        // }
        // &.OTHER {
        //   td {
        //     background: rgba(41, 41, 112, 0.07);
        //     box-shadow: none;
        //   }
        // }
        &.selectTr {
          td {
            background: @trselected !important;
            border-right: 1px solid @trselected !important;
          }
        }
        // &:nth-child(1){
        //     .SelectcolorWiseList{
        //         &.red{
        //             display: block;
        //         }
        //     }
        // }
        // &:nth-child(2){
        //     .SelectcolorWiseList{
        //         &.green{
        //             display: block;
        //         }
        //         &.red{
        //             display: none !important;
        //         }
        //     }
        // }
        // &:nth-child(3){
        //     .SelectcolorWiseList{
        //         &.blue{
        //             display: block;
        //         }
        //         &.red{
        //             display: none !important;
        //         }
        //     }
        // }
        // &:hover{
        //     td{
        //         background: @trhover;
        //     }
        // }
        // &.checked{
        //     &:hover{
        //         td{
        //             background:@trselected;
        //         }
        //     }
        //     td{
        //         background:@trselected;
        //     }
        //     box-shadow: none;
        // }
      }
      &.tableNewRowAdd {
        tr {
          border: 1px solid @themeColor;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
          td {
            position: relative;
            z-index: 1;
            background: #fff;
            input {
              border: none;
              text-align: center;
            }
            .SelectcolorWiseList {
              display: block;
            }
          }
        }
      }
    }
  }
  .tableHeaderMain {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
    border-radius: 4px;
    padding: 3px 5px;
    white-space: nowrap;
    width: auto;
    // background: #fff;
    font-size: 13px;
    color: #000;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
    .listIcon {
      position: absolute;
      left: -12px;
      z-index: 2;
      margin-right: 1px;
      opacity: 0;
      visibility: hidden;
      width: 12px;
      height: 12px;
      cursor: move;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .shortingRound {
      position: absolute;
      right: -14px;
      z-index: 2;
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: #ababab;
      display: flex;
      opacity: 0;
      align-items: center;
      margin-left: 5px;
      justify-content: center;
      visibility: hidden;
      .shortingRoundInner {
        height: 10px;
        display: block;
        img {
          width: 8px;
          height: 5px;
          display: block;
          margin: auto;
        }
      }
    }
    .selectShortingMainBlock {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      z-index: 2;
      // top: -30px;
      .selectShorting {
        background-color: @themeColor;
        padding: 2px 5px;
        border-radius: 13px;
        display: flex;
        line-height: 12px;
        margin-left: 2px;
        // margin-left: -14px;
        .selectShortingCount {
          font-size: 10px;
          margin-right: 1px;
          color: #fff;
        }
        .selectShortingArrow {
          width: 6px;
          height: 6px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .shortingClose {
        width: 16px;
        height: 16px;
        background-color: #bcc0c7;
        border-radius: 100%;
        display: flex;
        opacity: 0;
        visibility: hidden;
        margin-left: 3px;
        position: absolute;
        right: -20px;
        img {
          margin: auto;
          width: 6px;
          height: 6px;
        }
      }
      .taleHeadTitle {
        position: relative;
        z-index: 0;
      }
    }
  }
  th {
    &:hover {
      z-index: 1000;
      .tableHeaderMain {
        &.shortingBoxShow {
          &:after {
            width: calc(100% + 17px + 24px);
            right: -19px;
          }
        }
        &.squeceChange {
          &::after {
            width: calc(100% + 6px + 6px);
            left: -6px;
            right: -6px;
          }
        }
        &:after {
          content: '';
          width: calc(100% + 17px + 6px);
          left: -17px;
          position: absolute;
          height: 100%;
          border-radius: 4px;
          right: -6px;
          background: #ddd;
        }
        .taleHeadTitle {
          z-index: 2;
        }
        .listIcon {
          opacity: 1;
          visibility: visible;
        }
        .shortingRound {
          opacity: 1;
          visibility: visible;
        }
        .selectShortingMainBlock {
          .shortingClose {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

.tabelDropdownClass {
  .menuInnerFilterOption {
    width: 200px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 6px;
    position: relative;
    .filtersortingClose {
      position: absolute;
      cursor: pointer;
      right: -5px;
      top: -5px;
      width: 16px;
      height: 16px;
      display: flex;
      background-color: #fff;
      border-radius: 100%;
      img {
        margin: auto;
        width: 6px;
        height: 6px;
      }
    }
    .menuInnerFilterInner {
      padding: 10px;
      max-height: calc(100vh - 350px);
      overflow: auto;
      &::-webkit-scrollbar {
        // display: none;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(186, 186, 192, 0.5) !important;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      .llSortingTitle {
        font-size: 12px;
        text-transform: uppercase;
        color: #858585;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      .llSortingTitleList {
        .llSortingTitleItem {
          display: flex;
          position: relative;
          margin: 10px 0;
          align-items: center;
          cursor: pointer;
          .selectSortingTitle {
            background: @themeLightColor;
            border-radius: 5px;
            padding: 3px 5px;
            color: @themeColor;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 14px;
          }
          .sqvanceImage {
            width: 14px;
            height: 14px;
            display: block;
            margin-right: 5px;
            cursor: move;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          .shortingRoundInner {
            width: 10px;
            height: 14px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
            margin: auto;
            img {
              width: 10px;
              height: 8px;
              display: block;
            }
          }
        }
      }
      .llfilterMenuDropdown {
        // border-top: 1px solid #e9ebf0;
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          .llSortingTitle {
            width: 100%;
            margin-bottom: 5px;
          }
          li {
            list-style: none;
            padding: 4px 10px;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
            border: 1px solid #e9ebf0;
            border-radius: 4px;
            margin: 2px;
          }
        }
        .llfilterSelect {
          margin: 0 0 10px 0;
          padding: 5px 0;
          border-bottom: 1px solid #e9ebf0;
          li {
            background-color: @themeColor;
            color: #fff;
            border: 1px solid @themeColor;
            position: relative;
            .closeFilter {
              width: 12px;
              height: 12px;
              right: -4px;
              top: -6px;
              border-radius: 100%;
              background-color: @themeLightColor;
              position: absolute;
              display: flex;
              cursor: pointer;
              img {
                width: 6px;
                margin: auto;
                height: 6px;
              }
            }
          }
        }
      }
    }
  }
}
.amountCalalateOverClass {
  width: 200px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 6px;
  padding: 10px;
  .calculatorTitle {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .calculatorSelect {
    width: 100%;
  }
  button {
    border: none;
    cursor: pointer;
    background: @themeColor;
    width: 100%;
    color: #fff;
    border-radius: 6px;
    padding: 7px 10px;
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  .calTotalAmount {
    border-top: 1px solid #e9ebf0;
    color: #82868d;
    font-size: 14px;
    margin: 10px -10px 0 -10px;
    padding: 8px 5px 0 5px;
    text-align: center;
  }
}
.amountCalalateOverClass.active {
  display: block !important;
}
.SelectcolorWiseList {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 2px;
  background: #6bc950;
  // display: none;
  &.red {
    background-color: #db1c1c;
    display: block;
  }
  &.blue {
    background-color: #307bea;
  }
  &.all {
    background-color: @themeColor;
  }
  &.tenOrange {
    background: #a87d52;
  }
  &.lighBlue {
    background: #508dc9;
  }
  &.pink {
    background-color: #c950c1;
  }
}
.showSatusBlock {
  display: flex;
  align-items: center;
  span {
    min-width: 10px;
    text-align: left;
  }
}
.selectActionIcon {
  display: flex;
  align-items: center;
  .selectActionIconWrapper {
    width: calc(~'100% - 11px');
    display: flex;
    justify-content: center;
    margin-left: 5px;
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: @themeColor;
        border-color: @themeColor;
      }
    }
    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      &:after {
        width: 5px;
        height: 8px;
      }
    }
  }
}
.tableGropupingBox {
  position: sticky;
  left: 0;

  .tableGroupTitle {
    display: inline-flex;
    white-space: nowrap;
    z-index: 0;

    .tableGroupTitleInner {
      color: #000;
      padding: 1px 5px;
      line-height: 22px;
      border-radius: 4px 4px 0 0;
      white-space: nowrap;
      background: @themeGrayColor;
      margin-right: 5px;
      display: flex;
      align-items: center;
      font-weight: 500;
      .customCheckBoxLebel {
        box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.3);
        border: 1px solid #b7b7b7;
      }
      span {
        font-size: 11px;
      }
      .moreOption {
        width: 10px;
        height: 10px;
        display: block;
        cursor: pointer;
        margin-left: 10px;
        display: none;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      &:hover,
      &.moreOptionShow {
        .moreOption {
          display: block;
        }
      }
      .checkBoxSelect {
        display: block;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
        img {
          width: 12px;
          height: 14px;
        }
        &:after {
          content: '';
          width: 1px;
          height: 22px;
          background: @themeDarkBoreder;
          position: absolute;
          right: -6px;
          top: -4px;
          display: none;
        }
      }
    }
  }
}
.tableGroupingTr {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

.groupingTableAmount {
  text-align: center !important;
  padding: 6px 8px 8px 8px !important;
  color: #000;
  font-weight: bold;
  font-size: 10px;
  //position: sticky;
  // height: 12px;
  cursor: pointer;
  border: none !important;
  &:first-child {
    text-align: right;
    padding: 6px 15px 8px 8px;
  }
  .selectAmount {
    position: relative;
    z-index: 1;
  }
}
.tableInput {
  margin: auto;
  position: relative;
  display: block;
  display: flex;
  margin: -2.5px 0px -2.5px 0px;

  img {
    width: 15px;
    height: auto;
    margin: auto;
  }

  input {
    width: 70%;
    width: calc(100% - 30px);
    padding: 0px 8px;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    font-size: 14px;
  }

  &.tableTopOfferInput {
    input {
      font-size: 13px;
      position: absolute;
      top: -16px;
      left: 0;
      padding: 4px 5px;
    }
  }
  &.noteAdd {
    display: block;
    width: 120px;
  }
  &.noteAddList {
    width: 180px;
    height: 100px;
  }
  .add-sign {
    position: absolute;
    left: 2px;
    top: 2px;
    color: #868788;
  }
  .add-sign-mobile {
    position: absolute;
    left: 2px;
    top: 3px;
    color: #aaaaaae6 !important;
  }
  .add-sign-deal-list {
    position: absolute;
    left: 2px;
    top: 3px;
    color: #161616 !important;
  }
}
.tableLink {
  color: #000;
}
.rapPrice {
  color: #db1c1c;
}
.askingPrice {
  color: #469c1a;
}
.m2mPrice {
  color: #296cb1;
}
.certificateNo {
  color: @themeColor;
  text-decoration: underline;
  &:hover {
    color: @themeColor;
    text-decoration: underline;
  }
}
.diamondDetailSummary {
  .tabInnerTableScroll {
    height: calc(100vh - 174px);
  }
}
.colorDropdown {
  background: #fff;
  box-shadow: 0 0 14px 0px rgba(0, 0, 0, 10%);
  padding: 5px 10px;
  border-radius: 5px;
  .SelectcolorWiseList {
    display: block;
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin: 5px 0;
  }
}
.addNewColumButton {
  color: rgba(52, 52, 52, 0.5);
  white-space: nowrap;
  padding: 5px 8px;
  border-radius: 5px;
  margin-top: 5px;
  display: flex;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  position: absolute;
  &:hover {
    background: #e2e7ed;
  }
  img {
    width: 10px;
    margin-right: 5px;
    height: 10px;
  }
}
.filedLeftBlock {
  position: relative;
}
.closeRow {
  position: absolute;
  left: 0;
  img {
    width: 10px;
    margin-left: 5px;
    height: 10px;
  }
}
.colunmAlign {
  display: flex;
  justify-content: center;
  .colunmAlignItem {
    width: 30px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
.diamondListingAction {
  justify-content: space-between;
}
.listingPagePagination {
  display: flex;
  align-items: center;
  .clientPagination {
    display: flex;
    align-items: center;
    ul {
      margin-bottom: 0;
      li {
        line-height: 20px;
        &.active {
          background-color: @themeColor;
          color: #fff;
        }
      }
    }
    .paginationText {
      color: #000;
      font-size: 12px;
      margin-left: 5px;
    }
    .paginationSelect {
      .ant-select-selection {
        background: #fff;
        border-radius: 5px;
        border: none;
        height: auto;
        .ant-select-selection__rendered {
          line-height: 26px;
          .ant-select-selection-selected-value {
            font-size: 12px;
            color: #000;
          }
        }
        .ant-select-arrow {
          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  width: max-content;
  padding: 6px 8px;
  color: #292929 !important;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: white !important;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  font-weight: 600;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%) !important;
}
.ant-tooltip-arrow {
  &::before {
    background-color: white !important;
  }
}

.numberValue {
  text-align: right !important;
}
.redColor {
  color: #db1c1c;
}
.greenColor {
  color: #6bc950;
}
.selectStonHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  h2 {
    margin: 0;
  }
  .search_para {
    width: 97%;
  }
  .multi-search {
    width: 100%;
    justify-content: space-between;
  }
  .diamond_legends {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 100px;

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 5px;
      color: @themeDarkBoreder;
      font-size: 14px;
      &::after {
        content: '|';
        display: inline-block;
        margin-left: 5px;
      }
      &:last-child {
        margin-right: 0;
        &::after {
          content: '';
          margin: 0;
        }
      }
      .legend_clr {
        width: 8px;
        height: 8px;
        margin-right: 5px;
        border-radius: 2px;

        display: block;
        &.avail {
          background: #6bc950;
        }
        &.bus {
          background: #db1c1c;
        }
        &.bid {
          background: #a87d52;
        }
        &.up {
          background: #c950c1;
        }
        &.memo {
          background-color: #307bea;
        }
      }
    }
  }
}
.selectStoneValueBlock {
  // border: 1px solid #efefef;
  // padding: 10px 20px;
  // background-color: #f8f8f8;
  // position: absolute;
  // top: -40px;
  display: flex;
  align-items: center;
  // width: auto;
  // position: absolute;
  background: @themeLightColor;
  width: 100%;
  // left: 200px;
  // right: 0;
  padding: 0px 15px 5px;
  // z-index: 1;
  // top: 0;
  justify-content: space-between;
  margin-left: 10px;
  .selectStoneValueContainer {
    width: calc(100% - 75px);
    display: flex;
    align-items: center;
    .selectStoneValueItem {
      span.selectStoneLabel,
      span.selectStopnValue {
        font-size: 14px;
      }
    }
  }
  &.diamondListStone {
    border-radius: 5px;
    width: calc(100% - 15px);
    margin-bottom: 5px;
    padding: 5px;
    .scroll_wrap {
      width: 95%;
      display: flex;
      .selectStoneValueItem {
        span.selectStoneLabel {
          width: max-content;
        }
      }
    }
    // &.bid_it{
    //   width: calc(100% - 510px);
    //   left: 240px;
    //   top : 13px;
    // }
    &.my_bid {
      width: calc(100% - 15px);
      .scroll_wrap {
        // width : calc(100% - 80px)
      }
    }
    &.my_acc {
      width: 100%;
      // padding-left: 0;
      background: @themeLightColor;
      margin-left: 0;
      .scroll_wrap {
        // width : calc(100% - 80px)
      }
    }
  }
  .selectStoneValueItem {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    align-items: center;
    span {
      &.selectStoneLabel {
        color: rgba(52, 52, 52, 0.5);
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        margin-right: 3px;
        display: block;
      }
      &.selectStopnValue {
        color: #307bea;
        font-size: 12px;
      }
    }
  }
  .searchStoneClose {
    color: #fff;
    // position: absolute;
    // right: 15px;
    cursor: pointer;
    background: @themeColor;
    padding: 3px 8px;
    border-radius: 5px;
    // top: 6px;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    display: block;
    cursor: pointer;
    top: 11px;
    img {
      width: 10px;
      height: 10px;
    }
  }
  &.profileStonSelect {
    position: absolute;
    top: -42px;
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #000;
    width: 100%;
    padding-right: 80px;
    .searchStoneClose {
      display: none;
    }
  }
}

.FilterOption {
  margin-right: 10px;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.offerPrice {
  &.client {
    color: #469c1a;
    font-weight: bold;
    // margin-right: 2px;
  }
  &.admin {
    color: #124673;
    font-weight: bold;
    // margin-right: 2px;
  }
}
.popupInnerTitle {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 20px 0;
  text-decoration: underline;
  line-height: 18px;
}
.confirmListOption {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  .from-group {
    width: 50%;
    padding-right: 10px;
    .ant-input-number {
      // width: 130px;
      // margin: 10px !important;
      border-color: #434443;
      // border-color: #3a8ed2;
      box-shadow: none;
      margin: 0 !important;
      width: 100%;
      border: none;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 0;
      &:hover {
        border-color: @themeColor;
      }
      &:focus {
        // box-shadow: 0 0 0 2px hsl(240deg 19% 86%);
        border-color: @themeColor;
      }
    }
    .err_span {
      color: #f5222d;
      display: block;
    }
  }
}
.ant-input-number-focused {
  box-shadow: none;
}
.summaryListWrapper {
  margin-bottom: 20px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 5px;
  .smallTableScroll {
    overflow: auto;
    padding-bottom: 15px;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  }
  .summaryListTop {
    th {
      color: #343434;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
    }
  }
  .summaryListScroll {
    td {
      font-size: 12px;
      text-align: center;
      padding: 2px 5px;
      border-right: 1px solid #f2f2f2;
      &:last-child {
        border-right: none;
      }
    }
  }
}
.checkImageBox {
  img {
    width: 16px;
    height: 16px;
    &.checkClose {
      width: 12px;
      height: 12px;
    }
  }
}
.stone-width {
  &.botoomStickyBar {
    width: calc(100% - 0px);
    background-color: transparent;
    box-shadow: none;
  }
}
.botoomStickyBar {
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
  background: #fff;
  position: absolute;
  bottom: 0;
  padding: 10px 15px;
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  right: 0;
  z-index: 5;
}
.diamondSearchSticky {
  position: sticky;
  bottom: 0;
}
.botoomStickyBar .bottomStickyButton {
  border: 1px solid @themeColor;
  border-radius: 5px;
  margin: 0 3px;
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 5px;
  line-height: 18px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  background-color: @themeColor;
  &.disable {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      border: 1px solid @themeColor;
      background-color: @themeColor;
      color: #fff;
      span {
        color: #fff;
      }
    }
  }
}
.botoomStickyBar .bottomStickyButton:focus {
  outline: none;
}
.botoomStickyBar .bottomStickyButton span {
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  transition: all 0.5s ease-in-out;
}
.botoomStickyBar .bottomStickyButton:hover {
  background-color: #fff;
}
.botoomStickyBar .bottomStickyButton:hover span {
  color: @themeColor;
}
.clientPagination ul {
  display: flex;
  padding: 0;
  align-items: center;
}
.clientPagination li {
  list-style: none;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.clientPagination li img {
  width: 10px;
  height: 10px;
}
.clientPagination li.active {
  background-color: #fff;
  border-radius: 100%;
}

//   filterTop

.filterTopMainBlock {
  // border-bottom: 1px dashed #e1e1e1;
  min-height: 51px;
  padding: 5px 0;
}

.searchInnerFilterMain {
  display: flex;
  align-items: center;
  padding: 0 10px;
  .searchInnerFilterItem {
    display: flex;
    align-items: center;
    border-radius: 20px;
    margin-right: 10px;
    background-color: #d4d4e2;
    cursor: pointer;
    .searchInnerFilterTitle {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      padding: 4px 0 4px 10px;
    }
    .searchMainFilterIcon {
      margin-left: 5px;
      img {
        width: 12px;
        height: 12px;
        display: block;
      }
      &.filterClose {
        padding: 5px 10px 5px 8px;
        border-left: 1px solid @themeDarkBoreder;
        img {
          width: 10px;
          height: 10px;
        }
      }
    }
    &.active {
      background-color: #d4d4e2;
      border: 1px solid #434443;
      // border: 1px solid #3a8ed2;
    }
  }
}

.clientDetailDiamonList {
  margin-right: 10px;
  .listdetailBox {
    margin-left: 10px;
    position: relative;
    &:after {
      content: '';
      width: 1px;
      height: 10px;
      background-color: #a69e9e;
      position: absolute;
      right: -5px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}
.filterTopOption {
  display: flex;
  padding: 0 10px;
  align-items: center;
  &.ant-drawer-open {
    .ant-drawer-close {
      left: -65px !important;
    }
  }
  .ant-drawer-close {
    top: 10px;
    left: 0;
    right: auto;
    width: 36px;
    height: 36px;
    padding: 11px;
    background: #fff;
    border: none;
    outline: 0;
    border-radius: 50%;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.07);
    stroke: #979797;
    display: flex;
    cursor: pointer;
  }
  .filterSidebarItem {
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: relative;
    border-radius: 25px;
    padding: 0 10px;
    min-height: 30px;
    .ant-calendar-picker {
      .ant-input {
        background-color: transparent;
        padding: 0 !important;
        height: auto;
        border: none;
        width: 230px;
        display: flex;
        align-items: center;
        .ant-calendar-range-picker-separator {
          color: #000;
        }
        .anticon {
          display: block;
          line-height: 30px;
          padding-left: 7px;
          height: 30px;
          top: 0;
          margin: 0;
          position: relative;
          background: transparent;
          &.anticon-close-circle {
            position: absolute;
            // right: 0;
          }
          &:after {
            content: '';
            width: 1px;
            height: 28px;
            background: @themeDarkBoreder;
            position: absolute;
            left: 0;
            display: block;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          svg {
            fill: #000;
          }
        }
        .ant-calendar-range-picker-input {
          width: 45%;
          font-size: 12px;
          color: #000 !important;
        }
      }
    }
    .ant-select {
      height: 20px;
      width: 320px !important;
      .ant-select-selection {
        height: 20px;
        background: transparent;
        min-height: auto;
        padding: 0 3px;
        border-radius: 0 !important;
        border: none !important;
        box-shadow: none !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:active {
          box-shadow: none;
        }
        .ant-select-selection__rendered {
          line-height: normal;
          margin: 0;
          height: 20px;
          display: flex;
          .ant-select-selection-selected-value {
            color: #000 !important;
            padding-right: 6px;
            line-height: 18px;
          }
          .ant-select-selection__placeholder {
            position: relative;
            top: 0;
            margin-top: 0;
            height: auto;
          }
          ul {
            li {
              height: 20px;
              margin-top: 0;
              line-height: 20px;
              &.ant-select-selection__choice {
                margin: 0 3px;
                background: rgba(47, 109, 177, 9%);
                color: #000;
                font-size: 12px;
                height: auto;
                line-height: 18px;
                align-items: center;
                display: flex;
                // &[unselectable="on"]{
                //   display: flex;
                // }
              }
            }
          }
        }
        .ant-select-arrow {
          position: relative;
          top: 0;
          padding-left: 5px;
          right: 0;
          margin: 0 0;
          svg {
            fill: #000;
          }
        }
      }
      &.updateStausBlock {
        min-width: 80px !important;
        width: auto !important;
        border: none;
        &:hover {
          box-shadow: none;
        }
        &.md-size {
          width: 185px !important;
        }
        &.sm-size {
          width: 80px !important;
        }
      }
    }
  }
  .filterSidebarTitle {
    font-size: 12px;
    font-weight: 600;
    color: #000 !important;
    margin: 0;
    line-height: 14px;
    white-space: nowrap;
  }
}

// offer

.offerTopCollpaseOption {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .collpaseItem {
    display: flex;
    margin: 0 5px;
    .collpaseItemBox {
      transition: background-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
      display: flex;
      align-items: center;
      position: relative;
      padding: 5px 7px;
      color: #7c828d;
      cursor: pointer;
      font-size: 12px;
      border: 1px solid #d8dce2;
      &:first-child {
        border-radius: 3px 0 0 3px;
      }
      &:last-child {
        border-radius: 0 3px 3px 0;
        border-left: none;
      }
      &.active {
        color: @themeColor;
      }
    }
  }
}
.offerDiffrent {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .offerDiffrentItem {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    span {
      &:first-child {
        white-space: nowrap;
        font-size: 12px;
        color: #212121;
        margin-right: 5px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
      &:last-child {
        white-space: nowrap;
        color: #000;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
.offerStickyFiled {
  table {
    tr {
      td,
      th {
        &:last-child {
          position: sticky;
          right: 0;
          background-color: #fff;
        }
        &:nth-last-child(2) {
          position: sticky;
          right: 97px;
          background-color: #fff;
        }
      }
    }
  }
}
.totalDisscount {
  margin-right: 0 !important;
  margin-top: 20px !important;
  input {
    height: 30px;
  }
}
.offerWrapper {
  .DiamondDetailPopup {
    // margin-top: 20px;
    width: auto;
    // margin-right: 50px;
    // border-right: 1px solid #e2e2e2;
    // padding-right: 50px;
    display: flex;
    .DiamondDetailPopupItem {
      width: auto;
      justify-content: space-between;
      margin-right: 10px;
      margin-bottom: 0;
      border-right: 1px solid #ddd;
      padding-right: 10px;
      span {
        min-width: min-content !important;
        &:last-child {
          font-weight: 600;
        }
      }
      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
  }
  .popupInnerTitle {
    margin-bottom: 0;
    h2 {
      margin-bottom: 0;
    }
  }
  .offerTopBlock {
    margin-bottom: 15px;
  }
  .offerInputBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .inputBlock {
      padding-top: 10px;
      margin-bottom: 0 !important;
      padding-right: 20px;
      width: 100%;
    }
  }
  .offerBottomBox {
    margin-top: 10px;
  }
}
.offerNote {
  width: 100%;
  color: #000000;
}

// grid View
.diamondGridView {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  .daimondGridViewItem {
    width: calc(25% - 10px);
    margin: 5px;
    border: 1px solid #e2e2e2;
    // padding: 10px;
    border-radius: 10px;
    .DiamondGridViewImg {
      width: 100%;
      height: 250px;
      // padding: 10px;
      background: #fafafa;
      border-radius: 10px 10px 0 0;
      position: relative;
      display: flex;
      justify-content: center;
      .diamondGrdiCheckbox {
        position: absolute;
        left: 10px;
        top: 10px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px 10px 0 0;
      }
    }
    .gridViewContent {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #e2e2e2;
      padding-top: 10px;
      /* margin-top: 15px; */
      padding: 10px;
      .gridLeftContent {
        width: 60%;
        text-align: left;
        h2 {
          font-size: 16px;
          font-weight: 800;
          margin-bottom: 5px;
          line-height: 20px;
        }
        a {
          margin-bottom: 5px;
          color: #000;
          display: block;
          font-weight: 500;
          text-decoration: underline;
        }
        p {
          margin: 0;
          font-size: 14px;
          color: #545454;
        }
      }
      .gridRightContent {
        width: 40%;
        text-align: right;
        span {
          display: block;
          color: #000;
          font-weight: 500;
          font-size: 16px;
        }
        .statusShow {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          margin-top: 5px;
          .statusIcon {
            margin-left: 5px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            display: block;
            background: #6bc950;
            &.red {
              background-color: #db1c1c;
            }
            &.blue {
              background-color: #307bea;
            }
          }
        }
      }
    }
  }
}
.gridListIcon {
  margin-right: 10px;
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
  }
}
.dropdown {
  position: relative;
}
.diamondListBottomDropdown {
  background-color: #fff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-width: 150px;
  display: none;
  overflow: visible;
  transform: translate(0, 0) !important;
  top: auto !important;
  bottom: 0 !important;
  margin-bottom: 35px;
  &.leptopSize {
    right: -200px;
    left: auto !important;
    margin: auto;
  }
  &.show {
    display: block;
  }
  ul {
    margin: 0;
    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      .dropdownBottomItem {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0;
        background-color: transparent;
        border: none;
        .dropdownIcon {
          width: 25px;
          height: 25px;
          background-color: #e4e6eb;
          border-radius: 100%;
          margin-right: 5px;
          display: flex;
          img {
            width: 14px;
            height: 14px;
            margin: auto;
          }
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: #000;
        }
      }
      .dropdownrightArrow {
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .dropdownInnerTitle {
    display: flex;
    align-items: center;
    padding: 0 10px 5px 10px;
    position: relative;
    cursor: pointer;
    img {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      &.sendIcon {
        position: absolute;
        right: 5px;
        width: 16px;
        height: 16px;
      }
    }
    h2 {
      font-size: 14px;
      margin: 0;
      font-weight: 600;
      color: #000;
    }
  }
}
.dropdownIconCheckBox {
  margin-right: 5px;
}
.shareOptionSet {
  margin-top: 20px;
  max-height: 332px;
  overflow: auto;
  .shareOptionSetItem {
    width: 100%;
    text-align: center;
    .singleOnly {
      display: inline-block;
      margin: 10px;
      li {
        margin-left: 0;
        background: @staticBg;
        border: 1px solid #e1e1e1;
        background-color: @staticBg;
        border-radius: 5px;
        &.active {
          background-color: @themeLightColor;
          border: 1px solid @themeColor;
          color: @themeColor;
        }
        .shareOptionLabel {
          font-weight: 500;
          color: @themeDarkBoreder;
        }
        .dropdownBottomItem {
          display: flex;
          margin: 10px;
        }
      }
      .ant-checkbox-checked {
        &:after {
          border: 0;
        }
      }
    }
    // margin-top: 20px;
    .dropdownMainTitle {
      //  width: 100%;
      width: 120px;
      display: flex;
      span {
        font-weight: 600;
        font-size: 14px;
        color: #000;
      }
    }
    .shareOptionTitle {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 10px;
      display: block;
    }

    .excelShare {
      border-left: 1px solid #e2e2e2;
      padding-left: 10px;
    }
    ul {
      display: flex;
      width: 100%;
      justify-content: flex-start !important;
      align-items: center;
      //    padding: 0 10px !important;
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 10px !important;
      margin-bottom: 10px !important;
      .shareDropdownUi {
        margin-left: 25px !important;
      }
      li {
        list-style: none;
        margin: 4px;
        border: 1px solid #e1e1e1;
        background-color: #fafafa;
        padding: 8px 10px;
        min-width: 100px;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 14px;
        .dropdownBottomItem {
          display: flex;
          .shareOptionLabel {
            font-weight: 500;
            color: #000;
            // margin-left: 8px;
          }
        }
        &.active {
          background-color: @themeLightColor;
          border: 1px solid @themeColor;
          color: @themeColor;
        }
      }
      .dropdownRightCheck {
        display: flex;
        width: calc(100% - 120px);
        flex-wrap: wrap;
      }
    }
  }
}
.listingTopBlock {
  border-bottom: 1px dashed #e1e1e1;
  .diamondlistInfo {
    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .filterTopMainBlock {
    border-bottom: none;
  }
}

.infoPopoverWrapper {
  position: relative;
  overflow: visible;
}

.infoPopover {
  width: 320px;
  .ant-popover-content {
    border-radius: 10px;
    position: relative;

    p {
      b {
        margin-right: 10px;
      }
    }
    .ant-popover-inner {
      border-radius: 10px;
    }
    &:after {
      content: '';
      background: @notificationColor;
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
    }
  }
}
.listingSelected {
  // position: absolute;
  // right: 0;
  // background: #fff;
  // padding: 10px;
  // width: calc(100% - 280px) !important;
}
.demandPopupTwoValue {
  display: flex;
  justify-content: space-between;
  .inputBlock {
    width: 49%;
  }
}
.countDownBlock {
  display: flex;
  justify-content: space-between;
  .countdownItem {
    span {
      display: block;
      &:first-child {
        font-size: 14px;
        color: #000;
        font-weight: 600;
      }
      &:last-child {
        text-transform: uppercase;
        color: #555;
        font-size: 10px;
        font-weight: 500;
      }
    }
  }
}

// bid
.bidEndBlock {
  margin-top: 5px;
  .bidEndLabel {
    font-weight: 600;
    color: #000;
    font-size: 12px;
    width: 70px;
    line-height: 14px;
    text-align: right;
  }
  .countDownBlock {
    .countdownItem {
      background-color: @themeLightColor;
      border: 1px solid @themeColor;
      border-radius: 4px;
      padding: 2px 7px;
      margin: 0 5px 5px;
      span {
        color: @themeColor;
        text-align: center;
      }
    }
  }
}

.empty-data {
  text-align: center;
  width: 98%;
  margin: 0 auto;
  height: 88%;
}

.empty-data h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .cart-empty-data {
    text-decoration: underline;
    a {
      color: #292f70 !important;
      // color: #292f70 !important;
    }
  }
}
.priceChange {
  vertical-align: bottom;
  span {
    font-size: 10px;
    margin-left: 2px;
  }
}
.bottomNote {
  text-align: center;
  span {
    display: block;
    color: #232323;
    font-size: 13px;
    line-height: 20px;
    em {
      font-style: normal;
      font-weight: 600;
      padding: 2px 5px;
      border-radius: 5px;
      background: @themeColor;
      color: #fff;
    }
  }
}
.columnSettingPage {
  table {
    tr {
      td,
      th {
        &:first-child {
          left: -30px !important;
        }
      }
    }
  }
}
.confirmStonePopUp .searchResultTable {
  height: calc(100vh - 390px);
}
.ant-notification {
  z-index: 10000001;
  right: 20px !important;
  // right: 42px !important;
  top: 130px !important;
  bottom: auto !important;
  margin-right: 0;
  width: 420px;
  .ant-notification-notice {
    // background-color: @tableBackGroundColor;
    // border: 1px solid @themeColor;right: 42px;
    padding: 20px 24px;
    .ant-notification-notice-message {
      font-weight: 600;
    }
    &:after {
      content: '';
      // background-image: url(http://192.168.0.155:3002/static/media/header-bg.b04ac58f.svg);
      position: absolute;
      left: 0;
      width: 8px;
      height: 100%;
      top: 0;
      background-size: contain;
      background-position: center;
      /* transform: rotate(90deg); */
      background: @notificationColor;
      opacity: 1;
    }
  }
  .ant-notification-notice-close {
    color: #000;
    top: 5px;
    right: 6px;
    svg {
      fill: @themeColor;
    }
  }
}
.searchResultTable.watchpopup {
  height: calc(100vh - 165px);
}
.mt-10 {
  margin-top: 10px;
}
.finalFMSelect.ant-select {
  width: 200px;
}
.pos_rel {
  position: relative;
}

.gia-cer-color {
  color: #edb41b !important;
}
.ige-cer-color {
  color: #927559 !important;
}
.other-cer-color {
  color: #339cd4 !important;
}
.backgroud-change {
  background-color: #fba9ad !important;
  // td:first-child{
  //   background-color: #fba9ad !important;
  //   position: sticky !important;
  //   left: -10px !important;
  //   z-index: 1 !important;
  // }
}
.selectTr {
  td:first-child {
    background: @trselected !important;
    border-right: 1px solid @trselected !important;
  }
}

.low-bid {
  background-color: #fba9ade0 !important;
  td {
    background-color: #fba9ade0 !important;
  }
  td:first-child {
    background-color: #fba9ade0 !important;
    position: sticky !important;
    left: -10px !important;
    z-index: 1 !important;
  }
}
.highest-bid {
  background-color: #cae9b9 !important;
  td {
    background-color: #cae9b9 !important;
  }
  td:first-child {
    background-color: #cae9b9 !important;
    position: sticky !important;
    left: -10px !important;
    z-index: 1 !important;
  }
}
.equal-bid {
  background-color: #a9a9a9a1 !important;
  td {
    background-color: #a9a9a9a1 !important;
  }
  td:first-child {
    background-color: #a9a9a9a1 !important;
    position: sticky !important;
    left: -10px !important;
    z-index: 1 !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .selectStoneValueBlock {
    &.diamondListStone {
      .scroll_wrap {
        width: 93%;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .botoomStickyBar {
    .bottomStickyButton {
      padding-left: 5px;
      padding-right: 5px;
      span {
        font-size: 11px;
      }
    }
  }
  .selectStonHeader {
    .search_para {
      width: 85%;
    }
  }
  .selectStonHeader {
    .diamond_legends {
      margin-right: 0px;
    }
  }
  .selectStoneValueBlock {
    padding-right: 15px !important;
    &.diamondListStone {
      .scroll_wrap {
        width: 85%;
        overflow: hidden;
        overflow-x: auto;
      }
    }
    .searchStoneClose {
      display: inline-block;
      margin: 0;
    }
  }
}
@media screen and (max-width: 767px) {
  .botoomStickyBar {
    display: none;
  }
  .diamondGridView {
    padding-bottom: 60px;
    .daimondGridViewItem {
      width: 100%;
    }
  }
  .compareMainPage {
    padding-bottom: 67px;
  }
  .empty-data {
    h3 {
      height: calc(100vh - 255px);
      width: 100%;
      font-size: 18px;
      color: #000;
      text-align: center;
      display: flex !important;
      justify-content: center;
      align-items: center;
      font-weight: 600;
    }
  }
  .selectStoneValueBlock {
    margin-left: 0;
    &.diamondListStone {
      width: 100%;
      .scroll_wrap {
        width: 78%;
        overflow: hidden;
        overflow-x: auto;
      }
    }
    &.profileStonSelect {
      position: relative;
      top: 0;
      padding-right: 0;
      .scroll_wrap {
        display: flex;
        overflow: hidden;
        overflow-x: auto;
      }
    }
    .selectStoneValueItem {
      min-width: max-content;
    }
  }
}

.box {
  position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.ribbon {
  position: absolute;
  left: 5px;
  top: -2px;
  width: 100%;
  height: auto;
  color: #fff;
  line-height: 1;
  font-size: 10px;
  text-align: center;
  overflow: unset;
  padding: 2px;
  z-index: 1;
}
.ribbon {
  &:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    top: 14px;
    left: calc(100% - 6px);
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }
  &.indeterminate {
    background: #6e8368;
    &:after {
      border: 3px solid #7a7a63;
    }
  }
  &.active {
    background: #00d299;
    &:after {
      border: 3px solid #448039;
    }
  }
  &.inactive {
    background: #f5222d;
    &:after {
      border: 3px solid #f5222d;
    }
  }
}

.ribbon-icon {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 20%;
  height: auto;
  color: #fff;
  line-height: 1;
  font-size: 10px;
  text-align: center;
  overflow: unset;
  padding: 2px;
  z-index: 1;
}
.ribbon-icon {
  &:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    top: 14px;
    right: calc(100% - 6px);
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }
  &.active {
    background: #00d299;
    &:after {
      border: 3px solid #448039;
    }
  }
  &.inactive {
    background: #f5222d;
    &:after {
      border: 3px solid #f5222d;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1359px) {
  .botoomStickyBar {
    padding-left: 5px;
    padding-right: 5px;
    .bottomStickyButton {
      padding-left: 7px;
      padding-right: 7px;
    }
  }
  .ribbon {
    top: -5px;
  }
}
.email-verified-modal {
  .ant-modal-content {
    font-size: 17px;
    border-bottom: 1px solid #e6e6e6;
    .ant-modal-close {
      top: -5px !important;
    }
    .ant-modal-header {
      padding: 10px 14px !important;
    }
    .ant-modal-body {
      padding: 10px 14px !important;
      h3 {
        font-family: Gilroy;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.2px;
        font-weight: 600;
      }
    }
  }
}
.deal-of-the-day-continer {
  .quoteExtraItem {
    color: #161616 !important;
    margin-right: 20px !important;
    margin-top: 10px !important;
  }
  span {
    font-size: 13px !important;
    font-weight: 500;
  }
}
.status-display {
  display: flex;
  align-items: center;
  margin-left: 10px;
  .circle-high {
    margin: 3px;
    background: #9be274db;
    clip-path: circle(50%);
    height: 21px;
    width: 21px;
  }
  span {
    font-size: 17px;
    line-height: 1;
    color: #383a35;
  }
  .circle-equal {
    margin: 3px;
    background: #9be274db;
    clip-path: circle(50%);
    height: 21px;
    width: 21px;
  }
  .circle-low {
    margin: 3px;
    background: rgb(250 178 182);
    clip-path: circle(50%);
    height: 21px;
    width: 21px;
  }
  .circle-equal {
    margin: 3px;
    background: #c6c6c6;
    clip-path: circle(50%);
    height: 21px;
    width: 21px;
  }
}

@primary-color: #434443;