@import 'variable.less';

.quickSearchTopHeader {
  margin-bottom: 3px;
  h2 {
    margin: 0;
  }
}
.quickSearchTable {
  width: 100%;
  overflow: auto;
  height: 100%;
  table {
    width: 100%;
    border-collapse: unset;
    tr {
      th {
        background-color: #e9eaea;
        padding: 10px;
        // border: 1px solid #e8e8e8;
        // color: @themeColor;
        color: #000;
        font-weight: 500;
        text-transform: uppercase;
        &:nth-child(3) {
          text-align: center;
        }
      }
      td {
        padding: 6px;
        border: 1px solid #e9eaea;
        white-space: nowrap;
        min-width: 70px;
        cursor: pointer;
        &:hover,
        .active {
          background-color: @themeLightColor;
          color: @themeDarkBoreder;
          border: 1px solid @themeSecondColor;
        }
      }
      .quickSearchTitleTd {
        background-color: #f8f8f8;
        &:hover {
          background-color: #f8f8f8;
        }
      }
    }
  }
}
.quickSearchMainWrapper {
  background-color: white;
  // margin-top: 8px;
  margin-left: 8px;
  height: 100%;
  background: #fff;
  border-top-left-radius: 10px;

  .headerSelectOption {
    width: auto;
    background: #fff;
    min-height: 100%;
    display: flex;
    align-items: center;
  }
  .quickSearchRight {
    // height:calc(100vh - 122px);
    padding: 10px 10px 0px;
    // padding: 10px;
    // height: calc(100vh - 183px);
    height: calc(100vh - 227px);
    overflow: hidden;
    // overflow: auto;
    &.checkImageWrapper {
      padding: 0;
    }
  }
}
.selectdValue {
  width: 100%;
  display: flex;
  padding: 0 15px;
  .selectValueItem {
    width: auto;
    margin-right: 10px;
    img {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      color: #000;
    }
  }
}

@media (min-width: 768px) {
  .mobileheader {
    display: none;
  }
}

@media (max-width: 767px) {
  .quickSearchTopHeader {
    display: none !important;
  }
  .quickSearchMainWrapper {
    background-color: white;
    margin-top: 10px;
  }
  .quickSearchFilter {
    .leftSelectItem {
      display: block;
      margin-right: 0;
      margin-bottom: 10px;
      .ant-select-selection {
        .ant-select-selection__rendered {
          margin-left: 0;
        }
      }
    }
  }
  .selectdValue {
    display: -webkit-box;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@primary-color: #434443;