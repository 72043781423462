@import 'variable.less';
@media (max-width: 1366px) {
  .mobilediamond {
    .diamondDetailInnerDetail {
      .diamondDetailImageBox {
        &.htmlViewImage {
          // height: 43vh;
          // height: 38vh;
          // height: 38vh;
          // height: 63vh;
        }
      }
    }
  }
}
@media (min-width: 990px) and (max-width: 1025px) {
  .diamondDetailLeft {
    .diamondDetailInnerDetail {
      .diamondDetailImageBox {
        &.htmlViewImage {
          height: 680px;
          position: relative;
        }
      }
    }
  }
}
@media (min-width: 800px) and (max-width: 825px) {
  .diamondDetailLeft {
    .diamondDetailInnerDetail {
      .diamondDetailImageBox {
        &.htmlViewImage {
          height: 530px;
          position: relative;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  // .diamondDetailLeft {
  //   .diamondDetailInnerDetail {
  //     .tab-content, {
  .diamondDetailLeft {
    .diamondDetailInnerDetail {
      .tab-pane {
        min-height: 300px;
        // height: auto;
      }
    }
  }
  //     }
  //   }
  // }

  .diamondDetailInnerDetail {
    .customerMaintab {
      display: flex;
      overflow: auto;
      justify-content: center;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .mobilediamondDetail {
    padding-bottom: 50px;
  }
  .mobilediamond {
    width: 100%;
    display: block;
    box-shadow: none;
    // margin-bottom: 20px;

    // height: 360px;
    .diamondDetailInnerDetail {
      // height: 355px;
      // height: 342px;
      // height: auto;
      .customerMaintab {
        // order: 2;
        // position: absolute;
        // z-index: 10;
        // width: 100%;
        // bottom: -19px;
      }
      .diamondDetailImageBox {
        .magnifier {
          width: 100% !important;
        }
        &.htmlViewImage {
          // height: 388.56px;
          display: block !important;
          // height: auto;
          iframe {
            // height: 300px;
          }
        }
        // iframe {
        //   height: 400px;
        // }
        // img {
        //   object-fit: contain;
        // }
      }
    }
  }
  .mobileRightDetail {
    flex-wrap: wrap;
    width: 100%;
    display: -webkit-box;
    align-items: flex-start;
    overflow: auto;
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    padding: 10px;
    margin-left: 0;
    display: grid;
    // margin-top: 20px;
    .diamondDetailMore {
      width: 100%;
      height: max-content;
      border: 1px solid #e2e2e2;
      // border-radius: 10px;
      margin-bottom: 10px;
      padding: 0px;
      max-height: 535px;
      overflow: auto;
      .detailSmallTitle {
        background: @themeColor;
        color: @whiteColor;
        padding: 5px 10px;
        text-align: center;
        margin-bottom: 0;
      }
      .diamondDetailMoreHalf {
        ul {
          width: 33.33%;
          &.twoCol {
            width: 50%;
          }
          &.threeCol {
            width: 33.33%;
          }
          &.fourCol {
            width: 25%;
          }
          li {
            span {
              &:first-child {
                width: 100%;
              }
              &:last-child {
                width: 100%;
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
// @media (max-width: 540px) {
//   .mobilediamond {
//     .diamondDetailInnerDetail {
//       .diamondDetailImageBox {
//         &.htmlViewImage {
//           height: 346px;
//           display: block !important;
//         }
//       }
//     }
//   }
// }
// @media (max-width: 414px) {
//   .mobilediamond {
//     .diamondDetailInnerDetail {
//       .diamondDetailImageBox {
//         &.htmlViewImage {
//           height: 333px;
//           display: block !important;
//         }
//       }
//     }
//   }
// }
// @media (max-width: 411px) {
//   .mobilediamond {
//     .diamondDetailInnerDetail {
//       .diamondDetailImageBox {
//         &.htmlViewImage {
//           height: 330px;
//           display: block !important;
//         }
//       }
//     }
//   }
// }
// @media (max-width: 390px) {
//   .mobilediamond {
//     .diamondDetailInnerDetail {
//       .diamondDetailImageBox {
//         &.htmlViewImage {
//           height: 312px;
//           display: block !important;
//         }
//       }
//     }
//   }
// }
// @media (max-width: 375px) {
//   .mobilediamond {
//     .diamondDetailInnerDetail {
//       .diamondDetailImageBox {
//         &.htmlViewImage {
//           height: 300px;
//           display: block !important;
//         }
//       }
//     }
//   }
// }
// @media (max-width: 360px) {
//   .mobilediamond {
//     .diamondDetailInnerDetail {
//       .diamondDetailImageBox {
//         &.htmlViewImage {
//           height: 287px;
//           display: block !important;
//         }
//       }
//     }
//   }
// }

// @media (max-width: 320px) {
//   .mobilediamond {
//     .diamondDetailInnerDetail {
//       .diamondDetailImageBox {
//         &.htmlViewImage {
//           height: 256px;
//           display: block !important;
//         }
//       }
//     }
//   }
// }
// @media (max-width: 280px) {
//   .mobilediamond {
//     .diamondDetailInnerDetail {
//       .diamondDetailImageBox {
//         &.htmlViewImage {
//           height: 226px;
//           display: block !important;
//         }
//       }
//     }
//   }
// }

@primary-color: #434443;