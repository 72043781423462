@import '../../../styles/variable.less';

.selectDopdown {
  z-index: 1000000 !important;
}
.formSelect {
  // margin-bottom: 0px;
  margin-bottom: 15px;
  label {
    color: #000;
    font-size: 14px;
    display: block;
    width: 100%;
    font-weight: 600;
  }
  .ant-select {
    width: 100%;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 0;
    border-radius: 0 !important;
    .ant-select-selection__placeholder {
      color: #555;
    }
    &.ant-select-focused {
      // &:focus{
      border-bottom: 1px solid @themeColor;
      // }
    }
    &.multiSelect {
      padding: 0px 0px;
      // padding: 5px 0px;
      margin-bottom: 0px;
      &.pt-0 {
        padding-top: 0;
      }
      .ant-select-selection {
        &:active,
        &:focus {
          box-shadow: none;
        }
      }
      .ant-select-selection--multiple .ant-select-selection__placeholder {
        margin-left: 0 !important;
      }
      .ant-select-selection-enabled {
        box-shadow: none;
      }
    }
    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
      border-bottom: 1px solid #e4e4e4;
    }
    .ant-select-selection {
      padding: 0;
      border: none;
      box-shadow: none !important;
      background-color: transparent;
      &.ant-select-selection--single {
        height: auto;
        .ant-select-selection__rendered {
          line-height: 16px;
          margin: 0;
          font-size: 14px;
          color: #555;
        }
        .ant-select-arrow {
          svg {
            fill: #000;
          }
        }
      }

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:active {
        box-shadow: none !important;
      }
    }
  }
}
.formSelect.erroroMess .ant-select {
  border-bottom: 1px solid #ee3b3b !important;
}

@primary-color: #434443;