@import "../../../styles/variable.less";

.commonTextArea{
    label{
        color: #000;
        font-size: 14px;
        display: block;
        width: 100%;
        font-weight: 600;
        margin-bottom: 5px;
        display: block;
    }
    textarea{
        padding: 10px;
        border: 1px solid #e4e4e4;
        width: 100%;
        height: 100px;
        &:focus{
            outline: none;
        }
    }
}
@primary-color: #434443;