@import 'variable.less';
.diamondListSummary {
  display: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .diamondGridView {
    .daimondGridViewItem {
      width: calc(50% - 10px) !important;
    }
  }
  .selectStoneValueBlock {
    flex-wrap: wrap;
    align-items: center;
    width: 100% !important;
    padding-right: 85px !important;
    .searchStoneClose {
      top: 0;
      bottom: 0;
      margin: auto;
      height: 24px;
    }
    &.profileStonSelect {
      padding: 0 0 0 8px !important;
    }
  }
  .searchPopupCommonTable {
    height: calc(1024px - 131px);
  }
  .offerWrapper {
    .offerTopBlock {
      .offerTopBlock {
        white-space: nowrap;
      }
    }
    .DiamondDetailPopup {
      .DiamondDetailPopupItem {
        margin-right: 5px;
        padding-right: 5px;
        span {
          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .diamondGridView {
    .daimondGridViewItem {
      .DiamondGridViewImg {
        height: 190px;
      }
    }
  }
}

@media (max-width: 767px) {
  .bidEndBlock {
    margin: 0 10px;
    background-color: #f9f9f9;
    padding: 5px 10px;
    border-radius: 0 0 10px 10px;
    flex-wrap: wrap;
    justify-content: center;
    .bidEndLabel {
      text-align: center;
      width: 100%;
      margin-bottom: 5px;
      display: block;
    }
    .countdownItem {
      width: 33%;
    }
  }
  .botoomStickyBar {
    justify-content: center;
  }
  .searchTopInputValue {
    padding: 0 10px;
    width: 64%;
    // width: 100%;
    .searchTopBlock {
      width: 100% !important;
    }
  }
  .demandPopupTwoValue {
    flex-wrap: wrap;
    .inputBlock {
      width: 100%;
    }
  }
  .searchResultListWrapper {
    margin-top: 10px;
  }
  .diamondListingAction {
    display: none;
  }
  .listingTopBlock {
    // display: block !important;
    position: relative;
    .gridListIcon {
      // position: absolute;
      // top: 3px;
      // right: 0;
    }
    .searchInnerFilterMain {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .filterOption {
      position: relative;
      img {
        width: 14px;
        height: 14px;
      }
      .filterStatus {
        background-color: @themeColor;
        color: #fff;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        position: absolute;
        right: -7px;
        top: -5px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .mobileRightAction {
      padding-right: 10px;
    }
    .mobileStoneSelect {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .mobileSelectStoneItem {
        text-align: center;
        width: auto;
        span {
          font-size: 12px;
          display: block;
          &:first-child {
            font-weight: 600;
            color: #000;
          }
          &:last-child {
            font-size: 11px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .diamondListLegends {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    p {
      font-size: 11px;
      display: flex;
      align-items: center;
      line-height: 1;
      .lDot {
        width: 5px;
        height: 5px;
        border-radius: 10px;
        display: inline-block;
        margin-right: 3px;
        &.available {
          background: #6bc950;
        }
        &.ibp {
          background: #db1c1c;
        }
        &.bid {
          background: #777777;
        }
        &.upcoming {
          background: rgb(245, 95, 207);
        }
      }
      .lName {
        color: #000;
      }
    }
  }
  .diamondListSummary {
    width: 100%;
    display: block;
    padding: 10px;
    .diamondListSummaryWrap {
      display: flex;
      justify-content: space-between;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      width: 100%;
      background-color: @whiteColor;
      border-radius: 5px;
      p {
        padding: 5px 2px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .span {
          display: inline-block;
          width: 100%;
          text-align: center;
        }
        .sVal {
          color: @themeColor;
          font-size: 10px;
          font-weight: 600;
        }
        .sTitle {
          font-size: 10px;
        }
      }
    }
  }

  .diamondListMobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    padding-bottom: 80px !important;
    .quotePageWrapper {
      .mobileListItem {
        display: flex;
        justify-content: space-between;
        span {
          font-size: 12px !important;
          display: flex;
          align-items: center;
        }
      }
      .mobileDiamondListBottom {
        span {
          font-size: 11px !important;
        }
      }
    }
    &.as_app {
      border: 1px solid #e2e2e2;
      border-radius: 10px;
      padding: 0px 0px 0px 0px !important;
      &.active {
        border: 1px solid @themeColor;
        box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.11);
        border-radius: 10px;
      }

      .diamodListItemWrapper {
        padding: 5px;
        display: flex;
        width: 100%;
        margin-bottom: 0;
        .diamodListItemContent {
          padding: 0 5px;
          width: 100%;

          p {
            font-size: 12px;
            color: #000;
          }
          .priceCaratContainer,
          .stoneInfo,
          .smeasure {
            display: flex;
            justify-content: space-between;
          }
          .stoneInfo {
            p:first-child {
              text-transform: uppercase;
              span {
                margin-right: 5px;
                &.sc {
                  position: relative;
                  padding-right: 5px;

                  &::after {
                    content: '';
                    width: 3px;
                    height: 3px;
                    position: absolute;
                    top: 5px;

                    right: 0;
                    background: #cecece;
                    border-radius: 5px;
                  }
                }
              }

              span:nth-child(5) {
                &::after {
                  display: none;
                }
              }
            }
            .sAmt {
              color: @themeColor;
            }
          }
          .smeasure {
            p {
              .title {
                font-weight: 600;
                color: #a3a1a1;
              }
            }
          }
          p {
            font-weight: 500;
            &.sId {
              color: @themeColor;
              font-weight: 600;
            }
            &.sShape {
              text-transform: uppercase;
              color: #000;
            }
            &.sDis {
              color: #6bc950;
            }
            &.priceCarat {
              color: @themeColor;
              font-weight: 600;
            }
          }
        }
      }
      .caratStatus {
        background: #e9e9e9;
        display: flex;
        flex-direction: column;
        padding: 3px 8px;
        border-radius: 5px;
        align-items: center;
        p:first-child {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: @themeColor;
          font-weight: 600;
          small {
            font-weight: 600;
          }
        }
        p.sStatus {
          font-size: 13px;
          display: flex;
          align-items: center;
          // width: 8px;
          // height: 8px;
          // margin-right: 5px;
          // border-radius: 2px;
          &.green {
            .lDot {
              background-color: #6bc950;
            }
            .lName {
              color: #6bc950 !important;
            }
          }
          &.red {
            .lDot {
              background-color: #db1c1c;
            }
            .lName {
              color: #db1c1c !important;
            }
          }
          &.blue {
            .lDot {
              background-color: #307bea;
            }
            .lName {
              color: #307bea;
            }
          }
          &.all {
            .lDot {
              background-color: @themeColor;
            }
            .lName {
              color: @themeColor;
            }
          }
          &.tenOrange {
            .lDot {
              background-color: #a87d52;
            }
            .lName {
              color: #a87d52;
            }
          }
          &.lighBlue {
            .lDot {
              background-color: #508dc9;
            }
            .lName {
              color: #508dc9;
            }
          }
          &.pink {
            .lDot {
              background-color: #c950c1;
            }
            .lName {
              color: #c950c1;
            }
          }
          .lDot {
            width: 5px;
            height: 5px;
            border-radius: 10px;
            display: inline-block;
            margin-right: 3px;
          }
        }
      }
      .quoteExtraDetail {
        padding: 5px 5px 5px 5px;
        border-top: 1px solid #ddd;
        max-width: 33.3333%;

        .quoteExtraItem {
          span {
            font-size: 12px;
            color: #000;
            &:first-child {
              font-weight: 600;
            }
          }
          .tableInput {
            width: 100px;
          }
        }
      }
    }
    padding: 10px;
    overflow: auto;
    padding-bottom: 50px;
    .diamodListItemWrapper {
      // border: 1px solid #e2e2e2;
      // border-radius: 10px;
      margin-bottom: 10px;
      position: relative;
      &:after {
        content: '';
        width: 5px;
        height: 40px;
        background: transparent;
        position: absolute;

        border-radius: 0 10px 10px 0;
        margin: auto;
        left: 0;
        top: 0;
        bottom: 0;
        background: #6bc950;
      }
      &.green {
        &:after {
          background: #6bc950;
        }
      }
      &.red {
        &:after {
          background: #db1c1c;
        }
      }
      &.blue {
        &:after {
          background: #307bea;
        }
      }
      .mobileListHeade {
        background-color: #fafafa;
        padding: 10px;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #e2e2e2;
        .mobileListHeadeBlock {
          display: flex;
          justify-content: space-between;
          .mobileListItem {
            text-align: center;
            min-width: 25%;
            .countDownBlock {
              .countdownItem {
                margin: 0 2px;
                span {
                  font-size: 12px;
                  text-transform: capitalize;
                }
              }
            }
            span,
            a {
              display: block;
              // &:first-child{
              //     text-transform: uppercase;
              //     font-size: 12px;
              // }
              &:last-child {
                color: #000;
                font-weight: 600;
                font-size: 14px;
              }
              &.stageStatus {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                // background-color: #000;
                margin: 5px auto auto auto;
              }
            }
          }
        }
      }
      .quoteExtraDetail {
        padding: 5px 5px 5px 5px;
        border-top: 1px solid #ddd;
        .quoteExtraItem {
          span {
            font-size: 12px;
            color: #000;
            &:first-child {
              font-weight: 600;
            }
          }
          .tableInput {
            width: 100px;
          }
        }
      }
      .mobileDiamondListBottom {
        .mobileListHeadeBlock {
          display: flex;
          flex-wrap: wrap;
          padding: 5px 5px;
          border-radius: 0 0 10px 10px;
          justify-content: space-between;
          .mobileListItem {
            width: 50%;
            &:nth-child(2),
            &:nth-child(4) {
              text-align: right;
            }
            span {
              color: #555;
              font-weight: 500;
              // font-size: 11px;
              font-size: calc(12px + (16 - 14) * ((100vw - 300px) / (767 - 300)));
              letter-spacing: 0;
            }
            &.basicDetail {
              span {
                font-weight: 600;
              }
            }
            &.totalPrice {
              span {
                font-weight: 600;
                color: #000;
              }
            }
          }
        }
      }
      .mobileListBlock {
        padding: 10px;
        display: -webkit-box;
        /* flex-wrap: wrap; */
        overflow: auto;
        .mobileListItem {
          min-width: 30%;
          display: flex;
          justify-content: space-between;
          margin: 2px 10px;
          align-items: center;
          position: relative;
          span {
            // &:first-child{
            //     font-weight: 500;
            //     font-size: 12px;
            //     text-transform: uppercase;
            //     color: #555;
            //     margin-right: 10px;
            // }
            // &:last-child{
            font-weight: 600;
            color: #000;
            // }
          }
          &:after {
            content: '';
            width: 1px;
            height: 15px;
            background: #9b9b9b;
            position: absolute;
            right: -10px;
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &.active {
        border: 1px solid @themeColor;
        box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.11);
        border-radius: 10px;
        .mobileListHeade {
          background-color: #fff;
        }
        .mobileDiamondListBottom {
          .mobileListHeadeBlock {
            background-color: #fff;
          }
        }
      }
    }
    .quotePageWrapper {
      .mobileListItem {
        span {
          font-size: 12px !important;
        }
      }
      .mobileDiamondListBottom {
        span {
          font-size: 11px !important;
        }
      }
    }
  }
  .diamondGridView {
    .daimondGridViewItem {
      width: calc(100% - 10px);
    }
  }

  // Offer
  .offerWrapper {
    .offerTopBlock {
      display: block !important;
      position: sticky;
      top: -20px;
      background: #fff;
      padding: 10px 0;
      z-index: 1;
      margin-top: 0;
    }
  }

  .mobileFilterSideBar {
    z-index: 100000;
    .ant-drawer-close {
      display: none;
    }
    .ant-drawer-body {
      padding: 15px;
    }
    .mobileFilterPopup {
      .mobileFilterDetail {
        margin-bottom: 25px;
        .mobileFilterListWrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .mobileFilterItem {
            background-color: @themeLightColor;
            padding: 2px 8px !important;
            border-radius: 20px;
            margin: 3px;
            display: flex;
            align-items: center;
            span {
              color: @themeColor;
              font-size: 12px;
              font-weight: 500;
            }
            .filterClose {
              width: 14px;
              height: 14px;
              border-radius: 100%;
              background-color: @themeColor;
              display: flex;
              margin-left: 5px;
              img {
                width: 6px;
                height: 6px;
                margin: auto;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 350px) {
  .diamondListMobile {
    &.as_app {
      .caratStatus {
        padding: 3px;
        p {
          font-size: 12px;
          small {
            font-size: 11px;
          }
        }
      }
      .diamodListItemWrapper {
        .diamodListItemContent {
          p {
            font-size: 11px;
          }
        }
      }
    }
  }
  .diamondListLegends {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    overflow-x: auto;
    flex-wrap: nowrap;
    p {
      font-size: 11px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      line-height: 1;
      .lDot {
        width: 5px;
        height: 5px;
        border-radius: 10px;
        display: inline-block;
        margin-right: 3px;
        &.available {
          background: #6bc950;
        }
        &.ibp {
          background: #db1c1c;
        }
        &.bid {
          background: #999;
        }
        &.upcoming {
          background: rgb(245, 95, 207);
        }
      }
      .lName {
        color: #000;
        white-space: nowrap;
      }
    }
  }
  .diamondListSummary {
    width: 100%;
    display: block;
    padding: 10px;
    .diamondListSummaryWrap {
      display: flex;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
      width: 100%;
      justify-content: space-between;
      p {
        padding: 5px 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        .span {
          display: inline-block;
          width: 100%;
          text-align: center;
        }
        .sVal {
          color: @themeColor;
          font-size: 10px;
          font-weight: 600;
        }
        .sTitle {
          font-size: 10px;
        }
      }
    }
  }
}

.mobileGroupingCard {
  margin-bottom: 10px;
  .mobileGroupingHead {
    background-color: @themeColor;
    font-size: 10px;
    color: #fff;
    padding: 2px 5px;
    border-radius: 5px 5px 0 0;
  }
  .mobileGroupingList {
    .diamodListItemWrapper {
      margin-bottom: 0;
      border-radius: 0;
      &:first-child {
        // border-bottom: none;
        border-radius: 0;
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }
}
.mobileSortingDropdown {
  min-width: 150px;
  .mobileSortingDropdownList {
    padding: 0;
    background-color: #fff;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 10;
    position: relative;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-bottom: 1px solid #e2e2e2;
        padding: 10px;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
.mobileOfferScroll {
  // max-height: calc(100vh - 530px);
  padding: 10px 0;
}

// share
.shareStoneMobileItem {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 10px;
  .shareStoneMobileHead {
    display: flex;
    background-color: #fafafa;
    padding: 10px;
    justify-content: space-between;
    .shareDropdownMobileHead {
      .dropdownIconCheckBox {
        margin-right: 10px;
      }
    }
    span {
      color: #000;
      font-weight: 600;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
}
.mobileShareOption {
  padding: 10px 20px;
  li {
    list-style: none;
    .dropdownBottomItem {
      display: flex;
      margin-bottom: 10px;
      .shareOptionLabel {
        color: #000;
        font-weight: 500;
      }
    }
  }
}

@primary-color: #434443;