@import "../../../styles/variable.less";

.inputBlock{
    margin-bottom: 15px;
    position: relative;
    label{
        color: #000;
        font-size: 14px !important;
        display: block;
        width: 100%;
        font-weight: 600;
    }
    .inputWrapper{
        position: relative;
        input{
            border: none;
            color:#555;
            font-weight:400;
            font-size: 14px;
            padding: 10px 0;
            width: 100%;
            border-bottom: 1px solid #e4e4e4;
            background-color: transparent;
            line-height: 16px;
            border-radius: 0;
            &::placeholder{
                color:rgb(98, 98, 98);
                font-weight:400;
                font-size: 14px;
            }
            &:focus{
                border-bottom: 1px solid @themeColor;
                outline: none;
            }
        }
    }
}
.plusInputRemove{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 30px;
    margin: auto;
    display: flex;
    .closeInoutItem,
    .plusInputItem{
        width: 25px;
        height: 25px;
        background-color: @themeColor;
        border-radius: 100%;
        display: flex;
        cursor: pointer;
        &.closeInoutItem{
            margin-left: 5px;
        }
    }
    img{
        width: 10px;
        margin: auto;
        height: 10px;
        &.closeInput{
            width: 8px;
            height: 8px;
        }
    }
}
.error-message{
    input{
        border-bottom: 1px solid #ee3b3b !important;
    }
}
@primary-color: #434443;