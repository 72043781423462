.loading-indicator:before {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000000;
  background: rgba(255, 255, 255, 0.15);
  cursor: progress;
  width: 100%;
  height: 100%;
  content: '';
}

.loading-indicator:after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000000001000;
  margin: auto;
  background-image: url('../img/loader.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 64px;
  height: 64px;
  animation: zoomeffect 10s infinite;
  content: '';

  @-webkit-keyframes zoomeffect {
    0% {
      transform: scale(0.1);
      background-position: center;
    }

    50% {
      transform: scale(0.6);
      background-position: center;
    }

    100% {
      transform: scale(0.1);
      background-position: center;
    }
  }

  @keyframes zoomeffect {
    0% {
      transform: scale(0.1);
      background-position: center;
    }

    50% {
      transform: scale(0.6);
      background-position: center;
    }

    100% {
      transform: scale(0.1);
      background-position: center;
    }
  }
}

.progress {
  cursor: progress;
}
