@import 'variable.less';

.container {
  width: 1240px;
  margin: auto;
}
.accountWrapper {
  padding-top: 10px;
  .accountTopBlock {
    display: flex;
    max-width: 1240px;
    margin: auto auto 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 5px;
    .accountUser {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .accountuserName {
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        font-style: italic;
      }
      .accountTitle {
        font-size: 18px;
        line-height: 48px;
        font-weight: 600;
        margin: 0;
        width: max-content;
      }
      .diamond_legends {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {
          position: relative;
          display: flex;
          align-items: center;
          margin-right: 5px;
          color: @themeDarkBoreder;
          font-size: 14px;
          &::after {
            content: '|';
            display: inline-block;
            margin-left: 5px;
          }
          &:last-child {
            margin-right: 0;
            &::after {
              content: '';
              margin: 0;
            }
          }
          .legend_clr {
            width: 8px;
            height: 8px;
            margin-right: 5px;
            border-radius: 2px;

            display: block;
            &.avail {
              background: #6bc950;
            }
            &.bus {
              background: #db1c1c;
            }
            &.bid {
              background: #a87d52;
            }
            &.up {
              background: #c950c1;
            }
            &.memo {
              background-color: #307bea;
            }
          }
        }
      }
    }
  }
  .customerSubTab {
    .accountTabBox {
      max-width: 1240px;
      margin: auto;
      padding: 0 5px;
    }
  }
}
.accountLogout {
  a {
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 500;
    font-size: 13px;
  }
  img {
    width: 10px;
    margin-left: 5px;
    height: 10px;
  }
}

.userAccountProfileDropdown {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  ul {
    margin: 0;
    li {
      display: block;
      list-style: none;
      a {
        color: #000;
        padding: 10px 5px;
        display: block;
        font-weight: 500;
      }
    }
  }
}

.profileHead {
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 20px;
}
.profileDetailSpace {
  padding: 15px;
}
.personalProfileWrapper {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  .editProfileLogo {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    img {
      width: 100%;
      border-radius: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .profileAction {
    // display: flex;
    margin: 0 24px;
    a,
    .profileUploadBlock {
      -webkit-transition: background-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
      transition: background-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
      display: flex;
      align-items: center;
      position: relative;
      padding: 5px 7px;
      color: #7c828d;
      cursor: pointer;
      font-size: 13px;
      justify-content: center;
      border: 1px solid #d8dce2;
      font-weight: 500;
      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
      label {
        font-size: 13px;
        font-weight: 500;
      }
      &:first-child {
        border-radius: 3px 3px 0 0;
      }
      &:last-child {
        border-radius: 0 0px 3px 3px;
        border-top: none;
      }
    }
  }
}

.contactDirectoryWrapper {
  .contactListMain {
  }
  .contactList {
    height: calc(~'100vh - 219px');
    overflow: auto;
    table {
      width: 100%;
      tr {
        th {
          min-width: 120px;
          text-transform: uppercase;
          color: #a2a2a2;
          font-weight: normal;
          letter-spacing: 1px;
          font-size: 13px;
          border-bottom: 1px solid #e9e9e9;
          padding: 5px 5px 10px 5px;
          white-space: nowrap;
          position: sticky;
          top: 0;
          text-align: center;
          background-color: #fff;
          &:first-child {
            min-width: min-content;
          }
        }
        td {
          text-align: center;
          padding: 15px 5px;
          border-bottom: 1px solid #e9e9e9;
        }
      }
    }
  }
}
.editProfilePage {
  max-width: 1240px;
  margin: auto;
}
.userManageTop {
  margin-top: 10px;
  margin-bottom: 20px;
}
.cardIcon {
  display: flex;
  justify-content: flex-end;
}
.cardIcon img {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  cursor: pointer;
}
.mb-10 {
  margin-bottom: 10px;
}
.m-0 {
  margin: 0;
}
.justify-content-center {
  justify-content: center;
}

.addressBlock {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  .addressItem {
    width: calc(33.33% - 10px);
    margin: 5px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    padding: 15px;
    .addressTopBlock {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h2 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;
      width: calc(100% - 100px);
    }
    p {
      color: #7e7e7e;
      font-size: 14px;
      margin: 0;
      line-height: 24px;
      font-weight: 500;
      text-transform: capitalize;
    }
    .addressAction {
      display: flex;
      margin-top: 0;
      align-items: center;
      a {
        border: 1px solid #d8dce2;
        padding: 4px 8px;
        font-size: 13px;
        display: block;
        color: #7c828d;
        &:first-child {
          border-radius: 3px 0 0 3px;
        }
        &:last-child {
          border-radius: 0 3px 3px 0;
          border-left: none;
        }
      }
    }
  }
}
.noAddressFound {
  padding: 25px;
  width: 100% !important;
  min-height: 300px;
  justify-content: center;
  display: flex;
  align-items: center;
  .notFoundTitle {
    font-size: 20px;
    color: #000;
    font-weight: 500;
  }
}
.columnSettingPage {
  padding-left: 30px;
}
.columnSettingPage {
  table {
    tr {
      th:first-child,
      td:first-child {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        .selectActionIconWrapper {
          margin: 0;
        }
      }
    }
  }
}
.profileSidebar {
  &.diamondListinSidebar {
    .ant-drawer-content-wrapper {
      width: 580px !important;
    }
  }
}
.KycCardBlock {
  box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.09);
  border: 1px solid #efefef;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0;
  position: relative;
  img {
    &.full_banner {
      width: 100% !important;
      height: 100%;
    }
  }
  .companyNameCode {
    display: flex;
    align-items: center;
    span {
      color: #000;
      font-size: 14px;
      &:first-child {
        font-size: 16px;
        font-weight: 600;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  .KYCFomrAddress {
    p {
      margin-bottom: 0 !important;
      color: #525252;
    }
  }
}
.detailEditBloxk {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: @themeColor;
  border-radius: 100%;
  display: flex;
  img {
    margin: auto;
    width: 14px;
    height: 14px;
  }
}
.kycDetailBox {
  align-items: center;
  .kycUploadDoc {
    width: 80px;
    height: 80px;
    border: 1px solid #ddd;
    border-radius: 100%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }
  .kycDetailItem {
    width: calc(~'100% - 95px');
    margin-left: 15px;
    .detail {
      display: flex;
      // align-items: center;
      // margin-right: 10px;
      margin-bottom: 10px;
      span {
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        color: #454545;
        font-size: 14px;
        &:first-child {
          margin-right: 8px;
          font-weight: 600;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0 !important;
        color: #454545;
        line-height: 20px;
      }
    }
  }
  &.addressBlock {
    border-top: 1px solid @themeLightColor;
    padding-top: 10px;
  }
}
.KycCardBlock {
  h2 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .accountWrapper {
    .accountTopBlock {
      max-width: 1200px;
    }
    .customerSubTab {
      .accountTabBox {
        max-width: 1200px;
        overflow-x: auto;
      }
    }
  }
}
@media (max-width: 1260px) {
  .customerSubTab {
    .accountTabBox {
      margin: auto 15px;
    }
  }
}
@media screen and (max-width: 767px) {
  .personalProfileWrapper {
    .profileAction {
      width: calc(100% - 120px);
      margin-right: 0;
    }
  }
  .profileUploadBlock {
    .fileUploadBlock {
      img {
        margin: 0;
      }
    }
  }
}

@primary-color: #434443;