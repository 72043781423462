@import "variable.less";

@media (min-width: 992px) and (max-width: 1199px) {
  .headerTopMenu {
    .headerWrapper {
      .headerLeft {
        // width: calc(100% - 290px);
        .headerLogo {
          width: 50px;
        }
        .headerMeniInner {
          > ul {
            li {
              a {
                &.menuItem {
                  padding: 0 3px;
                }
              }
            }
          }
        }
        .headerSearchWrapper {
          margin-left: 5px;
          width: calc(100% - 55px);
        }
      }
      .headerRightSide {
        // width: calc(100% - 243px);
        .headerMenuOption {
          width: calc(100% - 290px);
          .headerMenuBox {
            .headerMeniInner {
              li {
                a {
                  display: block;
                  font-size: 13px;
                  padding: 0 8px;
                }
              }
            }
          }
        }
        .headerRightBlock {
          // width: 290px;
          .headerRightOption {
            margin-left: 15px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  // .HeaderSticky{
  //     overflow: hidden;
  // }
  .headerTopMenu {
    width: 100%;
    .headerWrapper {
      .headerLeft {
        width: calc(100% - 120px);
        .headerLogo {
          width: 50px;
        }
        .headerMenuOption {
          ul {
            &#links {
              li {
                a {
                  padding: 0 5px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .headerRightSide {
        .headerRightBlock {
          width: 120px;
          .headerIcon {
            .headerUserName {
              display: none;
            }
          }
        }
      }
    }
    &.active {
      .HeaderRightSidebar {
        top: 102px;
      }
    }
  }
  .HeaderRightSidebar {
    .headerRightSidebarTop {
      height: 70px;
      top: -70px;
    }
    .dropdownRightSide {
      height: calc(100% - 70px);
    }
  }
}
@media (max-width: 767px) {
  .headerTopMenu {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
    width: 100%;
  }
  .headerRightSide {
    display: none;
  }
  .mobileMenu {
    img {
      width: 14px;
      height: 14px;
    }
  }
  .headerLogo {
    margin-left: 10px;
    // width: 60px;
    img {
      height: 45px !important;
      width: auto;
    }
  }
  .headerMobileIcon {
    display: flex;
    align-items: center;
    .headerMobileIconItem {
      margin-left: 20px;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .headerLeft {
    width: calc(100% - 16px);
    justify-content: space-between;
    margin-left: 15px;
  }

  .headerMenuBox {
    margin-top: 0;
    .headerMeniInner {
      ul {
        display: block;
        li {
          text-align: left;
          display: block !important;
          padding: 0 !important;
          a {
            padding: 15px;
            display: flex;
            width: 100%;
            align-items: center;
            border-bottom: 1px solid #e2e2e2;
            justify-content: space-between;
            &:after {
              display: none;
            }
            img {
              width: 14px;
              height: 14px;
            }
            &.active {
              font-weight: 600;
              color: @themeColor;
            }
          }
        }
      }
      .mobileSubMenu {
        display: none;
        // position: fixed;
        background: #fff;
        width: auto;
        height: auto;
        right: 0;
        left: 0;
        top: 0;
        z-index: 1;
        padding: 0 0 0 0;
        .mobileSubMenuHead {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          img {
            width: 16px;
            height: 16px;
            margin-right: 15px;
          }
          h3 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
          }
        }
        ul {
          padding: 0;
          margin: 0;
          border-bottom: 1px solid #e2e2e2;
          li {
            background-color: #fafafa;
            a {
              color: #000;
              font-weight: 500;
              font-size: 14px;
              // padding: 10px !important;
              border-bottom: none;
            }
            // border-bottom: 1px solid #e2e2e2;
          }
        }
        &.active {
          display: block;
        }
      }
    }
  }
  .mobileBottomSidebar {
    width: 100%;
    height: 0;
    bottom: -100%;
    .ant-drawer-body {
      padding: 15px 25px;
    }
    .ant-drawer-content,
    .ant-drawer-wrapper-body {
      overflow: visible;
    }
    .ant-drawer-content-wrapper {
      width: 100% !important;
      bottom: 0;
      top: auto;
      height: auto;
      overflow: auto;
      transform: translate(0, 100%) !important;
      overflow: visible;
    }
    &.ant-drawer-open {
      height: 100%;
      bottom: 0;
      .ant-drawer-content-wrapper {
        transform: translateY(0) !important;
      }
    }
  }
  .mobileSidebar {
    &.mobileSidebarHeader {
      .ant-drawer-content-wrapper {
        width: 300px !important;
        .ant-drawer-body {
          padding: 0;
        }
      }
      .ant-drawer-close {
        display: none !important;
      }
    }
    .ant-drawer-close {
      background-color: #fff;
      width: 40px;
      height: 40px;
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      border-radius: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: #000;
      }
    }
  }
  .covidStrip {
    span {
      font-size: 12px;
      &.closeImg {
        right: 6px;
        width: 14px;
        height: 14px;
        img {
          width: 6px;
          height: 6px;
        }
      }
    }
  }
  .mobileHeaderStrip {
    z-index: 2;
    .downArrow {
      bottom: auto;
      top: -10px;
      right: 6px;
    }
  }
}

@primary-color: #434443;