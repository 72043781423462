@themeColor : #434443;
// @themeColor : #3a8ed2;
@themeSecondColor:#434443;
// @themeSecondColor:#008dd2;
@tableBackGroundColor:#d4d4e2;
@themeLightColor : #e4e4e4;
// @themeLightColor : #dbdbe9;
// @themeLightColor : rgba(0, 141, 210,0.1);
@tableBorder:#f6f1eb;
@trhover:#f5fbf6;
@trselected:#e9eae9;
@themeDarkBoreder:#000;
@themeGrayColor:#e9eaea;
@mobilebglight:#fbf8f6;
@headerSearch:#e8d9ca;
@headerStripColor:linear-gradient(to bottom, #434443 0%,#aedbff 100%) !important;
// @headerStripColor:linear-gradient(to bottom, #3a8ed2 0%,#aedbff 100%) !important;
@thankBg:linear-gradient(to bottom,#fff 0%,#FFF9F4 100%);
@headerStripBg:#e0bf63;
@inputBorder:#9d9e9e;
@bgLightColor:#e7dacd;
@staticBg:#fafafa;
@notificationColor:linear-gradient(to bottom, #434443 0%,#aedbff 100%);
// @notificationColor:linear-gradient(to bottom, #3a8ed2 0%,#aedbff 100%);
@whiteColor : #fff;
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #ddd!important;
    -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb {
    background-color: #999 !important;
}
::selection{
    background-color: @themeColor;
}

*{
  scrollbar-width: thin;
  scrollbar-color: #999 #ddd;
}
