.mainHeadingTitle{
    font-size: 18px;
    padding: 0 15px;
    font-weight: 600;
}
.popupInnerTitle{
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 20px 0;
    padding: 0;
    text-decoration: underline;
    line-height: 18px;
}
.innerPageSmallTitle{
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 12px 0;
    padding: 0;
    line-height: 18px;
}
@primary-color: #434443;