@import 'variable.less';

.diamondSearch {
  &.new_ui {
    position: relative;
    background-color: #fff;

    .diamondSearchSticky {
      position: fixed;
      bottom: 0;
      right: 0;
      left: auto;
      width: calc(100% - 60px);
    }
    .green {
      color: #16914f;
    }
    .red {
      color: #f5222d;
      font-weight: 600;
    }
  }
  .diamondSearchWrapper {
    &.curved {
      border-radius: 10px;
      background: #fff;

      overflow: hidden;
      overflow-y: auto;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

      padding: 10px;

      height: calc(100vh - 165px);

      .diamondSearchItem {
        padding-left: 0;

        .diamondSearchLabel {
          background: @themeGrayColor;
          padding: 5px 15px;
          width: 138px;
          margin-right: 20px;
        }
        .clearSelection {
          color: black;
          padding-left: 10px;
          font-size: 15px;
          cursor: pointer;
        }
        .diamondSearchDetail {
          width: calc(100% - 158px);
        }
        .clearity_s {
          display: flex;
          .diamondSearchItem {
            width: 32%;
            border-bottom: 0 !important;
            .diamondSearchDetail {
              width: 400px;
            }
          }
          &.fancy_color {
            align-items: flex-start;
            .fancyColorBlock:first-of-type {
              margin-left: 10px;
            }
            .formSelect {
              margin-bottom: 0px;
              .ant-select {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
    .diamondSearchItem {
      display: flex;
      flex-wrap: wrap;
      padding: 5px 15px;
      align-items: center;
      border-bottom: 1px solid #ddd;
      &.makeGroup {
      }
      .haBlock,
      &.floBlock,
      &.labBlock,
      .brilliance {
        &.haBlock {
          margin-bottom: 8px;
        }
        .diamondSearchLabel {
          margin-bottom: 0;
        }
      }

      .diamondSearchLabel {
        width: 230px;
        label {
          font-weight: 600;
          color: #000;
          font-size: 14px;
          white-space: nowrap;
        }
        .colorTab {
          margin-top: 5px;
          a {
            font-size: 13px;
            color: #000;
            font-weight: 400;
            padding-right: 5px;
            margin-right: 5px;
            border-right: 1px solid #ddd;
            &.active {
              font-weight: 600;
              color: @themeColor;
              text-decoration: underline;
            }
            &:last-child {
              border-right: none;
            }
          }
        }
      }
      .diamondSearchDetail {
        width: calc(100% - 230px);
        position: relative;
        &.fullWidthBlock {
          width: 100%;
          .diamondSearchInnerblock {
            margin: 5px 1% 5px 0;
          }
        }
        .diamondSearchShape {
          display: flex;
          flex-wrap: wrap;

          height: auto;
          margin: 0;
          overflow: hidden;
          line-height: 21px;
          width: 100%;
          li {
            width: 6rem;
            list-style: none;
            text-align: center;
            margin: 0.15rem;
            border: 1px solid #ddd;
            padding: 10px 5px;
            cursor: pointer;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            /* border-radius: 5px; */

            span {
              display: block;
              font-size: 13px;
              font-weight: 500;
              text-transform: capitalize;
              color: #000;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }
            &:hover,
            &.active {
              background-color: @themeLightColor;

              color: @themeColor;
              border: 1px solid @themeSecondColor;
            }
          }
          &.active {
            height: auto;
          }
        }
        .shapeMoewLess,
        .smallMoreLess {
          right: 0;
          border: 1px solid #ddd;
          height: 79px;
          display: flex;
          align-items: center;
          width: 107px;
          font-weight: 500;
          color: #000;
          justify-content: center;
          position: absolute;
          top: 0;
          cursor: pointer;
          &.smallMoreLess {
            width: 50px;
            height: 33px;
            margin: 2px 0;
          }
        }
        .diamondSearchCaratRange {
          width: 20%;
        }
        .fromToValue {
          display: flex;
          justify-content: space-between;
          .fromToValueInput {
            width: 48%;
            padding: 7px 0;
            border-bottom: 1px solid #d2d3d4;
            label {
              font-size: 14px;
              font-weight: 600;
              color: #a0a2a6;
            }
            input {
              width: calc(100% - 40px);
              border: none;
              text-align: left;
              color: #000;
              font-weight: 500;
              font-size: 16px;
              padding-left: 15px;
            }
            &.focus {
              border-bottom: 1px solid @themeColor !important;
            }
          }
        }
        .addCaratButton {
          min-width: 120px;
          text-align: center;
          margin-left: 25px;
        }
        .diamondSearchList {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          margin-bottom: 0;
          &.whiteColor {
            &.active {
              height: auto;
            }
          }
          li {
            list-style: none;
            padding: 5px 10px;
            border: 1px solid #ddd;
            background-color: #fafafa;
            margin: 2px;
            color: #000;
            font-weight: 500;
            min-width: 50px;
            cursor: pointer;
            text-align: center;
            border-radius: 4px;
            line-height: 20px;

            &:hover {
              background-color: @themeLightColor;
              color: #000;
              border: 1px solid @themeSecondColor;
            }
            &.active {
              box-shadow: 0 0 7px 0px rgba(192, 158, 124, 0.15);

              color: #000;
              background-color: @themeLightColor;
              border: 1px solid @themeSecondColor;
            }
            &.diamondSearchCaratRange {
              background: @whiteColor;
              border: 0;
              cursor: auto;
              text-align: left;
              border-radius: 0;
              display: flex;
              align-items: center;
              padding-top: 0 !important;
              padding-bottom: 0 !important;
              width: max-content;
              border-left: 1px solid #a9a9a9;
              .fromToValue {
                .fromToValueInput {
                  margin-right: 10px;
                  width: 100px;
                  display: flex;
                  align-items: center;
                  padding-top: 0;
                  &:last-child {
                    margin-right: 0;
                  }
                  input {
                    width: 100%;
                  }
                }
              }
              .addCaratButton.commonButton {
                padding: 2px 10px;
                min-width: 100px;
                margin-left: 15px;
              }
            }
          }
        }
        .searchSelectValue {
          display: flex;

          .searchSelectItem {
            width: 49%;
            align-items: center;
            display: flex;
            padding: 6px 0;
            border-bottom: 1px solid #d2d3d4;
            .searchSelectLabel {
              font-size: 14px;
              font-weight: 600;
              color: #a0a2a6;
            }
            .ant-select {
              width: 100%;
              border: none;
              border-radius: 0;
              .ant-select-selection-selected-value {
                float: right;
                padding-right: 15px;
                font-weight: 500;
                color: #000;
                font-size: 16px;
              }
              .ant-select-selection {
                border: none;
                box-shadow: none;
                &:active {
                  box-shadow: none;
                  border: none;
                }
              }
              &:hover,
              &:active {
                box-shadow: none;
              }
            }
            &.multiSelect {
              width: 100%;
              padding: 2px 0;
              .ant-select-selection {
                padding-bottom: 0;
                .ant-select-selection__rendered {
                  height: 35px;
                  .ant-select-selection__placeholder {
                    margin-left: 0;
                    font-size: 14px;
                    font-weight: 600;
                    color: #a0a2a6 !important;
                  }
                  ul {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    li {
                      &.ant-select-selection__choice {
                        background: #fafafa;
                        border-radius: 5px;
                        color: #000;
                      }
                    }
                  }
                }
              }
            }
            &.singleInput {
              width: 100%;
              label {
                font-size: 14px;
                font-weight: 600;
                color: #000;
              }
              input {
                border: none;
                width: 100%;
                color: #000;
                font-size: 14px;
                font-weight: 500;
                padding: 6px 0;
                text-align: left;
              }
            }
          }
        }
        .diamondSearchInnerblock {
          width: 32.33%;
          margin-right: 1%;
          .smallLabel {
            font-size: 14px;
            color: #000;
          }
          .diamondSearchCaratRange {
            width: 100%;
          }
          .searchSelectItem {
            width: 49%;
          }
        }
      }
    }
    .hideShowBlock {
      background-color: #fafafa;
      border-top: 1px solid #ddd;
      padding: 15px;
      color: #000;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      cursor: pointer;
      text-transform: uppercase;
      justify-content: space-between;
      img {
        height: 14px;
        width: 14px;
      }
    }
    .fullBlockInnerBlock {
    }
    .b-b-0 {
      border-bottom: none;
    }
    .inclusiveBlock {
      display: block !important;
      .diamondSearchItem {
        border-bottom: none;
      }
      .inclusion-bb {
        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
}

.width-100 {
  width: 100% !important;
}
.makeGroup {
  width: 25%;
}
.diamondSearch .diamondSearchWrapper .diamondSearchItem.makeGroup .diamondSearchLabel {
  width: 138px;
  margin-right: 20px;
}
.diamondSearch .diamondSearchWrapper .diamondSearchItem.makeGroup > .d-flex {
  width: 100%;
}

.diamondSearch .diamondSearchWrapper.curved .diamondSearchItem.makeGroup .diamondSearchDetail {
  width: calc(100% - 158px);
  display: flex;
}
.labInnerBlock {
  width: 50%;
}
.labBlock,
.floBlock {
  width: 18%;
  align-items: flex-start !important;
  border-left: 1px solid #ddd;
  .diamondSearchLabel {
    width: 100% !important;
  }
  .diamondSearchDetail {
    width: 100% !important;
  }
}
.searchSimpleDrpdowwn {
  &.ant-select {
    border: 1px solid #ddd;
    background-color: #fafafa;
    height: 32px;
    margin: 2px;
    .ant-select-selection {
      background-color: transparent;
      border: none;
      width: 130px;
      .ant-select-selection-selected-value {
        color: #000 !important;
        font-size: 14px;
        font-weight: 500;
      }
      .ant-select-arrow {
        svg {
          width: 10px;
          height: 10px;
        }
      }
      &:focus,
      &:hover {
        box-shadow: none;
      }
    }
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }
}

.searchTopInputValue {
  > div {
    width: 50%;
  }
  .searchVendor {
    min-width: 300px;
    max-width: 768px;

    input {
      width: 100%;

      color: #000;
      padding: 6px 10px;
      border-radius: 8px !important;
      height: auto;

      &::placeholder {
        font-size: 14px;
        font-weight: 500;
        color: #000;
      }
      &:hover {
        outline: none;
        box-shadow: none;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .searchTopBlock {
    width: 300px;
    padding: 5px 0;
    input {
      width: 100%;

      border: 1px solid @inputBorder;
      color: #000;
      padding: 6px 10px;
      border-radius: 8px !important;
      height: auto;
      background: @themeLightColor;
      &::placeholder {
        font-size: 14px;
        font-weight: 500;
        color: #000;
      }
      &:hover {
        outline: none;
        box-shadow: none;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
.caratTagButton {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 5px;
  .addedCarat {
    padding: 3px 5px;
    border: 1px solid @themeColor;
    background-color: @themeLightColor !important;
    color: @themeColor !important;
    svg {
      fill: @themeColor;
    }
  }
}
.brilliance,
.haBlock {
  width: 50%;
}

.multiSelectDropdown {
  img {
    margin-left: 5px;
    width: 12px;
    height: 12px;
  }
}
.selectOptionDropdown {
  background: #fff;
  padding: 10px;
  box-shadow: -5px -5px 16px 0px rgba(0, 0, 0, 0.09);
  border-radius: 0 0 10px 10px;
  .ant-checkbox-wrapper {
    display: block;
    margin-left: 0;
  }
}
.diffrentBlockWrapper {
  .threeBlockPart {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-left: 1px solid #ddd;
    padding-left: 10px;
    ul {
      margin: 0;
      margin-top: 0;
    }
    &:first-child {
      padding-left: 0;
      border-left: none;
    }
    .threeInnerBlock {
      padding-left: 10px;
      width: 50% !important;
      margin-right: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
.smallLabelInner {
  color: #000;
  font-size: 14px;
  padding: 0 10px;
  margin-bottom: 5px;
  font-weight: 600;
  text-decoration: underline;
}
.caratBoxSize {
  li {
    min-width: 85px !important;
    padding: 5px 5px !important;
  }
}

.select-diamonds {
  .ant-select {
    border: none;
    &:hover,
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    .ant-select-selection {
      &:active,
      &:focus {
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid @themeColor;
      }
      &:hover {
        border: 1px solid @themeColor;
      }
      .ant-select-selection__placeholder {
        color: #000;
      }
    }
  }
}
.two-cols {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .diamondSearchItem {
    width: 50%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .diamondSearch {
    &.new_ui {
      .diamondSearchSticky {
        flex-wrap: wrap;
        .opt_btn {
          width: 100%;
          margin-bottom: 5px;
        }
      }
    }
    .diamondSearchWrapper {
      .diamondSearchItem {
        .diamondSearchDetail {
          .diamondSearchCaratRange {
            width: 60%;
          }
        }
      }
    }
  }
  .two-cols {
    .diamondSearchItem {
      width: 100%;
    }
  }
}
@media screen and (max-width: 767px) {
  .diamondSearch {
    .diamondSearchWrapper {
      &.curved {
        overflow: auto;
        padding: 0.35rem;
        .diamondSearchItem {
          padding-left: 10px !important;
          .diamondSearchLabel {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 0 !important;
            background: transparent;
            padding-left: 5px;
          }
          .labInnerBlock {
            width: 100%;
          }
          .diamondSearchDetail {
            width: 100%;
            &.clearity_s {
              flex-wrap: wrap;
              &.fancy_color {
                .fancyColorBlock:first-of-type {
                  margin-left: 0;
                }
              }
              .diamondSearchItem {
                width: 100%;
                .diamondSearchDetail {
                  width: 100%;
                }
              }
            }
          }
        }
        .two-cols {
          .diamondSearchItem {
            width: 100%;
          }
        }
      }
    }
  }
}

@primary-color: #434443;