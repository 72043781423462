@import 'variable.less';

@media (min-width: 992px) and (max-width: 1199px) {
  .diamondSearch {
    .diamondSearchWrapper {
      .diamondSearchItem {
        .diamondSearchDetail {
          .diamondSearchShape {
            li {
              width: 16.65%;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .botoomStickyBar {
    padding: 10px 5px;
    // flex-wrap: wrap;
    .bottomStickyButton {
      margin: 0 2px;
      font-size: 12px;
      span {
        font-size: 12px;
      }
    }
    .select-diamonds {
      .ant-select {
        width: 100px;
      }
    }
    &.diamondListingAction {
      // bottom: -88px;
      .listingPagePagination {
        // margin-top: 10px;
        // justify-content: center;
        // width: 100%;
      }
      .listingAction {
        // justify-content: center;
        // width: 100%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .botoomStickyBar {
    padding: 10px 5px;
    // display: block !important;
  }
  .diamondSearch {
    .diamondSearchWrapper {
      .diamondSearchItem {
        flex-wrap: wrap;
        .diamondSearchDetail {
          width: 100%;
          .diamondSearchShape {
            flex-wrap: wrap;
            overflow: visible;
            li {
              width: 14.2%;
              span {
                word-break: break-word;
              }
            }
          }
          .searchSelectValue {
            flex-wrap: wrap;
            .diamondSearchInnerblock {
              width: 49%;
            }
          }
        }
        .diamondSearchLabel {
          width: 100%;
          margin-bottom: 5px;
        }
        .diamondsearchleft {
          width: 100%;
          display: flex;
        }
        &.diamondsearchleftRight {
          .diamondSearchSingle {
            width: 100% !important;
            border-top: 1px dotted #ddd;
            margin-top: 10px;
            padding-top: 10px;
          }
        }
        &.makeGroup {
          width: 100%;
          display: flex !important;
          .d-flex {
            width: 50%;
            margin: 5px 0;
          }
        }
        &.floBlock,
        &.labBlock {
          width: 100%;
          flex-wrap: wrap;
          margin-bottom: 0 !important;
          .diamondSearchLabel {
            width: 100% !important;
          }
          &.floBlock {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .mobileSearchBottom {
    display: none;
  }
}

@media (max-width: 800px) {
  .diamondSearch {
    &.new_ui {
      .searchTopBox {
        height: 45px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .diamondSearch {
    &.new_ui {
      .searchTopBox {
        height: 45px;
        line-height: 20px;
        .mainHeadingTitle {
          height: 45px;
          line-height: 20px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .searchTopInputValue .formSelect.from-group.multiSelect {
    display: block;
  }
  .diamondSearch {
    &.new_ui {
      .searchTopBox {
        height: 45px;
        line-height: 20px;
        .mainHeadingTitle {
          height: 45px;
          line-height: 20px;
        }
      }
    }
  }
  .diffrentBlockWrapper {
    .threeBlockPart {
      width: 100%;
      margin-top: 8px;
      border-left: none;
      padding-left: 0;
      .threeInnerBlock {
        width: 100% !important;
      }
    }
  }
  .fullBlockInnerBlock {
    background-color: #fff;
  }
  .smallLabelInner {
    padding: 15px 10px 0 10px;
    display: block;
  }
  .diamondSearch {
    .searchTopBox {
      position: relative;

      .mainHeadingTitle {
        padding: 5px 10px;
        margin-bottom: 0;
        line-height: 20px;
      }
    }
    &.new_ui {
      padding-left: 0;
      padding-right: 0;

      .searchTopBox {
        top: 56px;
        // top: 58px;
        left: 0;
        width: 100%;
        height: 62px;
        align-items: center;
        .flex-wrap-xs {
          flex-wrap: wrap;
        }
        .search_limit {
          padding: 5px 5px;
          position: fixed;
          bottom: 65px;
          background: #333;
          width: calc(~'100% - 20px');
          left: 10px;
          right: auto;
          margin: auto;
          border-radius: 5px;
        }
      }
    }
    .diamondSearchWrapper {
      background: @mobilebglight;
      padding-bottom: 50px;
      .diamondSearchItem {
        display: block !important;
        background-color: #fff;
        border-bottom: none;
        margin-bottom: 0px;
        padding: 10px;
        .haBlock,
        .brilliance {
          display: block !important;
        }
        &.makeGroup {
          .d-flex {
            margin-bottom: 5px;
          }
        }
        .diamondSearchLabel {
          width: 100%;
          margin-bottom: 5px;
        }
        .diamondSearchDetail {
          width: 100%;
          height: auto;
          padding-left: 0 !important;
          .diamondSearchShape {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            width: 100%;
            li {
              width: 80px;
              min-width: 80px;
              margin: 0 5px 0 0;
              padding: 11px 5px;
            }
            &::-webkit-scrollbar {
              display: none;
            }
          }
          .shapeMoewLess {
            display: none;
          }
          .diamondSearchList {
            // flex-wrap: nowrap;
            overflow: auto;
            height: auto;
            li {
              white-space: nowrap;
              min-width: auto;
            }
            &.caratBoxSize {
              li {
                min-width: 84px !important;
                &.diamondSearchCaratRange {
                  position: absolute;
                  right: 0;
                  padding-right: 0 !important;
                  border-left: 0;
                  top: -40px;
                  .fromToValue {
                    .fromToValueInput {
                      width: 60px;
                    }
                  }
                  .addCaratButton {
                    &.commonButton {
                      margin-right: 0;
                      margin-left: 8px;
                      padding: 2px 5px;
                      min-width: 80px;
                    }
                  }
                }
              }
            }
            &::-webkit-scrollbar {
              display: none;
            }
            &.whiteColor {
              width: 100%;
              overflow: auto;
              height: auto;
            }
          }
          .smallMoreLess {
            display: none;
          }
          .searchSelectValue {
            display: block !important;
            .diamondSearchInnerblock {
              width: 100%;
              margin-right: 0;
              margin-top: 15px;
            }
          }
          .diamondSearchInnerblock {
            width: 100%;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .diamondSearchCaratRange {
            width: calc(100% - 110px);
          }
          .addCaratButton {
            margin-left: 5px;
            min-width: 95px;
            padding: 5px 10px;
          }
        }
        .floBlock,
        .labBlock {
          width: 100%;
          display: block !important;
          &.floBlock {
            margin-bottom: 10px;
          }
        }
        &.makeGroup {
          width: 100%;
          display: flex !important;
          flex-wrap: nowrap;
          margin-bottom: 0;
          padding-bottom: 0;
          .d-flex {
            flex-wrap: nowrap;
            margin-bottom: 0;
          }

          .diamondSearchLabel {
            width: 150px;
            margin-right: 15px !important;
            margin-bottom: 0;
          }
        }
        .haBlock,
        &.floBlock,
        &.labBlock,
        .brilliance {
          width: 100%;
          &.haBlock {
            margin-bottom: 8px;
          }
          .diamondSearchLabel {
            margin-bottom: 0;
          }
        }
      }
      .fullBlockInnerBlock {
        .diamondSearchItem {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  .diamondSearchSticky {
    display: none;
  }
  .mobileSearchBottom {
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
    position: fixed;
    bottom: 0;
    padding: 10px 0 8px 0px;
    width: 100%;
    display: flex;
    border-radius: 10px 10px 0 0;
    z-index: 10;
    background-color: #fff;
    .mobileSearchActionItem {
      width: 25%;
      text-align: center;
      img {
        width: 14px;
        display: block;
        height: 14px;
        margin: auto auto 5px auto;
      }
      span {
        color: #000;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 11px;
        line-height: 14px;
        margin-top: 2px;
        display: block;
      }
    }
  }
  .MoreSearchOptionItem {
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        padding: 10px 0;
        border-bottom: 1px solid #e2e2e2;
        a {
          color: #000;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .diamondSearch {
    .diamondSearchWrapper {
      .diamondSearchItem {
        .diamondSearchDetail {
          .diamondSearchList {
            &.caratBoxSize {
              li {
                &.diamondSearchCaratRange {
                  .fromToValue {
                    .fromToValueInput {
                      width: 45px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #434443;