@import '../../../styles/variable.less';

.ant-modal-mask {
  z-index: 1000000;
}
.ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
}
.commonModal {
  width: auto !important;
  padding-bottom: 0 !important;
  top: 0;
  min-width: 700px !important;
  max-width: 70%;
  margin: auto;
  &.xs-size {
    min-width: 400px !important;
    max-width: 40%;
  }
  &.ListingModal {
    width: 900px !important;
  }
  .ant-modal-content {
    border-radius: 8px;
    padding: 25px;
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-close {
      top: 15px;
      right: 15px;
      .ant-modal-close-x {
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 100%;
        i {
          width: 100%;
          height: 100%;
          display: flex;
        }
        svg {
          margin: auto;
          fill: #000;
          width: 14px;
          height: 14px;
        }
      }
    }
    .ant-modal-header {
      border: none;
      text-align: center;
      .ant-modal-title {
        text-align: center;
        color: #343434;
        font-size: 24px;
        font-weight: 600;
      }
    }
    .ant-modal-footer {
      border-top: none;
    }
    .commonModalButton {
      display: flex;
      justify-content: space-evenly;
      .smallSliderImage {
        width: 48px;
        height: 48px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      button {
        min-width: 120px;
        padding: 10px 15px !important;
        font-size: 16px;
        border-radius: 5px;
        letter-spacing: 0.5px;
        cursor: pointer;
        transition: 0.2s all;
        &.fillButton {
          background-color: @themeColor;
          color: #fff;
          border: 1px solid @themeColor;
          &:hover {
            background-color: #fff;
            border: 1px solid @themeColor;
            color: @themeColor;
          }
          &:focus {
            outline: none;
          }
        }
        &.outLineButton {
          background-color: #fff;
          border: 1px solid @themeColor;
          color: @themeColor;
          &:hover {
            background-color: @themeColor;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .commonModal {
    &.xs-size {
      min-width: 500px !important;
      max-width: 40%;
      .ant-modal-content {
        overflow: auto;
        max-height: 90vh;
        max-height: 100%;
        min-height: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .commonModal,
  .commonModal.xs-size {
    width: 100% !important;
    min-width: 95% !important;
    max-width: 95% !important;
    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
    }
    .ant-modal-body {
      padding: 24px 0;
    }
  }
}

@primary-color: #434443;